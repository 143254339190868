import { IconCloudUp, IconLoader, IconX, IconUpload, IconLoader2, IconProgressCheck, IconFileSpreadsheet } from '@tabler/icons-react'
import React, { useEffect, useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { useSkuAPI } from 'src/API/rest.sku'
import { PostSubAttributes, useSubAttributeAPI } from 'src/API/rest.subattribute'
import { ButtonIconSquare } from 'src/components/buttons'
import { InputStandard } from 'src/components/standard.input'
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from 'src/components/standard.modal'
import { SelectStandard } from 'src/components/standard.select'
import { Progress } from 'src/shadcn/ui/progress'
import { toast } from 'src/shadcn/ui/use-toast'
import { RefStyleUploadExcel } from './component.uploadRefStyle'
import { MultiselectStandard } from 'src/components/standard.multiselect'
import { MultiselectStandardOnline } from 'src/components/standard.multiselectOnline'

type Props = {
    show?: boolean,
    setShow?: (show: boolean) => void,
}


type AssignStyleData = Array<{
    F19_M14_SubAttributeID: string,
    F19_M14_SubAttributeIDRef: string,
    styleName: string
}>

export function NPStylewiseRefUpload(props: Props) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [openExcelUpload, setOpenExcelUpload] = useState(false);
    const { register, handleSubmit, setValue, reset, formState: { errors }, control, watch } = useForm<{
        options: AssignStyleData
    }>({

    });
    const [searchOldStyles, setSearchSearchOldStyles] = useState('')

    const {
        subAttributes,
        error,
        isError: isErrorNewStyles,
        isFetched,
        isFetching: isFetchingNewStyles,
        isLoading,
        totalSubAttributeCount,
        isDeletingSubAttribute,
        refetchSubAttributes,
        deleteSubAttributeById,
        readSubAttributebyId,
        isReadingSubAttribute

    } = useSubAttributeAPI({
        page_size: 1000,
        FM14_NewStyleFlag: 1,
        trigger: props.show
    })
    const {
        subAttributes: subAttributesOld,
        isError: isErrorOldStyles,
        isFetching: isFetchingOldStyles,
        refetchSubAttributes: refetchOldSubAttributes,
        isFetched: isFetchedOldStyles
    } = useSubAttributeAPI({
        page_size: 15,
        FM14_NewStyleFlag: 0,
        search: searchOldStyles,
    })


    const {
        updateSubAttributeRef
    } = PostSubAttributes()

    const [newStyles, setNewStyles] = useState<AssignStyleData>([])
    const [selectedItems, setSelectedItems] = useState<{
        label: string,
        value: string,
        index: number
    }[]>([])
    //styles that are not available in initial loads
    const [unavailableStyles, setUnavailableStyles] = useState<{ label: string, value: string }[]>([])
    const [loadedAllUnavailableStyles, setLoadedAllUnavailableStyles] = useState(false)



    const realStyles = [...subAttributesOld?.map((subAttribute) => {
        return {
            label: subAttribute.FM14_SubAttributeName!,
            value: String(subAttribute.FM14_SubAttributeID)
        }
    }) || [], ...selectedItems.filter(({ value }) => !subAttributesOld?.some((subAttribute) => String(subAttribute.FM14_SubAttributeID) == value)),...unavailableStyles]

    const realStylesUnique = Array.from(new Set(realStyles.map(s => s.value))).map(v => realStyles.find(s => s.value === v)!);





    useEffect(() => {

        const loadUnavailableStyles = async (newStyles: {
            F19_M14_SubAttributeID: string;
            F19_M14_SubAttributeIDRef: string;
            styleName: string;
        }[]) => {



            const unavailableStyles = await Promise.all(
                newStyles.map(
                    async (style) => {
                        if(style.F19_M14_SubAttributeIDRef){
                            const styleFetched = await readSubAttributebyId({
                                subAttributeId:style.F19_M14_SubAttributeIDRef
                            })
                            if(styleFetched.success === true){
                                return {
                                    label: styleFetched.data?.FM14_SubAttributeName!,
                                    value: String(styleFetched.data?.FM14_SubAttributeID)
                                }
                            }
                        }

                        return null
                    }
                )
            )

            if(unavailableStyles.length !== 0){
                setUnavailableStyles(unavailableStyles.filter(s => s) as { label: string, value: string }[])
                setLoadedAllUnavailableStyles(true)
            }



        }


        if (subAttributes && !isErrorNewStyles && props.show && !isFetchingOldStyles) {



            const newStyles = subAttributes.map((subAttribute, index) => {
                //if already selected the item, then store it
                const item = selectedItems.find(item => item.index === index)
                return {
                    F19_M14_SubAttributeID: String(subAttribute.FM14_SubAttributeID),
                    F19_M14_SubAttributeIDRef: item ? item.value : subAttribute.reference_style_table?.[0]?.F19_M14_SubAttributeIDRef ? String(subAttribute.reference_style_table[0]?.F19_M14_SubAttributeIDRef) : '',
                    styleName: subAttribute.FM14_SubAttributeName || ''
                }
            })

            


            setNewStyles(newStyles)
            reset({
                options: newStyles
            })


            // fetch unavailable styles initially only
            if(!isFetchingNewStyles && !isFetchingOldStyles && isFetched && isFetchedOldStyles && !loadedAllUnavailableStyles) {
                loadUnavailableStyles(newStyles)
            }



        }

        if (props.show === false) {
            setSelectedItems([])
            setLoadedAllUnavailableStyles(false)
        }

    }, [isFetchingNewStyles, isFetchingOldStyles, props.show])

    const onSubmit = async (data: { options: { F19_M14_SubAttributeIDRef: string; }[] }) => {
        setIsSubmitting(true);
        const submitData: ({
            F19_M14_SubAttributeID: number;
            F19_M14_SubAttributeIDRef: number;
        })[] = []
        data.options.forEach((item, index) => {
            submitData.push({
                F19_M14_SubAttributeID: Number(newStyles[index].F19_M14_SubAttributeID),
                F19_M14_SubAttributeIDRef: Number(item.F19_M14_SubAttributeIDRef),
            })
        })
        const response = await updateSubAttributeRef(submitData)

        // if(response.success === true){
        toast({
            description: response.message,
        })
        props.setShow?.(false);
        // }else{
        //     toast({
        //         description: response.message,
        //         title: "error",
        //     })
        // }

        setIsSubmitting(false);
    };

    const isAnythingFetching = isFetchingNewStyles;



    return (
        <ModalStandard
            open={props.show}
        // onOpenChange={props.setShow}
        >
            <ModalStandardContent hideCloseButton={true} onOpenAutoFocus={(e) => e?.preventDefault()} className='bg-vstargray-100'>
                <form onSubmit={handleSubmit(onSubmit, (erro) => console.log(erro))}>
                    <ModalStandardHeader>
                        <ModalStandardTitle>Assign styles</ModalStandardTitle>
                        <ModalStandardDescription>
                            {isAnythingFetching && (
                                <div className='px-2 p-1 rounded-md bg-vstargray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                    Updating...
                                </div>
                            )}
                            <div className=' grid grid-cols-2 gap-2 py-2 '>
                                {
                                    newStyles.map((item, index) => {

                                        const refId = watch(`options.${index}.F19_M14_SubAttributeIDRef`)

                                        return (
                                            <>
                                                <InputStandard
                                                    disabled
                                                    placeholder={'state id'}
                                                    value={item.styleName}
                                                />
                                                <MultiselectStandardOnline
                                                    options={realStylesUnique || []}
                                                    placeholder={'reference'}
                                                    singleSelect
                                                    value={refId ? [refId] : []}
                                                    onValueChange={(value) => {
                                                        console.log('value changed', value.target.value)
                                                        //remove from selected array if it is deselected
                                                        if (!value.target.value[0]) {
                                                            setSelectedItems(selectedItems.filter((item) => item.index !== index))
                                                        } else {
                                                            const indexArray = selectedItems.findIndex((item) => item.index === index)
                                                            if (indexArray !== -1) {
                                                                selectedItems[indexArray] = { label: value.target.item[0].label, value: value.target.item[0].value, index }
                                                                setSelectedItems([...selectedItems])
                                                            } else {
                                                                setSelectedItems([...selectedItems, { label: value.target.item[0].label, value: value.target.item[0].value, index }])
                                                            }
                                                        }
                                                        setValue(`options.${index}.F19_M14_SubAttributeIDRef`, value.target.value[0])
                                                    }}
                                                    onChangeSearch={(value) => setSearchSearchOldStyles(value)}
                                                    {...register(`options.${index}.F19_M14_SubAttributeIDRef`, { required: "asp is required" })}
                                                    error={errors.options?.[index]?.F19_M14_SubAttributeIDRef?.message}
                                                />
                                            </>
                                        )
                                    })
                                }
                            </div>
                            <div>

                            </div>
                            <RefStyleUploadExcel
                                show={openExcelUpload}
                                setShow={setOpenExcelUpload}
                                onSuccess={() => {
                                    refetchSubAttributes()
                                    refetchOldSubAttributes()
                                }}
                            />
                        </ModalStandardDescription>
                    </ModalStandardHeader>
                    <ModalStandardFooter className=' justify-between'>
                        <ButtonIconSquare
                            variant='primary'
                            icon={<IconFileSpreadsheet />}
                            text='Upload'
                            className=' border !border-gray-400'
                            // onClick={handleUpload}
                            onClick={(e) => { e?.preventDefault(); setOpenExcelUpload(true) }}
                        />
                        <div className=' flex gap-2'>
                            <ButtonIconSquare
                                variant='white'
                                icon={<IconX />}
                                text='Close'
                                onClick={(e) => { e?.preventDefault(); props.setShow?.(false) }}
                                disabled={isSubmitting}
                            />
                            <ButtonIconSquare
                                variant='secondary'
                                icon={isSubmitting ? <IconLoader className='animate-spin' /> : <IconProgressCheck />}
                                text='Submit'
                                // onClick={handleUpload}
                                disabled={isSubmitting}
                            />
                        </div>
                    </ModalStandardFooter>
                </form>
            </ModalStandardContent>
        </ModalStandard>
    )
}