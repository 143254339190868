import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { AxiosResponseCustom, useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"

export type ForecastCalenderListResponse = {
    ForecastCalenderMasterID: number;
    SINo: string;
    Action: string;
    Status: string;
    CalenderLines: {
        ForecastCalenderLineID: number;
        ForecastCalenderActions: string;
        Date: string;
        TargetDate: string;
        Status: string;
        Responsibility: {
            ForecastCalenderResponsibilityID: number;
            ForecastCalenderline: number;
            EmployeeID: number;
            Employee: {
                Name: string;
                Id: number;
                Code: string;
            };
        }[];
    }[];
}

export type ForecastCalenderActionsType = {
    ForecastCalenderMasterID: number;
    SINo: number;
    Action: string;
    Status: number;
}


export type CalenderActinPostType = {
    SINo: number,
    FT06_forecastCalenderMasterID: number,
    Action: string,
    FT07_TargetDate: string | null,
    FT07_Date: string,
    FT07_Status: number,
    Responsibility: {
        FM03_EmployeeID: number
    }[],
};

export type CalenderLineupdateType = {
    FT07_ForecastCalenderLineID: number | null,
    FT06_forecastCalenderMasterID: number,
    FT07_Date: string,
    FT07_TargetDate: string | null,
    FT07_Status?: number,
    Responsibility: {
        FT08_ForecastCalenderResponsibilityID: number | null,
        FT08_T07_ForecastCalenderline: number | null,
        FM03_EmployeeID: number,
    }[],
}
export type CalenderActinUpdateType = {
    calenderLines: CalenderLineupdateType[],
    deletedResponsibility: number[],
}


const QUERY_KEY = 'forcastCalendersList'
export function useForecastCalenderAPI({
    date,
    retryCount,
    trigger = true
}: {
    date?: string,
    retryCount?: number,
    trigger?: boolean
}) {

    const api = useRestAPI()
    const [totalSubAttributeCount, setTotalSubAttributeCount] = useState(0)
    const queryclient = useQueryClient()
    // const [deleteRetryCount,setDeleteRetryCount] = useState(0)

    const {
        isLoading,
        isError,
        data: forcastCalendersList,
        refetch: refetchforcastCalendersList,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount,
    } = useQuery({
        queryKey: [QUERY_KEY, date],
        queryFn: async (): Promise<ForecastCalenderListResponse[]> => {
            const response = await api.get<{ data: ForecastCalenderListResponse[] } & backendPagination>({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/calendar/${date}/`,
                mode: 'protected',

            })
            if (response.success === false) {
                throw response.message
            }

            setTotalSubAttributeCount(response.count)
            return response.data



        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger,


    })

    const { mutateAsync: getCalendarActionList, data: calenderActionList, isPending: isCalenderActionListPending, failureCount: calenderActionListFailureCount } = useMutation({
        mutationFn: async () => {

            const response: {
                success: boolean,
                message: string,
                data: ForecastCalenderActionsType[]
            } = {
                success: true,
                message: 'succesfully fetched calender actions',
                data: [
                    {
                        "ForecastCalenderMasterID": 101,
                        "SINo": 1,
                        "Action": "Create",
                        "Status": 1
                    },
                    {
                        "ForecastCalenderMasterID": 101,
                        "SINo": 1,
                        "Action": "Create",
                        "Status": 1
                    },
                    {
                        "ForecastCalenderMasterID": 101,
                        "SINo": 1,
                        "Action": "Create",
                        "Status": 1
                    },
                ]

            }
            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && calenderActionListFailureCount < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }

            return response
        },
        retry: 4,

    })




    const { mutateAsync: createCalendarLine, isPending: isCreatingCalendarLine, failureCount: errorCreatingCalendarLineCount } = useMutation({
        mutationFn: async (actionsLines: CalenderActinPostType[]) => {


            const response = await api.post<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/calendar/`,
                data: actionsLines,
                mode: 'protected'
            })

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && errorCreatingCalendarLineCount < 4) {

                throw response
            }

            return response
        },
        retry: 4,

    })

    const { mutateAsync: updateCalendarLine, isPending: isUpdatingCalendarLine, failureCount: errorUpdatingCalendarLineCount } = useMutation({
        mutationFn: async ({ data, date }: { data: CalenderActinUpdateType, date: string }) => {


            const response = await api.put<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/calendar/update/${date}/`,
                data: data,
                mode: 'protected'
            })

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && errorUpdatingCalendarLineCount < 4) {

                throw response
            }

            return response
        },
        retry: 4,

    })




    return {
        isLoading,
        isError,
        forcastCalendersList,
        refetchforcastCalendersList,
        totalSubAttributeCount,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount,
        getCalendarActionList,
        isCalenderActionListPending,
        calenderActionList,
        createCalendarLine,
        isCreatingCalendarLine,
        updateCalendarLine,
        isUpdatingCalendarLine,
        errorUpdatingCalendarLineCount,
    }


}


export function useGetLatestCalendarDate({
    trigger = true
}:{
    trigger?:boolean
}) {

    const api = useRestAPI()

    const {
        data: latestCalendarDate,
        refetch: refetchlatestCalendarDate,
        isFetching:isFetchinglatestCalendarDate,
        isFetched:isFetchedlatestCalendarDate,
        isError:isErrorlatestCalendarDate,
        isLoading:isLoadinglatestCalendarDate
    } = useQuery({
        queryKey: ['defaultforcastcalenderdate'],
        queryFn: async (): Promise< { date: string  }> => {


            // const response: {
            //     success: boolean,
            //     message: string,
            //     data: {
            //         date: string
            //     }
            // } = {
            //     success: true,
            //     message: 'succesfully fetched latest  date',
            //     data: {
            //         date: '2024-10-01'
            //     }

            // }

            const response = await api.get<{
                data:{date: string}
            }>({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/calendar/get-latest-date/`,
                mode: 'protected',

            })
            if (response.success === false) {
                throw response.message
            }

            response.success = true
            return response.data

        },
        enabled:trigger,
        refetchOnWindowFocus: false
    })

    return {
        latestCalendarDate,
        refetchlatestCalendarDate,
        isFetchinglatestCalendarDate,
        isFetchedlatestCalendarDate,
        isErrorlatestCalendarDate,
        isLoadinglatestCalendarDate
    }

}


