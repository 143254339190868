import { useMutation, useQuery } from "@tanstack/react-query"
import { useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"

export type DesignationData = {
    FM05_DesignationID: number,
    FM05_Department: {
        id: number,
        name: string
    },
    FM05_DesignationName: string,
    FM04_IsActive: number
}

const QUERY_KEY = 'designations'
export function useDesignationAPI({
    page_size,
    page,
    retryCount,
    trigger=true
}:{
    page_size?: number,
    page?: number,
    retryCount?:number,
    trigger?:boolean
}) {

    const api = useRestAPI()
    const [totalDesignationCount, setTotalDesignationCount] = useState(0)

    const {
        isLoading,
        isError,
        data: designations,
        refetch: refetchDesignations,
        isFetching,
        isFetched,
        error
    } = useQuery({
        queryKey: [QUERY_KEY, page_size, page],
        queryFn: async () => {
            const response = await api.get<{ data: DesignationData[] }>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/designations/`,
                mode: 'protected',
                params: {
                    page_size,
                    page
                }
            })
            if (response.success === false) {
                throw response.message
            }

            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger
        
    })



    return {
        isLoading,
        isError,
        designations,
        refetchDesignations,
        isFetching,
        isFetched,
        error
    }


}



