import { IconEdit, IconLoader, IconLockFilled, IconPlus, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ForecastHeaderType, ForecastLineType, ForecastRegionWisePostType, useForecastAPI } from 'src/API/rest.forecast';
import { ForecastChannelType, MonthCode } from 'src/API/rest.forecast_regionwise';
// import { ForecastChannelType, ForecastHeaderType, ForecastLineType, ForecastRegionWisePostType,  MonthCode, useForecastRegionwiseAPI } from 'src/API/rest.forecast_regionwise';
import { useRegionAPI } from 'src/API/rest.region';
import { ButtonIconSquare } from 'src/components/buttons';
import { InputStandard } from 'src/components/standard.input';
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from 'src/components/standard.modal';
import { cn } from 'src/lib/utils';
import { toast } from 'src/shadcn/ui/use-toast';

type Props = {
    show?: boolean,
    setShow?: (show: boolean) => void,
    refetchforcastRegionwise: () => void,
    employeeDistributorMappingId?: number | string | null,
    selectedDate: string,
    MonthCode?: {
        monthCode: MonthCode;
        month: string;
    }
};




type OptionsTypeForm = {
    SINo: number,
    ForecastCalenderMasterID: number,
    Action: string,
    TargetDate: string | undefined,
    Status: number,
    Responsibility: {
        EmployeeID: number
    }[],
};


export type ForecastRegionType = {
    RegionID: number;
    RegionName: string;
    IsActive: boolean;
    ForecastLines: ForecastLineType[];
  }
  
  export type ForecastRegionWiseType = {
    ForecastHeader?: ForecastHeaderType;
    Regions: ForecastRegionType[];
  }

function returnForcastLine(props: {
    Date: string,
    ForecastMonth?: string,
    MonthCode?: MonthCode,
    forecastChannel: ForecastChannelType,
    regionID: number
}): ForecastLineType {
    return {
        FT02_ForecastLineID: null,
        FT02_FT01_ForecastHeaderID: null,
        FT02_ForecastLevel: "Region",
        FT02_Date: props.Date,
        FT02_ForecastMonth: props.ForecastMonth || null,
        FT02_MonthCode: props.MonthCode || null,
        FT02_ForecastChannel: props.forecastChannel,
        FT02_SuggestedQty: null,
        FT02_SuggestedValue: null,
        FT02_ForecastQty: null,
        FT02_ASP: null,
        FT02_ForecastValue: null,
        FT02_StyleFlag: null,
        FT02_M12_ProductSubCategory: null,
        FT02_M12_ProductCategory: null,
        FT02_ABCCategory: null,
        FT02_M13_ProductID: null,
        FT02_M14_SubAttributeID: null,
        FT02_M04_StateID: 1,
        FT02_FM07_RegionID: props.regionID,
        FT02_M03_EmployeeIDRSM: null,
        FT02_M03_EmployeeIDASM: null,
        FT02_M03_EmployeeIDSE: null,
        FT02_M11_DistributorID: null,
        FT02_Remarks: null,
        FT02_LYM1Qty: null,
        FT02_LYM2Qty: null,
        FT02_LYM3Qty: null,
        FT02_M1Qty: null,
        FT02_M2Qty: null,
        FT02_M3Qty: null,
        FT02_RunningMonthForecast: null,
        FT02_RunningMonthActuals: null,
        FT02_M1Suggested: null,
        FT02_Forecast1: null,
        FT02_Forecast2: null,
        FT02_Forecast3: null,
        FT02_SubmitFlag: 0,
        FT02_Status: 1,
        FT02_StatusDate: "2024-09-28",
        FT02_CreatedDate: null,
    } as ForecastLineType
}

export function ModalAddForecastModal(props: Props) {

    const { register, handleSubmit, setValue, reset, formState: { errors }, watch } = useForm<ForecastRegionWiseType>({

    });

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [options, setOptions] = useState<ForecastRegionWiseType>({
        ForecastHeader: undefined,
        Regions: [],
    });
    const [mode, setMode] = useState<'add' | 'edit'>('add');

    const {
        forecastData: forecastLines,
        isErrorforcast:isErrorLoadingForecastLine,
        isFetchingForcastData:isFetchingForecastLine,
        isFetchedForcastData:isFethedForecastLine,
        isLoadingforcast:isLoadingForecastLine,
        refetchForcastData:refetchForecastLine,
        createCalendarLine,
        isCreatingCalendarLine,
        isErrorCreateForecast,
        updateCalendarLine
        
    } = useForecastAPI({
        trigger:props.selectedDate&&props.show?true:false,
        FT02_Date:`${props.selectedDate}-01`,
        FT02_ForecastLevel:"Region",
        FT02_MonthCode:props.MonthCode?.monthCode!,
    })

   

    const {
        isLoading: isLoadingRegion,
        regions,
        isFetching: isFetchingRegion,
        refetchRegions,
        isError: isErrorRegion,
        isFetched: isFetchedRegion
    } = useRegionAPI({
        trigger:true
    })


    useEffect(() => {
        console.log(forecastLines)
        if (!isErrorRegion && !isErrorLoadingForecastLine && !isLoadingForecastLine &&!isLoadingRegion &&props.show) {
            
            const ForcastHeader: ForecastHeaderType = {
                FT01_ForecastHeaderID: forecastLines?.ForecastHeader?.[0]?.FT01_ForecastHeaderID || null,
                FT01_ForecastLevel: forecastLines?.ForecastHeader?.[0]?.FT01_ForecastLevel || "Region",
                FT01_Date: forecastLines?.ForecastHeader?.[0]?.FT01_Date || `${props.selectedDate}-01`,
                FT01_ForecastMonth: forecastLines?.ForecastHeader?.[0]?.FT01_ForecastMonth || `${props.MonthCode?.month!}-01`,
                FT01_MonthCode: forecastLines?.ForecastHeader?.[0]?.FT01_MonthCode || props.MonthCode?.monthCode!,
                FT01_ForecastChannel: forecastLines?.ForecastHeader?.[0]?.FT01_ForecastChannel || "",
                FT01_M04_StateID: forecastLines?.ForecastHeader?.[0]?.FT01_M04_StateID || 1,
                FT01_Remarks: forecastLines?.ForecastHeader?.[0]?.FT01_Remarks || null,
                FT01_SubmitFlag: forecastLines?.ForecastHeader?.[0]?.FT01_SubmitFlag || 0,
                FT01_IsApprove: forecastLines?.ForecastHeader?.[0]?.FT01_IsApprove || null,
                FT01_ApproveDate: forecastLines?.ForecastHeader?.[0]?.FT01_ApproveDate || null,
                FT01_ApprovedBy: forecastLines?.ForecastHeader?.[0]?.FT01_ApprovedBy || null,
                FT01_Status: forecastLines?.ForecastHeader?.[0]?.FT01_Status || true,
                FT01_StatusDate: forecastLines?.ForecastHeader?.[0]?.FT01_StatusDate || "",
                FT01_ForecastFileName: forecastLines?.ForecastHeader?.[0]?.FT01_ForecastFileName || null,
            }

            console.log(forecastLines)

            const options: ForecastRegionWiseType = {
                ForecastHeader: ForcastHeader,
                Regions: regions?.map(
                    region => {

                        const DistributerLine = forecastLines?.ForecastLines?.find(item => {
                            return item.FT02_ForecastChannel === 'Distributer' && item.FT02_FM07_RegionID==region.FM07_RegionID
                        }) || returnForcastLine({
                            Date: `${props.selectedDate}-01`,
                            forecastChannel: 'Distributer',
                            ForecastMonth: `${props.MonthCode?.month}-01`,
                            MonthCode: props.MonthCode?.monthCode,
                            regionID: region.FM07_RegionID
                        })
                        const EBOline = forecastLines?.ForecastLines?.find(item => {
                            return item.FT02_ForecastChannel === 'EBO' && item.FT02_FM07_RegionID==region.FM07_RegionID
                        }) || returnForcastLine({
                            Date: `${props.selectedDate}-01`,
                            forecastChannel: 'EBO',
                            ForecastMonth: `${props.MonthCode?.month}-01`,
                            MonthCode: props.MonthCode?.monthCode,
                            regionID: region.FM07_RegionID
                        })
                        const Institutionline = forecastLines?.ForecastLines?.find(item => {
                            return item.FT02_ForecastChannel === 'Institution' && item.FT02_FM07_RegionID==region.FM07_RegionID
                        }) || returnForcastLine({
                            Date: `${props.selectedDate}-01`,
                            forecastChannel: 'Institution',
                            ForecastMonth: `${props.MonthCode?.month}-01`,
                            MonthCode: props.MonthCode?.monthCode,
                            regionID: region.FM07_RegionID
                        })
                        const Otherline = forecastLines?.ForecastLines?.find(item => {
                            return item.FT02_ForecastChannel === 'Other' && item.FT02_FM07_RegionID==region.FM07_RegionID
                        }) || returnForcastLine({
                            Date: `${props.selectedDate}-01`,
                            forecastChannel: 'Other',
                            ForecastMonth: `${props.MonthCode?.month}-01`,
                            MonthCode: props.MonthCode?.monthCode,
                            regionID: region.FM07_RegionID
                        })

                        return {
                            IsActive: true,
                            RegionID: region.FM07_RegionID,
                            RegionName: region.FM07_RegionName,
                            ForecastLines: [
                                DistributerLine,
                                EBOline,
                                Institutionline,
                                Otherline
                            ]
                        }
                    }
                )
                    || []
            }
            setOptions(options)


            if (forecastLines?.ForecastLines?.length) {
                setMode('edit')
            } else {
                setMode('add')
            }
            reset(options)
        }

        if (isErrorLoadingForecastLine) {
            console.log('sjdflksjdflk lkdsjlkjflksjdl')
            toast({
                description: 'Error in fetching data',
                title: 'Error',
            })
        }

    }, [props.show, isFetchingForecastLine,isFetchingRegion]);

    const onSubmit = async (data: ForecastRegionWiseType, final?: boolean) => {
        setIsSubmitting(true);
        let postData: ForecastRegionWisePostType

        const ForecastLines = data.Regions.flatMap(region => region.ForecastLines);


        console.log(mode)
        if (mode === 'add') {
            postData = {
                ...data.ForecastHeader!,
                FT01_Date: `${data.ForecastHeader!.FT01_Date}`,
                FT01_StatusDate:null,
                ForcastLines: ForecastLines.map(item => {
                    return {
                        ...item,
                        FT02_Date: `${item!.FT02_Date}`,
                        FT02_ForecastMonth: `${item!.FT02_ForecastMonth}`,
                        FT02_ForecastValue: item.FT02_ForecastValue ? item.FT02_ForecastValue : null,
                        FT02_SubmitFlag: final ? 1 : 0
                    }
                }),
                FT01_ForecastMonth: `${data.ForecastHeader!.FT01_ForecastMonth}`,
                FT01_SubmitFlag: final ? 1 : 0,
                
            }

            console.log(postData)
            const response = await createCalendarLine(postData);
            if (response.success) {
                toast({ description: response.message });
                props.setShow?.(false);
                props.refetchforcastRegionwise();
            } else {
                toast({ description: response.message, title: 'Error' });
            }
        } else if (mode === 'edit') {
            postData = {
                ...data.ForecastHeader!,
                FT01_StatusDate:null,
                ForcastLines: ForecastLines.map(item => {
                    return {
                        ...item,
                        FT02_ForecastMonth: `${item!.FT02_ForecastMonth}`,
                        FT02_ForecastValue: item.FT02_ForecastValue ? item.FT02_ForecastValue : null,
                        FT02_SubmitFlag: final ? 1 : 0
                    }
                }),
                FT01_SubmitFlag: final ? 1 : 0,
                
            }

            // console.log(postData)

            const response = await updateCalendarLine({
                id: postData.FT01_ForecastHeaderID!,
                actionsLines: postData
            });
            if (response.success) {
                toast({ description: response.message });
                props.setShow?.(false);
                props.refetchforcastRegionwise();
            } else {
                toast({ description: response.message, title: 'Error' });
            }

        }
        setIsSubmitting(false);
    };


    
    const total = options.Regions.reduce((total, item) => total + (item.ForecastLines?.reduce((total, item) => total + (item.FT02_ForecastValue ? item.FT02_ForecastValue : 0), 0) || 0), 0)
    const isAnythingLoading = isLoadingRegion || isLoadingForecastLine
    const isAnythingFetching = isFetchingRegion || isFetchingForecastLine
    const isAnyError = isErrorRegion || isErrorLoadingForecastLine

    return (
        <ModalStandard open={props.show} onOpenChange={props.setShow} >
            <ModalStandardContent className="bg-gray-100 max-w-full min-w-full h-full flex flex-col">
                <form className=' grow flex flex-col' onSubmit={handleSubmit((data) => onSubmit(data))}>
                    <ModalStandardHeader className=' grow' >
                        <ModalStandardTitle>{mode === 'edit' ? 'Edit Regionwise Forecast' : 'Add Regionwise Forecast'}</ModalStandardTitle>
                        <ModalStandardDescription >
                            {isAnythingLoading && <div className=' px-2 p-1 rounded-md bg-vstargray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>}
                            <div className="overflow-x-auto">
                                <div className="inline-block min-w-full align-middle">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table className="min-w-full border border-gray-200 rounded-lg overflow-hidden">
                                            <thead className="bg-gray-100">
                                                <tr>
                                                    <th className="px-3 py-2 border-b border-gray-200">SI No</th>
                                                    <th className="px-3 py-2 border-b border-gray-200">Region</th>
                                                    <th className="px-3 py-2 border-b border-gray-200">Distributer</th>
                                                    <th className="px-3 py-2 border-b border-gray-200">EBO</th>
                                                    <th className="px-3 py-2 border-b border-gray-200">Institutions</th>
                                                    <th className="px-3 py-2 border-b border-gray-200">Other</th>
                                                    <th className="px-3 py-2 border-b border-gray-200">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white ">
                                                {
                                                    options?.Regions.map((region, indexRegion) => {

                                                        const total = region.ForecastLines.reduce((accumulator, currentValue) => {
                                                            return Number(accumulator) + Number(currentValue.FT02_ForecastValue)!;
                                                        }, 0);

                                                        return (
                                                            <tr >
                                                                <td className="px-3 py-2 border-b border-gray-200">{indexRegion + 1}</td>
                                                                <td className="px-3 py-2 border-b border-gray-200">{region.RegionName}</td>
                                                                {
                                                                    region.ForecastLines.map((forecastLine, indexLine) => {
                                                                        return (
                                                                            <td className=' px-3 py-2 border-b border-gray-200'>
                                                                                <InputStandard
                                                                                    className='min-w-[100px]'
                                                                                    {...register(`Regions.${indexRegion}.ForecastLines.${indexLine}.FT02_ForecastValue`, {
                                                                                        validate: (value) => {
                                                                                            if (!value) {
                                                                                                return true
                                                                                            }
                                                                                            if (isNaN(value)) {
                                                                                                return 'Not valid'
                                                                                            }
                                                                                        }
                                                                                    })}
                                                                                    disabled={isAnythingLoading || isAnyError}
                                                                                    onChange={(e) => {
                                                                                        const regex = /^[0-9]+$/;
                                                                                        if (e.target.value === '' || regex.test(e.target.value)) {
                                                                                            setValue(`Regions.${indexRegion}.ForecastLines.${indexLine}.FT02_ForecastValue`, e.target.value as unknown as number)
                                                                                            options.Regions[indexRegion].ForecastLines[indexLine].FT02_ForecastValue = Number(e.target.value)
                                                                                            setOptions({ ...options });
                                                                                        } else {
                                                                                            setValue(`Regions.${indexRegion}.ForecastLines.${indexLine}.FT02_ForecastValue`, watch(`Regions.${indexRegion}.ForecastLines.${indexLine}.FT02_ForecastValue`))
                                                                                            return;
                                                                                        }
                                                                                    }}

                                                                                    placeholder={forecastLine.FT02_ForecastChannel!}
                                                                                    error={errors?.Regions?.[indexRegion]?.ForecastLines?.[indexLine]?.FT02_ForecastValue?.message}
                                                                                />
                                                                            </td>
                                                                        )
                                                                    })
                                                                }
                                                                <td className=' px-3 py-2 border-b border-gray-200'>{total}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                <tr>
                                                    <td className=' px-3 py-2 border-b border-gray-200' colSpan={5}></td>
                                                    <td className=' px-3 py-2 border-b border-gray-200' >Total</td>
                                                    <td className=' px-3 py-2 border-b border-gray-200' >{total}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                            <div className='min-h-2'></div>

                        </ModalStandardDescription>
                    </ModalStandardHeader>
                    <ModalStandardFooter>
                        <ButtonIconSquare variant="white" icon={<IconX />} text="Close" onClick={(e) => {
                            props.setShow?.(false);
                            e?.preventDefault();
                        }} disabled={isSubmitting} />

                        <ButtonIconSquare
                            variant='secondary'
                            icon={isSubmitting ? <IconLoader className="animate-spin" /> : <IconLockFilled />}
                            text='Final submit'
                            disabled={isSubmitting || isAnythingLoading || isAnyError}
                            onClick={(e) => {
                                e?.preventDefault()
                                handleSubmit((data) => onSubmit(data, true))()
                            }}
                            className={
                                cn(forecastLines?.ForecastHeader?.[0]?.FT01_SubmitFlag === 1 ? 'hidden' : '')
                            }
                        />

                        <ButtonIconSquare
                            variant="secondary"
                            icon={isSubmitting ? <IconLoader className="animate-spin" /> : mode === 'add' ? <IconPlus /> : <IconEdit />}
                            text={mode === 'add' ? 'Add' : 'Edit'}
                            type="submit"
                            disabled={isSubmitting || isAnythingLoading || isAnyError}
                            className={
                                cn(forecastLines?.ForecastHeader?.[0]?.FT01_SubmitFlag === 1 ? 'hidden' : '')
                            }
                        />
                    </ModalStandardFooter>
                </form>
            </ModalStandardContent>
        </ModalStandard>
    );
}
