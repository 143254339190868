import { IconAlertTriangleFilled, IconDotsVertical, IconUxCircle } from '@tabler/icons-react'
import React from 'react'
import { ButtonIconSquare } from './buttons'

export type standardCellProperties = {
  width?: string,
  whiteSpace?: 'default'|'nowrap',
}
export type standardTableColumnDataType = {
  id: string,
  data: React.ReactNode,
}

export type StandardTableHeader = {
  label: string,
  id: string,
} & standardCellProperties

export type StandardTableRow = {
  id: string | number,
  datas: {
    [headerId: string]: standardTableColumnDataType;
  },
  settings?: {
  }
}

type DatasType = StandardTableRow['datas'];

export function TablePlater({ children, className }: { children?: React.ReactNode, className?: string }) {
  return (
    <div className={` grow overflow-auto rounded-xl h-full w-full bg-vstargray-100/60 dark:bg-vstargray-900 p-3 sm:p-7 ${className} `}>
      {children}
    </div>
  )
}
export interface StandardTableOptionButtonProps {
  onClick?: () => void;
}
export function StandardTableOptionButton(props: StandardTableOptionButtonProps) {
  return (
    <div onClick={props.onClick} className="flex items-center justify-center cursor-pointer hover:bg-vstargray-400/20 py-1 rounded-md">
      <IconDotsVertical className="w-5 h-5" />
    </div>
  )
}

export interface StandardTableProps {
  headers: StandardTableHeader[]
  rows: StandardTableRow[],
  isLoading?: boolean,
  isUpdating?: boolean,
  rowsPerPrevousePage?: number,
  onRowClick?: (row: DatasType, rowId: string | number, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,) => void,
  onColumnClick?: (cellData: standardTableColumnDataType, rowData: DatasType, rowId: string | number, e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>,) => void,
  isError?: boolean,
  onRetry?: () => void
}
export function StandardTable({ headers, rows, onRowClick, onColumnClick, isLoading, rowsPerPrevousePage = 7, isUpdating, isError, onRetry }: StandardTableProps) {


  if(isLoading===false&&rows.length===0){
    return (
      (<div className="flex flex-col items-center justify-center h-full">
        <IconUxCircle size={100} className="text-vstargray-500 text-5xl mb-4" />
        <p className="text-vstargray-800 text-lg mb-4 dark:text-vstargray-200 ">No data</p>
        <ButtonIconSquare
          text='Retry'
          onClick={onRetry}
        />
      </div>)
    )
  }

  if (isError) {
    return (
      (<div className="flex flex-col items-center justify-center h-full">
        <IconAlertTriangleFilled size={100} className="text-red-500 text-5xl mb-4" />
        <p className="text-vstargray-800 text-lg mb-4 dark:text-vstargray-200 ">Error Displaying Datas</p>
        <ButtonIconSquare
          text='Retry'
          onClick={onRetry}
        />
      </div>)
    )
  }

  return (
    <div className="overflow-x-auto relative min-h-32">
      {/* fetching indicator */}
      {isUpdating === true && isLoading === false && <div className=' absolute inset-0 bg-vstargray-300/20 z-10'>
        <div className=' mx-auto top-2 px-3 py-2 bg-vstargray-900/60 dark:bg-vstargray-200/60 dark:text-vstargray-900 text-vstargray-100 w-min rounded-md'>Updating...</div>
      </div>}
      <table className="table-auto w-full">
        <thead className="bg-vstargray-100 dark:bg-vstargray-800  sticky top-0">
          <tr>
            {headers.map((header) => (
              <th
                style={{
                  width: header.width,
                  whiteSpace: header.whiteSpace,
                }}
                className="px-4 py-2 text-left text-vstargray-500 dark:text-vstargray-200 text-xs" key={header.id}>{header.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!isLoading ? (
            rows.map((row, index) => (
              <tr
                className="hover:bg-vstarPrimary-500/10 border-b-2 border-transparent hover:border-vstarPrimary-500 cursor-pointer"
                onClick={(e) => onRowClick?.(row.datas, row.id, e)}
                key={index}
              >
                {headers.map((header) => (
                  <td
                    onClick={(e) => onColumnClick?.(row.datas[header.id], row.datas, row.id, e)}
                    className="px-4 py-[2px] whitespace-nowrap border-gray-300 text-left text-xs dark:text-vstargray-300" key={header.id}>{row.datas[header.id]?.data}</td>
                ))}
              </tr>
            ))
          ) : (
            [...Array(rowsPerPrevousePage ? rowsPerPrevousePage < 4 ? 4 : rowsPerPrevousePage : 7)].map((_, i) => (
              <tr key={i} className="">
                {headers.map((header) => (
                  <td key={header.id} className="px-4 h-[44.8px] py-2 border-b-2 border-transparent border-gray-300 text-left text-sm animate-pulse">
                    <div className=" h-3 bg-vstargray-300 px-4  rounded-full"></div>
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  )
}
