import { IconCloudDown, IconCloudUp, IconLoader, IconLoader2, IconPlus, IconSortDescending, IconTrashFilled, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { ButtonIconSquare } from 'src/components/buttons'
import { StandardTable, StandardTableHeader, StandardTableRow, TablePlater } from 'src/components/standard.table'
import { Pagination, PaginationKeys } from 'src/types/pagination'
import { ModalAddProduct } from './component.addModal'
import { DropdownMenuDemo } from './component.dropdownMenu'
import { StandardTablePagination } from '../../../../../components/standard.pagination'
import { useProductAPI } from 'src/API/rest.products'
import { toast } from 'src/shadcn/ui/use-toast'
import { StandardAlertDialog } from 'src/components/standard.alert-dialog'
import { useSkuAPI } from 'src/API/rest.sku'
import { ImportExcelModal } from './component.importExcelModal'
import { useDebounce } from 'use-debounce'
import { InputStandard } from 'src/components/standard.input'


export function SKUPage() {

    const searchParams = new URLSearchParams(window.location.search)
    const currentPageFromUrl = searchParams.get(PaginationKeys.CURRENT_PAGE)
    const dataPerPage = 15
    const [pagination, setPagination] = useState<Pagination>({
        currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
        totalPages: Math.ceil(1 / dataPerPage) || 1,
        dataPerPage: dataPerPage
    })
    const [skuRow, setSkuRow] = useState<StandardTableRow[]>([])
    const [previousDataLength, setPreviousDataLength] = useState(0)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [openImportModal, setOpenImportModal] = useState(false)
    const [selectedSkuId, setSelectedSKUId] = useState<number | null | string>()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [mode, setMode] = useState<'add' | 'edit'>('add')
    const [search, setSearch] = useState('')
    const [debouncedInput] = useDebounce(search, 500);


    const {
        skus,
        error,
        isError,
        isFetched,
        isFetching,
        isLoading,
        totalSkuCount,
        isDeleting,
        refetchSkus,
        deleteSkuById,

    } = useSkuAPI({
        page_size: pagination.dataPerPage,
        page: pagination.currentPage,
        search: debouncedInput
    })



    const headers: StandardTableHeader[] = [
        { label: 'SKU Code', id: 'SKUCode' },
        { label: 'EAN Code', id: 'EANCode' },
        { label: 'SKU Name', id: 'SKUName' },
        { label: 'Product', id: 'Product' },
        { label: 'Category', id: 'Category' },
        { label: 'Style', id: 'Style' },
        { label: '', id: 'actions', width: '10px' },
    ]



    const deleteSKU = async () => {

        if (selectedSkuId) {
            const response = await deleteSkuById({
                skuId: selectedSkuId
            })
            if (response.success === true) {
                toast({
                    title: 'Success',
                    description: response.message,
                })
                refetchSkus()
                setOpenDeleteModal(false)
            } else {
                toast({
                    title: 'Error',
                    description: response.message,
                })
            }
            setSelectedSKUId(null)
        }

    }


    useEffect(
        () => {
            // setting prevouse data length for loading optimization
            if (isFetched && !isFetching) {
                console.log(skus?.length)
                setPreviousDataLength(skus?.length || 0)
            }

            //if successfully loaded
            if (isFetched && !isError) {
                setPagination({
                    currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
                    totalPages: Math.ceil(totalSkuCount / dataPerPage) || 1,
                    dataPerPage: dataPerPage
                })
                setSkuRow(skus?.map(
                    (skus) => {
                        return ({
                            id: String(skus.FM15_SKUID),
                            datas: {
                                SKUCode: { id: 'SKUCode', data: skus.FM15_SKUCode },
                                EANCode: { id: 'EANCode', data: skus.FM15_EANCode },
                                SKUName: { id: 'SKUName', data: skus.FM15_SKUName },
                                Product: { id: 'Product', data: skus.FM15_M13_ProductID?.name },
                                Category: { id: 'Category', data: skus.FM14_M12_ProductCategory?.name },
                                Style: { id: 'Style', data: skus.FM15_M14_SubAttributeIDStyle?.name },
                                actions: {
                                    id: 'actions', data: <DropdownMenuDemo
                                        rowId={String(skus.FM15_SKUID)}
                                        onDeleteAction={(rowid) => {
                                            setSelectedSKUId(rowid);
                                            setOpenDeleteModal(true);
                                        }}
                                        onEditAction={(rowid) => {
                                            setSelectedSKUId(rowid);
                                            setMode('edit');
                                            setOpenAddModal(true);
                                        }}
                                    />
                                }
                            }
                        })

                    }
                ) || [])
            }
            console.log(skuRow)
            if (isError && isFetching === false) {
                toast({
                    description: String(error),
                })

            }
        }, [isFetching]
    )


    return (
        <div className=' grow  flex flex-col overflow-auto'>
            <StandardAlertDialog
                title="Are you absolutely sure?"
                description="This action cannot be undone. Are you sure you want to delete this product?"
                positiveLabel="Delete"
                negativeLabel="Cancel"
                positiveAction={deleteSKU}
                negativeAction={() => setOpenDeleteModal(false)}
                positiveIcon={isDeleting ? <IconLoader2 size={16} className=" animate-spin" /> : <IconTrashFilled size={16} />}
                negativeIcon={<IconX size={16} />}
                disableNegativeButton={isDeleting}
                disablePositiveButton={isDeleting}
                show={openDeleteModal}
            />
            <ModalAddProduct
                show={openAddModal}
                setShow={setOpenAddModal}
                refetchSkus={refetchSkus}
                mode={mode}
                productId={selectedSkuId}
            />
            <ImportExcelModal
                show={openImportModal}
                setShow={setOpenImportModal}
            />
            <TablePlater className=' flex flex-col gap-2'>
                {/* Buttons section */}
                <div className=' flex justify-end w-full gap-4'>
                    <InputStandard onChange={(e) => setSearch(e.target.value)} className=' lg:w-[400px] ' placeholder='Search' />

                    {/* <ButtonIconSquare variant="primary" icon={<IconSortDescending />} text="Filter" onClick={() => { }} /> */}
                    <ButtonIconSquare variant="white" icon={<IconCloudUp />} text="Import" onClick={() => { setOpenImportModal(true) }} />
                    <ButtonIconSquare variant="secondary" icon={<IconPlus />} text="Add sku" onClick={() => { setMode('add'); setOpenAddModal(true); }} />
                </div>
                <StandardTable
                    headers={headers}
                    rows={skuRow}
                    isLoading={isLoading}
                    rowsPerPrevousePage={previousDataLength}
                    isUpdating={isFetching}
                    isError={isError && isFetching === false}
                    onRetry={() => refetchSkus()}
                />
                <StandardTablePagination currentPage={pagination.currentPage} totalPages={pagination.totalPages} onChangePage={(page) => {
                    setPagination({ ...pagination, currentPage: page })
                }} />

            </TablePlater>
        </div>
    )

}



