import axios, { AxiosError, AxiosProgressEvent, AxiosRequestConfig, ResponseType } from "axios"
import { useNavigate } from "react-router-dom"

type mode = 'protected' | 'public'

type RestCommonProps = {
    url: string,
    params?: AxiosRequestConfig['params'],
    mode?: mode,
    responseType?:ResponseType
}

type RestDataProps<T> = RestCommonProps & {
    data?: T
}

type postMethodProps = {
    onUploadProgress?:((progressEvent: AxiosProgressEvent) => void)
}

type ResponseCommonKeys = {
    success: boolean,
    message: string
}

export type AxiosResponseCustom<T> = ResponseCommonKeys & T

export function useRestAPI() {


    const navigate = useNavigate()

    // GET request
    const get = async <T>({ url, params, mode = 'public',responseType }: RestCommonProps): Promise<AxiosResponseCustom<T>> => {
        try {

            const headers: any = {

            }
            if (mode === 'protected') {
                headers.Authorization = `Bearer ${localStorage.getItem('accessTocken')}`
            }

            const response = await axios.get<AxiosResponseCustom<T>>(url, {
                params: params,
                headers,
                responseType:responseType
            })

            return response.data
        } catch (error: AxiosError | any) {
            return handleError(error)
        }
    }

    // POST request
    const post = async <T, D>({ url, data, params, mode = 'public',onUploadProgress }: RestDataProps<D>&postMethodProps): Promise<AxiosResponseCustom<T>> => {
        try {
            const headers: any = {

            }
            if (mode === 'protected') {
                headers.Authorization = `Bearer ${localStorage.getItem('accessTocken')}`
            }
            const response = await axios.post<AxiosResponseCustom<T>>(url, data, {
                params: params,
                headers,
                onUploadProgress
            })
            return response.data
        } catch (error: AxiosError | any) {
            return handleError(error)
        }
    }

    // PUT request
    const put = async <T, D>({ url, data, params, mode = 'public' }: RestDataProps<D>): Promise<AxiosResponseCustom<T>> => {
        try {
            const headers: any = {

            }
            if (mode === 'protected') {
                headers.Authorization = `Bearer ${localStorage.getItem('accessTocken')}`
            }
            const response = await axios.put<AxiosResponseCustom<T>>(url, data, {
                params: params,
                headers
            })
            return response.data
        } catch (error: AxiosError | any) {
            return handleError(error)
        }
    }
    // PATCH request
    const patch = async <T, D>({ url, data, params, mode = 'public' }: RestDataProps<D>): Promise<AxiosResponseCustom<T>> => {
        try {
            const headers: any = {

            }
            if (mode === 'protected') {
                headers.Authorization = `Bearer ${localStorage.getItem('accessTocken')}`
            }
            const response = await axios.patch<AxiosResponseCustom<T>>(url, data, {
                params: params,
                headers
            })
            return response.data
        } catch (error: AxiosError | any) {
            return handleError(error)
        }
    }

    // DELETE request
    const del = async <T>({ url, params, mode = 'public' }: RestCommonProps): Promise<AxiosResponseCustom<T>> => {
        try {
            const headers: any = {

            }
            if (mode === 'protected') {
                headers.Authorization = `Bearer ${localStorage.getItem('accessTocken')}`
            }
            const response = await axios.delete<AxiosResponseCustom<T>>(url, {
                params: params,
                headers
            })
            return response.data
        } catch (error: AxiosError | any) {
            return handleError(error)
        }
    }

    // Error handling function
    const handleError = (error: AxiosError | any): AxiosResponseCustom<any> => {
        if(error.response?.status==401){
            navigate('/')
            return ;
        }
        if (error.response?.data && error.response.data.success === false && error.response.data.message) {
            return error.response.data
        }
        if (error.code === 'ERR_NETWORK') {
            return {
                success: false,
                message: 'Network Error'
            }
        }
        return {
            success: false,
            message: 'Server Error'
        }
    }

    return {
        get,
        post,
        put,
        patch,
        delete: del
    }
}