import { useEffect, useState } from 'react'
import { Progress } from '../shadcn/ui/progress'


type props = {
  show: boolean,
  progress?: number,
  subTitle?: string
}
export const ProgressFullScreen = (props: props) => {



  return (
    <div
      className={`fixed top-0 left-0 z-[100] h-full w-full bg-background/50 backdrop-blur-sm transition-opacity duration-500 ${props.show ? 'block' : 'hidden'
        }`}
    >
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className=' flex flex-col items-center'>
          <Progress className="w-56" value={props.progress || 0} />
          <p className="text-sm text-muted-foreground dark:text-vstargray-200">{props.subTitle}</p>
        </div>
      </div>
    </div>
  )
}
