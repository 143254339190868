import { IconEdit, IconLoader, IconPlus, IconX } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDistributorAPI } from 'src/API/rest.distributer';
import { useEmployeeAPI, useEmployeeSEsUnderASMsAPI } from 'src/API/rest.employee';
import { useEmployeeDistributorMappingAPI } from 'src/API/rest.employee_distributer_mapping';
import { useProductCategoryAPI } from 'src/API/rest.product_category';
import { useProductSubCategoryAPI } from 'src/API/rest.product_sub_category';
import { useProductAPI } from 'src/API/rest.products';
import { useRegionAPI } from 'src/API/rest.region';
import { useStatesAPI } from 'src/API/rest.states';
import { ButtonIconSquare } from 'src/components/buttons';
import { InputStandard } from 'src/components/standard.input';
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from 'src/components/standard.modal';
import { MultiselectStandard } from 'src/components/standard.multiselect';
import { SelectStandard } from 'src/components/standard.select';
import { SwitchDemo } from 'src/components/standard.switch';
import { RootState } from 'src/redux/store';
import { toast } from 'src/shadcn/ui/use-toast';

type Props = {
    show?: boolean,
    setShow?: (show: boolean) => void,
    refetchEmployeeDistributorMapping: () => void,
    mode: 'add' | 'edit',
    employeeDistributorMappingId?: number | string | null
};

export type EmployeeStateMappingFormType = {
    FM16_EmployeeDistributorMappingID?: number,
    FM16_M11_DistributorID: number,
    FM16_M04_StateName: number,
    FM16_M07_RegionName: number,
    FM14_M12_ProductSubCategory: number,
    FM14_M12_ProductCategory: number,
    FM16_M13_ProductID: number,
    FM16_M03_ExecutiveID: number,
    FM16_M03_ExecutiveName: string,
    FM16_M03_ASMID: number,
    FM16_M03_ASMName: string,
    FM16_M03_RSMID: number,
    FM16_M03_RSMName: string,
    FM16_Status: 1 | 0,
    FM16_StatusDate: string
};

export function ModalAddEmployeeDistributorMapping(props: Props) {

    const { register, handleSubmit, setValue, reset, formState: { errors }, watch } = useForm<EmployeeStateMappingFormType>({
        defaultValues: {

        }
    });

    const currentUser = useSelector((state: RootState) => state.user.employeeDetails)

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const {
        readEmployeeDistributorMappingById,
        createEmployeeDistributorMapping,
        updateEmployeeDistributorMapping,
        isReadingEmployeeDistributorMapping
    } = useEmployeeDistributorMappingAPI({ trigger: props.show });

    const {
        states,
        isLoading: isLoadingStates,
        isFetching: isFetchingStates
    } = useStatesAPI({
        page_size: 100,
        trigger: props.show
    })
    const {
        regions,
        isLoading: isLoadingRegions,
        isFetching: isFetchingRegions
    } = useRegionAPI({
        page_size: 100,
        trigger: props.show
    })

    const selectedCategoryId = watch('FM14_M12_ProductCategory')
    const selectedSubCategoryId = watch('FM14_M12_ProductSubCategory')



    const {
        productCategories,
        isLoading: isLoadingProductCategories,
        isFetching: isFetchingProductCategories
    } = useProductCategoryAPI({
        page_size: 100,
        trigger: props.show
    })

    const selectedCategory = productCategories?.find((category) => category.FM12_ProductCategoryID == selectedCategoryId)

    const {
        productCategories: productSubCategories,
        isLoading: isLoadingProductSubCategories,
        isFetching: isFetchingProductSubCategories
    } = useProductCategoryAPI({
        page_size: 100,
        trigger: props.show && selectedCategoryId ? true : false,
        FM12_ProductCategory: selectedCategory?.FM12_ProductCategory
    })

    const selectedSubCategories = productSubCategories?.find((category) => category.FM12_ProductCategoryID == selectedSubCategoryId)


    const {
        products,
        isLoading: isLoadingProducts,
        isFetching: isFetchingProducts
    } = useProductAPI({
        page_size: 100,
        trigger: props.show &&selectedSubCategories?true:false,
        FM13_M12_ProductSubCategory: selectedSubCategories?.FM12_ProductCategoryID
    })

    const {
        ses,
        isLoading: isLoadingEmployees,
        isFetching: isFetchingEmployees
    } = useEmployeeSEsUnderASMsAPI({
        trigger: props.show && currentUser.FM03_EmployeeID ? true : false,
        asmId: currentUser.FM03_EmployeeID!
    })

    const {
        distributors,
        error,
        isError,
        isFetched,
        isFetching,
        isLoading,
        totalDistributorCount,
        isDeleting,
        refetchDistributors,
        deleteDistributorById,

    } = useDistributorAPI({
        page_size: 100,
        page: 1
    })

    useEffect(() => {
        if (props.show && props.mode === 'add') {
            // reset({
            //     DistributorID: null,
            //     state_name: null,
            //     region_name: null,
            //     ProductSubCategory: null,
            //     ProductCategory: null,
            //     ProductID: null,
            //     executive_id: null,
            //     ASMID: null,
            //     ASMName: '',
            //     RSMID: null,
            //     RSMName: '',
            //     status: true,
            //     status_date: ''
            // });
        }
        if (props.mode === 'edit' && props.employeeDistributorMappingId && props.show) {
            readEmployeeDistributorMappingById({ id: props.employeeDistributorMappingId }).then(response => {
                if (response.success) {
                    // reset({
                    //     DistributorID: response.data.DistributorID.id,
                    //     state_name: response.data.state_name.id,
                    //     region_name: response.data.region_name.id,
                    //     ProductSubCategory: response.data.ProductSubCategory.id,
                    //     ProductCategory: response.data.ProductCategory.id,
                    //     ProductID: response.data.ProductID.id,
                    //     executive_id: response.data.executive_id.id,
                    //     ASMID: response.data.ASMID as unknown as number,
                    //     ASMName: response.data.ASMName,
                    //     RSMID: response.data.RSMID as unknown as number,
                    //     RSMName: response.data.RSMName,
                    //     status: response.data.status,
                    //     status_date: response.data.status_date
                    // });
                } else {
                    toast({
                        description: response.message,
                        title: 'Error'
                    });
                }
            });
        }
    }, [props.show]);

    const onSubmit = async (data: EmployeeStateMappingFormType) => {
        setIsSubmitting(true);
        // data.status_date = new Date().toISOString();
        if (props.mode === 'add') {
            const response = await createEmployeeDistributorMapping({
                ...data,
                FM16_M03_ExecutiveName: ses?.find((s) => s.FM03_EmployeeID === data.FM16_M03_ExecutiveID)?.FM03_EmployeeName||'',
                FM16_M03_ASMID:currentUser.FM03_EmployeeID!,
            });
            if (response.success) {
                toast({ description: response.message });
                props.setShow?.(false);
                props.refetchEmployeeDistributorMapping();
            } else {
                toast({ description: response.message, title: 'Error' });
            }
        } else if (props.mode === 'edit' && props.employeeDistributorMappingId) {
            // const response = await updateEmployeeDistributorMapping({ id: props.employeeDistributorMappingId, data: data as EmployeeDistributorMapping });
            // if (response.success) {
            //     toast({ description: response.message });
            //     props.setShow?.(false);
            //     props.refetchEmployeeDistributorMapping();
            // } else {
            //     toast({ description: response.message, title: 'Error' });
            // }
        }
        setIsSubmitting(false);
    };

    const isanythingLoading = isSubmitting || isReadingEmployeeDistributorMapping || isFetchingProductCategories ||
        isFetchingProducts || isFetchingStates || isLoadingProductCategories || isLoadingProducts || isLoadingStates ||
        isFetchingRegions || isLoadingRegions || isFetchingEmployees || isLoadingEmployees;



    return (
        <ModalStandard open={props.show} onOpenChange={props.setShow}>
            <ModalStandardContent className="bg-gray-100">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalStandardHeader>
                        <ModalStandardTitle>{props.mode === 'edit' ? 'Edit Distributer mapping' : 'Add Distributer mapping'}</ModalStandardTitle>
                        <ModalStandardDescription>
                            {isanythingLoading && <div className=' px-2 p-1 rounded-md bg-vstargray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>}
                            <div className="grid gap-4 py-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">

                                <div className=" lg:col-span-1 xl:col-span-1">
                                    <SelectStandard
                                        placeHolder='Distributer'
                                        options={distributors?.map((distributor) => ({ label: distributor.FM11_DistributorName!, value: String(distributor.FM11_DistributorID) })) || []}
                                        title='Distributor'
                                        {...register("FM16_M11_DistributorID", { required: "Distributor is required" })}
                                        error={errors.FM16_M11_DistributorID?.message}
                                        disabled={isanythingLoading}
                                    />
                                </div>
                                <div className=" lg:col-span-1 xl:col-span-1">
                                    <SelectStandard
                                        placeHolder='State'
                                        title='State'
                                        options={states?.map((state) => ({ label: state.FM04_StateName!, value: String(state.FM04_StateID) })) || []}
                                        {...register("FM16_M04_StateName", { required: "State is required" })}
                                        error={errors.FM16_M04_StateName?.message}
                                        disabled={isanythingLoading}
                                    />
                                </div>
                                <div className=" lg:col-span-1 xl:col-span-1">
                                    <SelectStandard
                                        placeHolder='Region'
                                        title='Region'
                                        options={regions?.map((region) => ({ label: region.FM07_RegionName!, value: String(region.FM07_RegionID) })) || []}
                                        {...register("FM16_M07_RegionName", { required: "Region is required" })}
                                        error={errors.FM16_M07_RegionName?.message}
                                        disabled={isanythingLoading}
                                    />
                                </div>
                                <div className=" lg:col-span-1 xl:col-span-1">
                                    <SelectStandard
                                        placeHolder='Category'
                                        title='Category'
                                        options={
                                            productCategories?.filter(category=>category.FM12_ProductSubCategory).map((productCategory) =>
                                                ({ label: productCategory.FM12_ProductCategory!, value: String(productCategory.FM12_ProductCategoryID) })
                                            ) || []}
                                        {...register("FM14_M12_ProductCategory", { required: "Category is required" })}
                                        error={errors.FM14_M12_ProductCategory?.message}
                                        disabled={isanythingLoading}
                                        value={selectedCategoryId?String(selectedCategoryId):''}
                                    />
                                </div>
                                <div className=" lg:col-span-1 xl:col-span-1">
                                    <SelectStandard
                                        placeHolder='Sub Category'
                                        title='Sub Category'
                                        options={productSubCategories?.map((productCategory) => ({ label: productCategory.FM12_ProductSubCategory!, value: String(productCategory.FM12_ProductCategoryID) })).filter(categoey => categoey.label) || []}
                                        {...register("FM14_M12_ProductSubCategory", { required: "Sub Category is required" })}
                                        error={errors.FM14_M12_ProductSubCategory?.message}
                                        disabled={isanythingLoading||!selectedCategoryId}
                                        value={selectedSubCategoryId?String(selectedSubCategoryId):''}
                                    />
                                </div>
                                <div className=" lg:col-span-1 xl:col-span-1">
                                    <SelectStandard
                                        placeHolder='Product'
                                        title='Product'
                                        options={products?.map((product) => ({ label: product.FM13_ProductDescription!, value: String(product.FM13_ProductID) })) || []}
                                        {...register("FM16_M13_ProductID", { required: "Product is required" })}
                                        error={errors.FM16_M13_ProductID?.message}
                                        disabled={isanythingLoading||!selectedSubCategoryId}
                                    />
                                </div>
                                <div className=" lg:col-span-1 xl:col-span-1">
                                    <SelectStandard
                                        placeHolder='Executive'
                                        title='Executive'
                                        options={ses?.map((executive) => ({ label: executive.FM03_EmployeeName!, value: String(executive.FM03_EmployeeID) })) || []}
                                        {...register("FM16_M03_ExecutiveID", { required: "Executive is required" })}
                                        error={errors.FM16_M03_ExecutiveID?.message}
                                        disabled={isanythingLoading}
                                    />
                                </div>



                                <SwitchDemo
                                    {...register('FM16_Status')}
                                    title="Status" value={watch('FM16_Status') == 1 ? true : false}
                                    disabled={isanythingLoading}
                                />
                            </div>
                        </ModalStandardDescription>
                    </ModalStandardHeader>
                    <ModalStandardFooter>
                        <ButtonIconSquare variant="white" icon={<IconX />} text="Close" onClick={() => props.setShow?.(false)} disabled={isSubmitting} />
                        <ButtonIconSquare variant="secondary" icon={isSubmitting ? <IconLoader className="animate-spin" /> : props.mode === 'add' ? <IconPlus /> : <IconEdit />} text={props.mode === 'add' ? 'Add' : 'Edit'} type="submit" disabled={isSubmitting} />
                    </ModalStandardFooter>
                </form>
            </ModalStandardContent>
        </ModalStandard>
    );
}
