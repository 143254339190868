import { IconEdit, IconLoader, IconPlus, IconX } from '@tabler/icons-react'
import React, { useEffect } from 'react'
import { Control, FieldValues, set, useForm } from 'react-hook-form'
import { useBranchAPI } from 'src/API/rest.branch'
import { useProductCategoryAPI } from 'src/API/rest.product_category'
import { useDepartmentAPI } from 'src/API/rest.department'
import { useProductAPI } from 'src/API/rest.products'
import { SkuDataResponse, useSkuAPI } from 'src/API/rest.sku'
import { ButtonIconSquare } from 'src/components/buttons'
import { InputStandard } from 'src/components/standard.input'
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle, ModalStandardTrigger } from 'src/components/standard.modal'
import { SelectStandard } from 'src/components/standard.select'
import { SwitchDemo } from 'src/components/standard.switch'
import { Input } from 'src/shadcn/ui/input'
import { Label } from 'src/shadcn/ui/label'
import { DialogFooter } from 'src/shadcn/ui/react-dialog'
import { toast } from 'src/shadcn/ui/use-toast'
import { useSubAttributeAPI } from 'src/API/rest.subattribute'
import { useProductSubCategoryAPI } from 'src/API/rest.product_sub_category'

type Props = {

    show?: boolean,
    setShow?: (show: boolean) => void,
    refetchSkus: () => void,
    mode: 'add' | 'edit',
    productId?: number | string | null
}
type SkuData = {
    FM15_SKUID?: number,
    FM15_SKUCode: string,
    FM15_EANCode: string,
    FM15_SKUName: string,
    FM15_M14_SubAttributeIDStyle: number | null,
    FM15_StockCategory: string,
    FM15_UOM: string,
    FM15_M13_ProductID: number | null,
    FM14_M12_ProductSubCategory: number | null,
    FM14_M12_ProductCategory: number | null,
    FM15_NewSKUFlag: number,
    FM15_ASP: number | null,
    FM15_MRP: number | null,
    FM15_Status: number,
    FM15_StatusDate: string | null
}

export function ModalAddProduct(props: Props) {

    const { register, handleSubmit, setValue, reset, formState: { errors }, control, watch } = useForm<SkuData>({
        defaultValues: {
        }
    });

    const selectedCategoryId = watch('FM14_M12_ProductCategory')
    const selectedSubCategoryId = watch('FM14_M12_ProductSubCategory')
    const selectedProductId = watch('FM15_M13_ProductID')

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const {
        productCategories,
        isLoading: isProductCategoryLoading,
        isFetching: isProductCategoryFetching,
    } = useProductCategoryAPI({
        page_size: 100,
        trigger: props.show
    })

    const selectedCategory = productCategories?.find((category) => category.FM12_ProductCategoryID == selectedCategoryId)

    const {
        productCategories: productSubCategories,
        isLoading: isLoadingProductSubCategories,
        isFetching: isFetchingProductSubCategories
    } = useProductCategoryAPI({
        page_size: 100,
        trigger: props.show && selectedCategoryId ? true : false,
        FM12_ProductCategory: selectedCategory?.FM12_ProductCategory
    })

    const {
        subAttributes,
        isLoading: isLoadingsubAttributes,
        isFetching: isFetchingSubAttributes
    } = useSubAttributeAPI({
        trigger: props.show,
        FM14_M13_ProductID: selectedProductId || undefined
    })

    const {
        products,
        isLoading: isLoadingProducts,
        isFetching: isFetcingProducts
    } = useProductAPI({
        trigger: props.show,
        FM13_M12_ProductSubCategory: selectedSubCategoryId ? selectedSubCategoryId : undefined
    })

    const {
        readSkuById,
        createSku,
        updateSku,
        isReadingSku
    } = useSkuAPI({})


    useEffect(
        () => {
            console.log(props.show)
            if (props.show && props.mode === 'add') {
                reset({
                    FM15_SKUID: undefined,
                    FM15_SKUCode: '',
                    FM15_EANCode: '',
                    FM15_SKUName: '',
                    FM15_M14_SubAttributeIDStyle: 0,
                    FM15_StockCategory: '',
                    FM15_UOM: '',
                    FM15_M13_ProductID: null,
                    FM14_M12_ProductSubCategory: null,
                    FM14_M12_ProductCategory: null,
                    FM15_NewSKUFlag: 0,
                    FM15_ASP: null,
                    FM15_MRP: null,
                    FM15_Status: 1,
                    FM15_StatusDate: null,

                })
            }
            if (props.mode === 'edit' && props.productId && props.show) {
                readSkuById({ skuId: props.productId }).then((response) => {
                    if (response.success === true) {
                        reset({
                            FM15_SKUID: undefined,
                            FM15_SKUCode: response.data.FM15_SKUCode,
                            FM15_EANCode: response.data.FM15_EANCode,
                            FM15_SKUName: response.data.FM15_SKUName,
                            FM15_M14_SubAttributeIDStyle: response.data.FM15_M14_SubAttributeIDStyle?.id || 0,
                            FM15_StockCategory: response.data.StockCategory,
                            FM15_UOM: response.data.FM15_UOM,
                            FM15_M13_ProductID: response.data.FM15_M13_ProductID?.id,
                            FM14_M12_ProductSubCategory: response.data.FM14_M12_ProductSubCategory?.id,
                            FM14_M12_ProductCategory: response.data.FM14_M12_ProductCategory?.id,
                            FM15_NewSKUFlag: response.data.FM15_NewSKUFlag,
                            FM15_ASP: response.data.FM15_ASP,
                            FM15_MRP: response.data.FM15_MRP,
                            FM15_Status: response.data.FM15_Status,
                            FM15_StatusDate: response.data.FM15_StatusDate
                        })
                    } else {
                        toast({
                            description: response.message,
                            title: "error",
                            // variant:''
                        })
                    }
                })
            }
        }, [props.show]
    )

    const onSubmit = async (data: SkuData) => {

        setIsSubmitting(true)
        if (props.mode === 'add') {
            const response = await createSku(data as SkuDataResponse)
            if (response.success === true) {
                toast({
                    description: response.message,
                    // variant:''
                })
                props.setShow?.(false);
                props.refetchSkus()
            } else {
                toast({
                    description: response.message,
                    title: "error",
                    // variant:''
                })
            }
        } else if (props.mode === 'edit' && props.productId) {
            const response = await updateSku({
                id: props.productId,
                Sku: data as SkuDataResponse
            })
            if (response.success === true) {
                toast({
                    description: response.message,
                    // variant:''
                })
                props.setShow?.(false);
                props.refetchSkus()
            } else {
                toast({
                    description: response.message,
                    title: "error",
                    // variant:''
                })
            }
        }
        setIsSubmitting(false)
    };

    const isAnythingLoading = isLoadingProductSubCategories || isFetchingProductSubCategories || isLoadingProducts || isFetcingProducts || isReadingSku || isProductCategoryLoading || isLoadingsubAttributes || isProductCategoryFetching || isFetchingSubAttributes
    const UOM = watch('FM15_UOM')
    const ProductID = watch('FM15_M13_ProductID')
    const SubAttributeIDStyle = watch('FM15_M14_SubAttributeIDStyle')
    const StockCategory = watch('FM15_StockCategory')

    return (
        <ModalStandard
            open={props.show}
            onOpenChange={props.setShow}
        >
            <ModalStandardContent onOpenAutoFocus={(e) => e?.preventDefault()} className='bg-vstargray-100'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalStandardHeader >
                        <ModalStandardTitle >{props.mode === 'edit' ? 'Edit SKU' : 'Add SKU'}</ModalStandardTitle>
                        <ModalStandardDescription>
                            {/* loading indication */}
                            {isAnythingLoading && <div className=' px-2 p-1 rounded-md bg-vstargray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>}
                            <form onSubmit={handleSubmit(onSubmit)} className=" pb-3">
                                <div className='grid gap-4 py-4 xs2:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 '>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='SKU Code'
                                            title='SKU Code'
                                            {...register("FM15_SKUCode", { required: "SKU Code is required" })}
                                            error={errors.FM15_SKUCode?.message}
                                            disabled={isAnythingLoading}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='EAN Code'
                                            title='EAN Code'
                                            {...register("FM15_EANCode", { required: "EAN Code is required" })}
                                            error={errors.FM15_EANCode?.message}
                                            disabled={isAnythingLoading}
                                        />
                                    </div>

                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <SelectStandard
                                            title='UOM'
                                            {...register("FM15_UOM", { required: "UOM is required" })}
                                            error={errors.FM15_UOM?.message}
                                            disabled={isAnythingLoading}
                                            options={[
                                                { value: 'Unit', label: 'Unit' },
                                                { value: 'Pair', label: 'Pair' },
                                            ]}
                                            value={UOM}
                                        />
                                    </div>


                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <SelectStandard
                                            title='Stock Category'
                                            {...register("FM15_StockCategory", { required: "Stock Category is required" })}
                                            error={errors.FM15_StockCategory?.message}
                                            disabled={isAnythingLoading}
                                            value={StockCategory}
                                            options={[
                                                { value: 'Raw Material', label: 'Raw Material' },
                                                { value: 'Finished Goods', label: 'Finished Goods' },
                                                { value: 'Semi Finished Goods', label: 'Semi Finished Goods' },
                                                { value: 'Scrap', label: 'Scrap' },
                                            ]}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <SelectStandard
                                            title='Product Category'
                                            {...register("FM14_M12_ProductCategory", { required: "Product Category is required" })}
                                            error={errors.FM14_M12_ProductCategory?.message}
                                            disabled={isAnythingLoading}
                                            value={selectedCategoryId ? String(selectedCategoryId) : undefined}
                                            options={productCategories?.filter(category => category.FM12_ProductSubCategory).map((item) => ({ value: String(item.FM12_ProductCategoryID), label: item.FM12_ProductCategory })) || []}
                                            onChange={(value) => {
                                                setValue('FM14_M12_ProductCategory', Number(value.target.value));
                                                setValue('FM14_M12_ProductSubCategory', null)
                                                setValue('FM15_M13_ProductID', null)
                                                setValue('FM15_M14_SubAttributeIDStyle', null)
                                            }
                                            }
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <SelectStandard
                                            title='Product Sub Category'
                                            {...register("FM14_M12_ProductSubCategory", { required: "Product Sub Category is required" })}
                                            error={errors.FM14_M12_ProductSubCategory?.message}
                                            disabled={isAnythingLoading || !selectedCategoryId}
                                            options={productSubCategories?.map((productCategory) => ({ label: productCategory.FM12_ProductSubCategory!, value: String(productCategory.FM12_ProductCategoryID) })).filter(categoey => categoey.label) || []}
                                            value={selectedSubCategoryId ? String(selectedSubCategoryId) : null as any as undefined}
                                            onChange={(value) => {
                                                setValue('FM14_M12_ProductSubCategory', Number(value.target.value));
                                                // setValue('FM14_M12_ProductSubCategory', null)
                                                setValue('FM15_M13_ProductID', null)
                                                setValue('FM15_M14_SubAttributeIDStyle', null)
                                            }}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <SelectStandard
                                            title='Product'
                                            {...register("FM15_M13_ProductID", { required: "Product ID is required" })}
                                            error={errors.FM15_M13_ProductID?.message}
                                            disabled={isAnythingLoading || !selectedSubCategoryId}
                                            options={products?.map((product) => ({ value: String(product.FM13_ProductID!), label: product.FM13_ProductCode! })) || []}
                                            value={ProductID ? String(ProductID) : null as any as undefined}
                                            onChange={(value) => {
                                                setValue('FM15_M13_ProductID', Number(value.target.value))
                                                setValue('FM15_M14_SubAttributeIDStyle', null)
                                            }}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <SelectStandard
                                            title='Sub Attribute'
                                            {...register("FM15_M14_SubAttributeIDStyle", { required: "Sub Attribute is required" })}
                                            error={errors.FM15_M14_SubAttributeIDStyle?.message}
                                            disabled={isAnythingLoading || !ProductID}
                                            options={subAttributes?.map((subAttribute) => ({ value: String(subAttribute.FM14_SubAttributeID), label: subAttribute.FM14_SubAttributeName! })) || []}
                                            placeHolder='Select Sub Attribute'
                                            value={SubAttributeIDStyle ? String(SubAttributeIDStyle) : null as any as undefined}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='SKU Name'
                                            title='SKU Name'
                                            {...register("FM15_SKUName", { required: "SKU Name is required" })}
                                            error={errors.FM15_SKUName?.message}
                                            disabled={isAnythingLoading}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            type="number"
                                            {...register("FM15_ASP", { required: "ASP is required" })}
                                            placeholder='ASP'
                                            title='ASP'
                                            error={errors.FM15_ASP?.message}
                                            disabled={isAnythingLoading}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            type="number"
                                            {...register("FM15_MRP", { required: "MRP is required" })}
                                            placeholder='MRP'
                                            title='MRP'
                                            error={errors.FM15_MRP?.message}
                                            disabled={isAnythingLoading}
                                        />
                                    </div>
                                </div>
                                <div className=' flex flex-col items-center '>
                                    <div className=' flex flex-col gap-2 items-start'>
                                        <SwitchDemo
                                            {...register("FM15_NewSKUFlag")}
                                            title={'New SKU Flag'}
                                            value={watch('FM15_NewSKUFlag') ? true : false}
                                            disabled={isAnythingLoading}
                                        />
                                        <SwitchDemo
                                            {...register("FM15_Status")}
                                            title={'Status'}
                                            value={watch('FM15_Status') == 1 ? true : false}
                                            disabled={isAnythingLoading}
                                        />


                                    </div>

                                </div>
                            </form>
                        </ModalStandardDescription>
                    </ModalStandardHeader>
                    <ModalStandardFooter>
                        <ButtonIconSquare
                            variant='white'
                            icon={<IconX />}
                            text='close'
                            onClick={(e) => { e?.preventDefault(); props.setShow?.(false) }}
                            disabled={isSubmitting}
                        />
                        <ButtonIconSquare
                            variant='secondary'
                            icon={isSubmitting ? <IconLoader className=' animate-spin ' /> : props.mode === 'add' ? <IconPlus /> : <IconEdit />}
                            text={props.mode === 'add' ? 'Add' : 'Edit'}
                            type="submit"
                            disabled={isSubmitting}
                        />
                    </ModalStandardFooter>
                </form>
            </ModalStandardContent>
        </ModalStandard>
    )
}


