import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { AxiosResponseCustom, useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"
import { AxiosProgressEvent } from "axios"

export type DistributorData = {
    FM11_DistributorID?:number,
    FM11_M04_StateName: string | null,
    FM11_M09_LocationName: string | null,
    FM11_M10_District: string | null,
    FM11_M08_Zone: string | null,
    FM11_DistributorCode: number | null,
    FM11_DistributorName: string | null,
    FM11_Status: number,
    FM11_StatusDate: string | null
}

export type DistributorDataExtended = {
    FM11_DistributorID?:number,
    FM11_M04_StateName: {
        id:number,
        name:string
    },
    FM11_M09_LocationName: {
        id:number,
        name:string
    },
    FM11_M10_District: {
        id:number,
        name:string
    },
    FM11_M08_Zone: {
        id:number,
        name:string
    },
    FM11_DistributorCode: number | null,
    FM11_DistributorName: string | null,
    FM11_Status: number,
    FM11_StatusDate: string | null
}

const QUERY_KEY = 'distributors'
export function useDistributorAPI({
    page_size,
    page,
    retryCount,
    trigger = true,
    search
}: {
    page_size?: number,
    page?: number,
    retryCount?: number,
    trigger?: boolean,
    search?:string
}) {

    const api = useRestAPI()
    const [totalDistributorCount, setTotalDistributorCount] = useState(0)
    const queryclient = useQueryClient()

    const {
        isLoading,
        isError,
        data: distributors,
        refetch: refetchDistributors,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount
    } = useQuery({
        queryKey: [QUERY_KEY, page_size, page,search],
        queryFn: async () => {
            const response = await api.get<{ data: DistributorDataExtended[] } & backendPagination>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/distributors/`,
                mode: 'protected',
                params: {
                    page_size,
                    page,
                    search
                }
            })
            if (response.success === false) {
                throw response.message
            }

            setTotalDistributorCount(response.count)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger,


    })

    const { mutateAsync: createDistributor,isPending:isCreatingDistributor,failureCount:createfailureCount } = useMutation({
        mutationFn: async (Distributor: DistributorData) => {
            const response = await api.post<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/distributors/`,
                data: Distributor,
                mode: 'protected'
            })

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&createfailureCount<4){
                
                throw response
            }

            return response
        }
    })

    const { mutateAsync: updateDistributor,isPending:isUpdatingDistributor  ,failureCount:updateFailuirCount } = useMutation({
        mutationFn: async ({
            id, 
            Distributor
        }:{
            id: string | number,
            Distributor: DistributorData
        }) => {
            const response = await api.patch<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/distributors/${id}/`,
                data: Distributor,
                mode: 'protected'
            })

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&updateFailuirCount<4){
                
                throw response
            }

            return response
        },retry:4
    })

    const { mutateAsync: deleteDistributorById ,isPending:isDeleting,failureCount} = useMutation({
        mutationFn: async ({distributorId}: {distributorId:string|number}) => {
            
            const response = await api.delete<{}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/distributors/${distributorId}/`,
                mode: 'protected'
            })

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&failureCount<4){
                
                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }

            return response 
        },
        retry:4,
        
    })
    const { mutateAsync: readDistributorbyId ,isPending:isReadingDistributor,failureCount:distributorReadFailureCount} = useMutation({
        mutationFn: async ({distributorId}: {distributorId:string|number}) => {
            
            const response = await api.get<{data:DistributorDataExtended}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/distributors/${distributorId}/`,
                mode: 'protected'
            })

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&distributorReadFailureCount<4){
                
                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }

            return response 
        },
        retry:4,
        
    })

       const { mutateAsync: uploadDistributer,isPending:isUploadingDistributer,failureCount:uploadDistributerFailure,isError:isUploadingDistributerError } = useMutation({
           mutationFn: async ({
               excelFile,
               onUploadProgress
           }:{
               excelFile:File,
               onUploadProgress?:((progressEvent: AxiosProgressEvent) => void)
           }) => {
               const formData = new FormData();
               formData.append('file', excelFile);
   
               const response = await api.post<{}, {}>({
                   url: `${process.env.REACT_APP_BACKEND_URL}/excel-upload/distributor-excel-upload/`,
                   data: formData,
                   mode: 'protected',
                   onUploadProgress: onUploadProgress
               });
   
               if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&uploadDistributerFailure<4){
                   
                   throw response
               }
   
               return response
           },
           retry:4
       })


    return {
        isLoading,
        isError,
        distributors,
        refetchDistributors,
        totalDistributorCount,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount,
        isDeleting,
        createDistributor,
        deleteDistributorById,
        readDistributorbyId,
        isReadingDistributor,
        updateDistributor,
        isUpdatingDistributor,
        uploadDistributer
    }


}


