export enum PaginationKeys {
    CURRENT_PAGE = 'currentPage',
    TOTAL_PAGES = 'totalPages',
    DATA_PER_PAGE = 'dataPerPage',
}

export type Pagination = {
    [PaginationKeys.CURRENT_PAGE]: number,
    [PaginationKeys.TOTAL_PAGES]: number,
    [PaginationKeys.DATA_PER_PAGE]: number,
}


export type backendPagination = {
    count: number,
    next: null | string,
    previous: null | string,
}
