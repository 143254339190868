import { useMutation, useQuery } from "@tanstack/react-query"
import { useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"

export type BranchData = {
    M02_BranchID: number,
    M02_BranchName: string,
    M02_Address: string,
    M02_Mobile: string,
    M02_EmailID: string,
    M02_IsActive: boolean,
    M02_CreatedBy: number,
    M02_CreatedDate: string,
    company: number
}

const QUERY_KEY = 'branches'
export function useBranchAPI({
    page_size,
    page,
    retryCount,
    trigger=true
}:{
    page_size?: number,
    page?: number,
    retryCount?:number,
    trigger?:boolean
}) {

    const api = useRestAPI()
    const [totalBranchCount, setTotalBranchCount] = useState(0)

    const {
        isLoading,
        isError,
        data: branches,
        refetch: refetchBranches,
        isFetching,
        isFetched,
        error
    } = useQuery({
        queryKey: [QUERY_KEY, page_size, page],
        queryFn: async () => {
            const response = await api.get<{ data: BranchData[] }&backendPagination>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/branches/`,
                mode: 'protected',
                params: {
                    page_size,
                    page
                }
            })
            if (response.success === false) {
                throw response.message
            }

            setTotalBranchCount(response.count)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger
        
    })



    return {
        isLoading,
        isError,
        branches,
        refetchBranches,
        totalBranchCount,
        isFetching,
        isFetched,
        error
    }


}


