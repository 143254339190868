import React from 'react';
import { Routes, Route, Navigate, replace } from 'react-router-dom';
import LoginPage from './pages/loginPage/Loginpage';
import { LayoutRoot } from './pages/LayoutRoot/layoutRoot';
import { Dashboard } from './pages/LayoutRoot/pages/Dashboard/dashboard';
import { useConfigurationRQ } from './queryState/configuration.state';
import { DistributorPage } from './pages/LayoutRoot/pages/Master/Distributor/page.distributor';
import { ProductPage } from './pages/LayoutRoot/pages/Master/Product/page.product';
import { SubAttributePage } from './pages/LayoutRoot/pages/Master/SubAttribute/page.subattribute';
import { SKUPage } from './pages/LayoutRoot/pages/Master/SKU/page.sku';
import { EmployeeDistributorMappingPage } from './pages/LayoutRoot/pages/Master/EmployeeDistributerMapping/page.employee_distributer_mapping';
import ForcastCalender from './pages/LayoutRoot/pages/Forcast/forcast-calender/page.calender';
import "react-day-picker/style.css";
import ForcastRegionWisePage from './pages/LayoutRoot/pages/Forcast/forcast-regionwise/page.RegionWise';
import ForcastStyleWise from './pages/LayoutRoot/pages/Forcast/NPstylewise/page.stylewise';
import { SeAllotmentPage } from './pages/LayoutRoot/pages/Forcast/SEallotment/page.SE_allotment';
import ForcastDBWise from './pages/LayoutRoot/pages/Forcast/NP-DBwise/page.DBwise';
import ForcastExecutiveWise from './pages/LayoutRoot/pages/Forcast/ExecutiveWise/page.executive_wise';
import { EBOForecastPage } from './pages/LayoutRoot/pages/Forcast/EBO_forecast/page.ebo_forecast';
import { InstitutionalForecastPage } from './pages/LayoutRoot/pages/Forcast/Institutional_forecast/page.institutional_forecast';
import { ExportForecastPage } from './pages/LayoutRoot/pages/Forcast/export_forecast/page.export_forecast';
import { OnlineForecastPage } from './pages/LayoutRoot/pages/Forcast/online_forecast/page.online_forecast';
import { EmloyeesPage } from './pages/LayoutRoot/pages/Authentication/employees/page.employees';
import RolePage from './pages/LayoutRoot/pages/Authentication/RolePage/page.RolePage';
import { isAllowedPermission } from './lib/utils';
import { useGetAllowedMenus } from './API/rest.role';

function App() {

  // const configuration = useConfigurationRQ()
  const {
    rolesAllowedMenus,
    isFetchedGetAllowdMenus,
    isFetchingGetAllowdMenus,
    refetchRolesAllowedMenus
  } = useGetAllowedMenus({
    menuIds: [
      "masterID",
      "distributerID",
      "productID",
      "subAttributeID",
      "skuID",
      "distributorID",
      "ForecastId",
      "CalenderID",
      "RegionWiseID",
      "NPStyleWiseID",
      "NPDBwiseID",
      "SEAllotmentID",
      "ExecutiveWiseID",
      "EBOForecastID",
      "InstitutionalID",
      "ExportForecastID",
      "OnlineForecastcastID",
      "AuthenticationID",
      "EmployeesID",
      "RoleID",
    ]
  })

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/home/*" element={<LayoutRoot />} >
        <Route index element={<Navigate to={'dashboard'} replace />} />
        <Route path='dashboard' element={<Dashboard />} />
        {isAllowedPermission(rolesAllowedMenus?.data, "productID") ? <Route path='master/product' element={<ProductPage />} />:null}
        {isAllowedPermission(rolesAllowedMenus?.data, "distributerID") ? <Route path='master/distributor' element={<DistributorPage />} />:null}
        {isAllowedPermission(rolesAllowedMenus?.data, "subAttributeID") ? <Route path='master/subattribute' element={<SubAttributePage />} />:null}
        {isAllowedPermission(rolesAllowedMenus?.data, "skuID") ? <Route path='master/sku' element={<SKUPage />} />:null}
        {isAllowedPermission(rolesAllowedMenus?.data, "distributorID") ? <Route path='master/distributor-mapping' element={<EmployeeDistributorMappingPage />} />:null}
        {isAllowedPermission(rolesAllowedMenus?.data, "ForecastId") ? <Route path='transaction/forecast-calender' element={<ForcastCalender />} />:null}
        {isAllowedPermission(rolesAllowedMenus?.data, "RegionWiseID") ? <Route path='transaction/forecast-regirionwise' element={<ForcastRegionWisePage />} />:null}
        {isAllowedPermission(rolesAllowedMenus?.data, "NPStyleWiseID") ? <Route path='transaction/new-product-stylewise' element={<ForcastStyleWise />} />:null}
        {isAllowedPermission(rolesAllowedMenus?.data, "NPDBwiseID") ? <Route path='transaction/new-product-dbwise' element={<ForcastDBWise />} />:null}
        {isAllowedPermission(rolesAllowedMenus?.data, "SEAllotmentID") ? <Route path='transaction/se-allotment' element={<SeAllotmentPage />} />:null}
        {isAllowedPermission(rolesAllowedMenus?.data, "ExecutiveWiseID") ? <Route path='transaction/executive-wise' element={<ForcastExecutiveWise />} />:null}
        {isAllowedPermission(rolesAllowedMenus?.data, "EBOForecastID") ? <Route path='transaction/ebo-forecast-page' element={<EBOForecastPage />} />:null}
        {isAllowedPermission(rolesAllowedMenus?.data, "InstitutionalID") ? <Route path='transaction/institutional-forecast-page' element={<InstitutionalForecastPage />} />:null}
        {isAllowedPermission(rolesAllowedMenus?.data, "ExportForecastID") ? <Route path='transaction/export-forecast-page' element={<ExportForecastPage />} />:null}
        {isAllowedPermission(rolesAllowedMenus?.data, "OnlineForecastcastID") ? <Route path='transaction/online-forecast-page' element={<OnlineForecastPage />} />:null}
        {isAllowedPermission(rolesAllowedMenus?.data, "EmployeesID") ? <Route path='authentication/Employees' element={<EmloyeesPage />} />:null}
        {isAllowedPermission(rolesAllowedMenus?.data, "RoleID") ? <Route path='authentication/role' element={<RolePage />} />:null}
      </Route>

    </Routes>
  );
}

export default App;
