import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { useRestAPI } from "./axiosAPI"



export function useOnlineForcast({
    retryCount,
}: {
    retryCount?: number,
}) {

    const api = useRestAPI()
    const [totalSubAttributeCount, setTotalSubAttributeCount] = useState(0)
    const [validateProgress, setValidateProgress] = useState(0)
    const [uploadProgress, setuploadProgress] = useState(0)
    const queryclient = useQueryClient()
    // const [deleteRetryCount,setDeleteRetryCount] = useState(0)


    const { mutateAsync: downloadTemplateOnlineforecast, isPending: isDownloadingTEmplateOnlineforecast, failureCount: downloadTemplateFailureCount, isError: isDownloadOnlineforcastTemplateError } = useMutation({
        mutationFn: async () => {

            const response = await api.get({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/online-forecast/v2/template/`,
                mode: 'protected',
                responseType: 'blob'
            })


            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && downloadTemplateFailureCount < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }
            if (response.success !== false) {
                const blob = new Blob([response as unknown as BlobPart], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'online_forecast_template.xlsx'; // Set the filename

                // Append to the document and trigger the download
                document.body.appendChild(link);
                link.click();

                // Clean up
                document.body.removeChild(link);
                window.URL.revokeObjectURL(link.href);
            }

            return response
        },
        retry: 4,

    })
    const { mutateAsync: validateOnlineforecastExel, isPending: isvalidateingOnlineforecastExel, failureCount: validateOnlineforecastExelFailureCount, isError: isvalidateOnlineforecastExelError } = useMutation({
        mutationFn: async ({ file }: { file: File }) => {

            const formData = new FormData();
            formData.append('file', file);

            const response = await api.post({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/online-forecast/v2/template/validate/`,
                mode: 'protected',
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total!
                    );
                    setValidateProgress(percentCompleted);
                },
                data: formData
            });


            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && validateOnlineforecastExelFailureCount < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }


            return response
        },
        retry: 4,

    })

    const { mutateAsync: uploadOnlineforecastExel, isPending: isuploadingOnlineforecastExel, failureCount: uploadOnlineforecastExelFailureCount, isError: isuploadOnlineforecastExelError } = useMutation({
        mutationFn: async ({ file, date }: { file: File, date: string }) => {

            const formData = new FormData();
            formData.append('file', file);
            formData.append('date', date);
            const response = await api.post({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/online-forecast/v2/template/upload/`,
                mode: 'protected',
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total!
                    );
                    setuploadProgress(percentCompleted);
                },
                data: formData
            })


            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && uploadOnlineforecastExelFailureCount < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }


            return response
        },
        retry: 4,

    })



    return {

        downloadTemplateOnlineforecast,
        isDownloadingTEmplateOnlineforecast,
        isDownloadOnlineforcastTemplateError,
        validateOnlineforecastExel,
        isvalidateingOnlineforecastExel,
        isvalidateOnlineforecastExelError,
        validateProgress,
        uploadOnlineforecastExel,
        isuploadingOnlineforecastExel,
        isuploadOnlineforecastExelError,
        uploadProgress
    }


}




