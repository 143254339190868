import { useMutation, useQuery } from "@tanstack/react-query"
import { useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"



export function useStatesAPI({
    page_size,
    page,
    retryCount,
    trigger = true,
    stateName,
    isState
}: {
    page_size?: number,
    page?: number,
    retryCount?: number,
    trigger?: boolean,
    stateName?:string,
    isState?:0|1
}) {

    const api = useRestAPI()
    const [totalStateCount, setTotalStateCount] = useState(0)

    const {
        isLoading,
        isError,
        data: states,
        refetch: refetchStates,
        isFetching,
        isFetched,
        error
    } = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: async () => {
            const response = await api.get<{ data: StateData[] }&backendPagination>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/states/`,
                mode: 'protected',
                params: {
                    page_size,
                    page,
                    FM04_StateName:stateName,
                    FM04_IsState:isState
                }
            })
            if (response.success === false) {
                throw response.message
            }

            setTotalStateCount(response.data?.length||0)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger
        
    })

    return {
        isLoading,
        isError,
        states,
        refetchStates,
        totalStateCount,
        isFetching,
        isFetched,
        error
    }

}
export function useLoadStatesFromStateMappingAPI({
    FM06_M03_EmployeeID,
    retryCount,
    trigger = true
}: {
    FM06_M03_EmployeeID:number,
    retryCount?: number,
    trigger?: boolean
}) {

    const api = useRestAPI()
    const [totalStateCount, setTotalStateCount] = useState(0)

    const {
        isLoading,
        isError,
        data: states,
        refetch: refetchStates,
        isFetching,
        isFetched,
        error
    } = useQuery({
        queryKey: ['states-under-employee',FM06_M03_EmployeeID],
        queryFn: async () => {
            const response = await api.get<{ data: StateData[]}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/statemapping-states/`,
                mode: 'protected',
                params: {
                    FM06_M03_EmployeeID:FM06_M03_EmployeeID
                }
            })
            if (response.success === false) {
                throw response.message
            }

            setTotalStateCount(response.data?.length||0)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger
        
    })

    return {
        isLoading,
        isError,
        states,
        refetchStates,
        totalStateCount,
        isFetching,
        isFetched,
        error
    }

}

type StateData = {
    FM04_StateID: number,
    FM04_StateName: string,
    FM04_ReportOrder: number|null,
    FM04_ReportText: string|null,
    FM04_IsState: boolean,
    FM04_IsActive: boolean
}

const QUERY_KEY = 'states'
