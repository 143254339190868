import React from 'react'
import SmallReport from './component.smallReport'

export function Dashboard() {
  console.log('first')
  return (
    <div className=' grow px-3 '>
      <SmallReport />
      <div className=' flex w-full pt-4 flex-col lg:flex-row gap-5 '>
        <div className=' h-[300px] min-h-[300px] bg-vstargray-100 dark:bg-vstargray-100/40 rounded-lg basis-[100%] lg:basis-[60%] p-4'>
          <p className=' text-vstargray-300 font-bold text-xs'>Built by developers</p>
          <p className=' font-bold text-lg mt-1 dark:text-vstargray-100'>UI dashboard</p>
          <p className=' text-vstargray-500 dark:text-vstargray-400'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nulla iure doloremque optio laborum obcaecati </p>
        </div>
        <div className=' h-[300px] min-h-[300px] bg-vstargray-100 dark:bg-vstargray-100/40 rounded-lg basis-[100%] lg:basis-[40%] p-4 '>
          <div className=' h-full w-full relative rounded-lg overflow-hidden'>

            <img className=' w-full h-full object-cover rounded-lg' src="/assets/temp/Rectangle 12.png" alt="" />
            <div className=' absolute inset-0 z-10 bg-gradient-to-b from-[#151928]/60 to-transparent p-4'>
              <p className=' font-bold text-lg text-vstargray-100'>Work With The Rockets</p>
              <p className=' text-white font-light text-xs '>Lorem ipsum dolor sit amet consectetur adipisicing elit. sdfjlsdfj dslkjf Quasi, vitae necessitatibus.</p>
            </div>
          </div>
        </div>
      </div>
      {/* Graphs */}
      <div className=' flex w-full pt-4 flex-col lg:flex-row gap-5 '>
        <div className=' h-[300px] min-h-[300px] bg-vstargray-100 rounded-lg basis-[100%] lg:basis-[50%] p-4'>
          <img className=' h-full w-full object-fill' src="/assets/temp/graph.png" alt="" />
        </div>
        <div className=' h-[300px] min-h-[300px] bg-vstargray-100 rounded-lg basis-[100%] lg:basis-[50%] p-4 '>
          <div className=' h-full w-full relative rounded-lg overflow-hidden'>

            <img className=' h-full w-full object-fill' src="/assets/temp/graph2.png" alt="" />

          </div>
        </div>
      </div>
    </div>
  )
}
