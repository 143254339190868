import { useNavigate, useLocation } from 'react-router-dom';
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from 'src/shadcn/ui/pagination'
import { PaginationKeys } from 'src/types/pagination';

export interface StandardTablePaginationProps {
  totalPages: number
  currentPage: number
  onChangePage: (page: number) => void
}

export function StandardTablePagination({
  totalPages,
  currentPage,
  onChangePage,
}: StandardTablePaginationProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const startPage = Math.max(1, currentPage - 1)
  const endPage = Math.min(totalPages, currentPage + 1)

  const handlePageChange = (page: number) => {
    onChangePage(page);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(PaginationKeys.CURRENT_PAGE, page.toString());
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  if (totalPages <= 1) {
    return null
  }

  return (
    <Pagination>
      <PaginationContent>
        {currentPage > 1 && (
          <PaginationItem className='cursor-pointer select-none'>
            <PaginationPrevious
              className=' text-vstargray-600 dark:text-vstargray-300'
              onClick={() => handlePageChange(currentPage - 1)}
            />
          </PaginationItem>
        )}
        {startPage > 1 && <PaginationItem><PaginationEllipsis className=' dark:text-white' /></PaginationItem>}
        {[...Array(endPage - startPage + 1)].map((_, i) => (
          <PaginationItem className={`cursor-pointer select-none  `} key={i + startPage}>
            <PaginationLink
              className={`${i + startPage === currentPage ? 'dark:bg-vstarPrimary-500 bg-vstargray-100 ' : ' text-vstargray-600 dark:text-vstargray-300 hover:border'}`}
              isActive={i + startPage === currentPage}
              onClick={() => handlePageChange(i + startPage)}
            >
              {i + startPage}
            </PaginationLink>
          </PaginationItem>
        ))}
        {endPage < totalPages && <PaginationItem><PaginationEllipsis className=' dark:text-white' /></PaginationItem>}
        {currentPage < totalPages && (
          <PaginationItem className='cursor-pointer select-none'>
            <PaginationNext
              className=' text-vstargray-600 dark:text-vstargray-300'
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </PaginationItem>
        )}
      </PaginationContent>
    </Pagination>
  )
}