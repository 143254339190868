import { IconChevronDown, IconChevronRight, IconEdit, IconLoader, IconPlus, IconSquareRounded, IconSquareRoundedCheckFilled, IconSquareRoundedMinus, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import CheckboxTree from 'react-checkbox-tree'
import "react-checkbox-tree/lib/react-checkbox-tree.css"
import { useForm } from 'react-hook-form'
import { useEmployeeAPI } from 'src/API/rest.employee'
import { RoleMenuType, useMenuGetAll, useRoleApi } from 'src/API/rest.role'
import { ButtonIconSquare } from 'src/components/buttons'
import { InputStandard } from 'src/components/standard.input'
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from 'src/components/standard.modal'
import { MultiselectStandard } from 'src/components/standard.multiselect'
import { toast } from 'src/shadcn/ui/use-toast'





type Props = {

    show?: boolean,
    setShow?: (show: boolean) => void,
    refetchEmployees: () => void,
    mode: 'add' | 'edit',
    employeeId?: number | string | null
}


type RoleMenuBoxType = {
    value: string,
    label: string,
    children?: RoleMenuBoxType[]
}

type formData = {
    role_name: string,
    users: number[],
}

function createNestedArrayWithFormattedFields(data: RoleMenuType[]) {
    let map = new Map();

    // First pass: create an entry for each item with formatted fields.
    data.forEach(item => {
        map.set(item.id, {
            value: item.id,
            label: item.menu_display_name,
            children: []
        });
    });

    // Second pass: properly nest the children.
    let roots: RoleMenuBoxType[] = [];
    data.forEach(item => {
        const current = map.get(item.id);
        if (item.parent_id === null) {
            roots.push(current);
        } else {
            let parent = map.get(item.parent_id);
            if (parent) {
                parent.children.push(current);
            }
        }
    });

    return roots;
}


const findAllParentIDs = (data: RoleMenuType[], id: string) => {
    const result: string[] = [];
    let currentID = id;

    const findParent = (itemID: string) => {
        // Find the item with the given ID
        console.log(itemID)
        const item = data.find(d => d.id as any == itemID);
        if (item && item.parent_id !== null) {
            // If the item has a parent, add the parent ID to the result array
            result.push(String(item.parent_id));
            // Recursively find the parent of the parent
            findParent(String(item.parent_id));
        }
    }

    findParent(currentID);
    return result;
}

function findLeafNodeIds(rolesList: RoleMenuBoxType[]) {
    const leafIds: string[] = [];

    function traverseTree(node: RoleMenuBoxType) {
        if (!node.children || node.children.length === 0) {
            leafIds.push(node.value);
        } else {
            node.children.forEach(child => traverseTree(child));
        }
    }

    rolesList.forEach(role => traverseTree(role));

    return leafIds.map(id => String(id));
}


export function ModalAddRole(props: Props) {



    const [checked, setChecked] = useState<string[]>([]);
    const [expanded, setExpanded] = useState<string[]>([]);
    
    const [rolesListState, setRolesListState] = useState<RoleMenuBoxType[]>([])
    const [leafNodes, setLeafNodes] = useState<string[]>([]);
    
    const { register, handleSubmit, setValue, reset, formState: { errors }, control, watch } = useForm<formData>({
        defaultValues: {
            "role_name": "",
            "users": []
        }
    });
    
    
    const {
        rolesList,
        isLoading: isLoadingRoleList,
        isFetchingRoleList,
        isErrorRoleList: isErrorRoleList,
        refetchRolesList,
        errorRoleList
    } = useMenuGetAll({
        trigger: props.show
    })

    const {
        createRole,
        isCreatingRole,
        isErrorCreatingRole,
        deleteRoleMaster,
        isErrorreadRoleMasterbyId,
        isReadingreadRoleMasterbyId,
        readRoleMasterbyId,
        updateRoleMaster,
        isUpdatingRoleMaster
    } = useRoleApi()
    
    const {
        emloyees,
        isLoading: isLoadingEmployeeList,
        isFetching: isFetchingEmployeeList,
        isError: isErrorEmployeeList,
    } = useEmployeeAPI({
        page_size: 1000,
        page: 1,
        trigger: props.show
    })
    
    
    
    useEffect(() => {
        
        if (props.show) {
            
            const rolesListCreated = createNestedArrayWithFormattedFields(rolesList?.data || [])
            const leafnodes = findLeafNodeIds(rolesListCreated)
            setLeafNodes(leafnodes)
            setRolesListState(rolesListCreated)
            
        }
        
    }, [isFetchingRoleList])
    
    useEffect(
        
        () => {
            
            if (props.show && props.mode == 'add') {
                reset({
                    role_name: "",
                    users: []
                })
                setChecked([])
            }
            if (props.show && props.mode == 'edit') {

                if(leafNodes.length===0) return
                
                readRoleMasterbyId({ roleMasterId: props.employeeId! }).then(
                    response => {
                        
                        if (response.success === true) {
                            reset({
                                role_name: response.data.role_name,
                                users: response.data.users.map((user) => {
                                    return user.FM03_EmployeeID
                                })
                            })
                            const idsWithParents = response.data.menus.map((menu) => {
                                return String(menu.id)
                            })
                            
                            
                            
                            const onlyLeafNodes = idsWithParents.filter(id => leafNodes.includes(id));
                            
                            // console.log(idswithoutParents)
                            setChecked(onlyLeafNodes)
                        }
                    }
                )
                
            }

            if(props.show===false){
                setLeafNodes([])
            }
            
        }, [props.show, isFetchingRoleList,leafNodes.length]
    )
    
    const onSubmit = async (data: formData) => {
        

        let completeParents: string[] = []
        checked.forEach(
            roles => {
                // console.log(rolesList)
                const parents = findAllParentIDs(rolesList?.data || [], roles);
                if (parents.length > 0) {
                    completeParents = [...new Set([...completeParents, ...parents])];
                }
            }
        )
        console.log(checked)
        
        const completeRoles = [...new Set([...completeParents, ...checked])]
        
        // setChecked(completeRoles)
        
        console.log(completeRoles)
        
        if (props.mode === 'add') {
            
            
            const response = await createRole({
                menus: completeRoles.map(Number),
                role_name: data.role_name,
                users: data.users
            })
            if (response.success === true) {
                toast({
                    description: response.message,
                    // variant:''
                })
                props.setShow?.(false);
                props.refetchEmployees()
            } else {
                toast({
                    description: response.message,
                    title: "error",
                    // variant:''
                })
            }
        } else if (props.mode === 'edit' && props.employeeId) {
            const response = await updateRoleMaster({
                id: props.employeeId,
                RoleMaster: {
                    menus: completeRoles.map(Number),
                    role_name: data.role_name,
                    users: data.users
                }
            })
            if (response.success === true) {
                toast({
                    description: response.message,
                    // variant:''
                })
                props.setShow?.(false);
                props.refetchEmployees()
            } else {
                toast({
                    description: response.message,
                    title: "error",
                    // variant:''
                })
            }
        }
    };
    
    
    const isAnyLoading = isLoadingRoleList || isCreatingRole || isLoadingEmployeeList
    const isAnyFetching = isFetchingRoleList || isFetchingEmployeeList
    const isAnyError = isErrorRoleList || isErrorEmployeeList
    
    
    return (
        <ModalStandard
            open={props.show}
            onOpenChange={props.setShow}
        >
            <ModalStandardContent onOpenAutoFocus={(e) => e?.preventDefault()} className='bg-vstargray-100'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalStandardHeader >
                        <ModalStandardTitle >{props.mode === 'edit' ? 'Edit Role' : 'Add Role'}</ModalStandardTitle>
                        <ModalStandardDescription>
                            {/* loading indication */}
                            {(isAnyLoading||isAnyFetching) && <div className=' px-2 p-1 rounded-md bg-vstargray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>}

                            <InputStandard
                                title='Role Name'
                                placeholder='Role Name'
                                disabled={isAnyError || isAnyLoading || isAnyFetching}
                                {...register("role_name", { required: 'Role name is required' })}
                                error={errors.role_name?.message}
                            />
                            <MultiselectStandard
                                title='Employee'
                                {...register("users", {})}
                                error={errors.users?.message}
                                value={watch('users')?.map(item => String(item)) || []}
                                options={emloyees?.map((employee) => ({ value: String(employee.FM03_EmployeeID), label: employee.FM03_EmployeeName })) || []}
                                placeholder='Employees'
                                onValueChange={(e) => {
                                    setValue('users', e.target.value?.map(item => Number(item)));
                                }}
                                disabled={isAnyLoading || isAnyFetching}
                            />
                            <div className='h-5'></div>
                            <CheckboxTree
                                nodes={rolesListState}
                                checked={checked}
                                expanded={expanded}
                                onCheck={setChecked}
                                onExpand={setExpanded}
                                icons={{
                                    check: <IconSquareRoundedCheckFilled />,
                                    uncheck: <IconSquareRounded />,
                                    halfCheck: <IconSquareRoundedMinus />,
                                    expandClose: <IconChevronRight className="expand-icon" />,
                                    expandOpen: <IconChevronDown className="expand-icon" />,
                                    parentClose: null,
                                    parentOpen: null,
                                    leaf: null,
                                }}
                            />

                            <div className=' h-4'></div>

                        </ModalStandardDescription>
                    </ModalStandardHeader>
                    <ModalStandardFooter>
                        <ButtonIconSquare
                            variant='white'
                            icon={<IconX />}
                            text='close'
                            onClick={(e) => { e?.preventDefault(); props.setShow?.(false) }}
                        // disabled={isSubmitting}
                        />
                        <ButtonIconSquare
                            variant='secondary'
                            icon={isCreatingRole||isUpdatingRoleMaster ? <IconLoader className=' animate-spin ' /> : props.mode === 'add' ? <IconPlus /> : <IconEdit />}
                            text={props.mode === 'add' ? 'Add' : 'Edit'}
                            type="submit"
                        disabled={isAnyFetching||isAnyLoading||isAnyError}
                        />
                    </ModalStandardFooter>
                </form>
            </ModalStandardContent>
        </ModalStandard>
    )
}
