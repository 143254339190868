import { IconUpload, IconX } from '@tabler/icons-react'
import { useDropzone } from 'react-dropzone'
import { ButtonIconSquare } from 'src/components/buttons'
import { toast } from 'src/shadcn/ui/use-toast'
import { TypographyP } from './typography'

type Props = {
    show?: boolean,
    setShow?: (show: boolean) => void,
}

export function FileInputDragDropStandard(props: {
    accept?: Record<string, string[]>
    onDrop?: (file: File) => void
    isLoading?: boolean
    disabled?: boolean
    file?: File | null
    onClear?: () => void
}) {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                props.onDrop?.(acceptedFiles[0]);
            } else {
                toast({
                    description: 'Please select a Valid File',
                })
            }
        },
        accept: props.accept,
        disabled: props.isLoading || props.disabled
    });

    return (
        <div {...getRootProps()} className=" h-[250px] w-[250px] flex flex-col gap-1 items-center justify-center border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer hover:border-gray-400 transition-colors">
            <input {...getInputProps()} />
            {isDragActive ? (
                <TypographyP>Drop the Excel or CSV file here...</TypographyP>
            ) : (
                <div>
                    <IconUpload className="mx-auto h-12 w-12 text-gray-400" />
                    <TypographyP> Drag and drop an Excel or CSV file here, or click to select a file</TypographyP>
                </div>
            )}
            {props.file && (
                <p className="mt-2 text-sm text-gray-600">Selected file: {props.file.name}</p>
            )}
            {props.file?<div className='flex items-center gap-2'>
                <ButtonIconSquare
                    variant='white'
                    icon={<IconX />}
                    text='Clear'
                    onClick={(e) => {
                        e?.stopPropagation();
                        props.onClear?.()
                    }}
                    disabled={!props.file}
                    
                />
            </div>:null}
        </div>
    )
}
