
import React from "react"
import { Popover, PopoverContent, PopoverTrigger } from "src/shadcn/ui/popover"
import { StandardTableOptionButton } from "../../../../../components/standard.table"
import { IconPencil, IconTools, IconTrash } from "@tabler/icons-react"

export interface DropdownMenuDemoProps {
    rowId: string | number,
    onDeleteAction: (rowId: string | number) => void;
    onEditAction: (rowId: string | number) => void;
}

function DropdownMenuItem({
    icon,
    text,
    onClick
}:{
    icon: React.ReactNode,  //recomended tabler icon size 20
    text: string,
    onClick?: () => any
}) {
    return (
        <button onClick={onClick} className=" text-vstargray-800 hover:text-vstargray-100 flex rounded-md bg-vstargray-100 hover:bg-vstarPrimary-500  items-center gap-2 px-3 py-1">
            {/* icon */}
            {icon}
            {/* text */}
            <p className=" text-xs">{text}</p>
        </button>
    )
}

export function DropdownMenuDemo({ rowId, onDeleteAction ,onEditAction}: DropdownMenuDemoProps) {

    const [openDrowdownMenu, setOpenDropdownMenu] = React.useState(false)



    return (
        <Popover  open={openDrowdownMenu} onOpenChange={setOpenDropdownMenu}  >
            <PopoverTrigger asChild>
                <div><StandardTableOptionButton onClick={() => setOpenDropdownMenu(!openDrowdownMenu)} /></div>
            </PopoverTrigger >
            <PopoverContent onOpenAutoFocus={(e)=>e?.preventDefault()} className="w-[120px] bg-vstargray-100 p-1">
                <div className="w-full flex flex-col gap-1">
                    <DropdownMenuItem onClick={() => {
                        setOpenDropdownMenu(!openDrowdownMenu);
                        onEditAction(rowId);
                    }} icon={<IconTools size={20} />} text="Edit" />
                    <DropdownMenuItem   icon={<IconTrash size={20} />} text="Delete" onClick={() => onDeleteAction(rowId)} />
                </div>
            </PopoverContent>
        </Popover>
    )
}


