import { IconCloudUp, IconLoader2, IconPlus, IconTrashFilled, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useEmployeeDistributorMappingAPI } from 'src/API/rest.employee_distributer_mapping'
import { ButtonIconSquare } from 'src/components/buttons'
import { StandardAlertDialog } from 'src/components/standard.alert-dialog'
import { StandardTable, StandardTableHeader, StandardTableRow, TablePlater } from 'src/components/standard.table'
import { toast } from 'src/shadcn/ui/use-toast'
import { Pagination, PaginationKeys } from 'src/types/pagination'
import { StandardTablePagination } from '../../../../../components/standard.pagination'
import { ModalAddEmployeeDistributorMapping } from './component.addModal'
import { DropdownMenuDemo } from './component.dropdownMenu'
import { ImportExcelModal } from './component.importExcelModal'


export function EmployeeDistributorMappingPage() {

    const searchParams = new URLSearchParams(window.location.search)
    const currentPageFromUrl = searchParams.get(PaginationKeys.CURRENT_PAGE)
    const dataPerPage = 5
    const [pagination, setPagination] = useState<Pagination>({
        currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
        totalPages: Math.ceil(1 / dataPerPage) || 1,
        dataPerPage: dataPerPage
    })
    const [employeeDistributorMappingRow, setEmployeeDistributorMappingRow] = useState<StandardTableRow[]>([])
    const [previousDataLength, setPreviousDataLength] = useState(0)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [selectedEmployeeDistributorMappingId, setSelectedEmployeeDistributorMappingId] = useState<number | null | string>()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [mode, setMode] = useState<'add' | 'edit'>('add')
    const [openImportModal, setOpenImportModal] = useState(false)

    const {
        employeeDistributorMapping,
        error,
        isError,
        isFetched,
        isFetching,
        isLoading,
        totalEmployeeDistributorMappingCount,
        isDeleting,
        refetchEmployeeDistributorMapping,
        deleteEmployeeDistributorMappingById,

    } = useEmployeeDistributorMappingAPI({
        page_size: pagination.dataPerPage,
        page: pagination.currentPage
    })



    const headers: StandardTableHeader[] = [
        { label: 'Distributor ID', id: 'DistributorID' },
        { label: 'State', id: 'state_name' },
        { label: 'Region', id: 'region_name' },
        { label: 'Product Sub Category', id: 'ProductSubCategory' },
        { label: 'Product Category', id: 'ProductCategory' },
        { label: 'Product ID', id: 'ProductID' },
        { label: 'Executive Name', id: 'executive_id' },
        { label: 'Status', id: 'status' },
        { label: '', id: 'actions', width: '10px' },
    ]



    const deleteEmployeeDistributorMapping = async () => {

        if (selectedEmployeeDistributorMappingId) {
            const response = await deleteEmployeeDistributorMappingById({
                id: selectedEmployeeDistributorMappingId
            })
            if (response.success === true) {
                toast({
                    title: 'Success',
                    description: response.message,
                })
                refetchEmployeeDistributorMapping()
                setOpenDeleteModal(false)
            } else {
                toast({
                    title: 'Error',
                    description: response.message,
                })
            }
            setSelectedEmployeeDistributorMappingId(null)
        }

    }


    useEffect(
        () => {
            // setting prevouse data length for loading optimization
            if (isFetched && !isFetching) {
                console.log(employeeDistributorMapping?.length)
                setPreviousDataLength(employeeDistributorMapping?.length || 0)
            }

            //if successfully loaded
            if (isFetched && !isError) {
                setPagination({
                    currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
                    totalPages: Math.ceil(totalEmployeeDistributorMappingCount / dataPerPage) || 1,
                    dataPerPage: dataPerPage
                })
                console.log(employeeDistributorMapping)
                setEmployeeDistributorMappingRow(employeeDistributorMapping?.map(
                    (mapping) => {
                        return ({
                            id: String(mapping.FM16_EmployeeDistributorMappingID),
                            datas: {
                                DistributorID: { id: 'DistributorID', data: mapping.FM16_M11_DistributorID?.name },
                                state_name: { id: 'state_name', data: mapping.FM16_M04_StateName?.name },
                                region_name: { id: 'region_name', data: mapping.FM16_M07_RegionName?.name },
                                ProductSubCategory: { id: 'ProductSubCategory', data: mapping.FM14_M12_ProductSubCategory },
                                ProductCategory: { id: 'ProductCategory', data: mapping.FM14_M12_ProductCategory },
                                ProductID: { id: 'ProductID', data: mapping.FM16_M13_ProductID },
                                executive_id: { id: 'executive_id', data: mapping.FM16_M03_ExecutiveID },
                                
                                status: { id: 'status', data: mapping?.FM16_Status ? '1' : '0' },
                                actions: {
                                    id: 'actions', data: <DropdownMenuDemo
                                        rowId={String(mapping.FM16_EmployeeDistributorMappingID)}
                                        onDeleteAction={(rowid) => {
                                            setSelectedEmployeeDistributorMappingId(rowid);
                                            setOpenDeleteModal(true);
                                        }}
                                        onEditAction={(rowid) => {
                                            setSelectedEmployeeDistributorMappingId(rowid);
                                            setMode('edit');
                                            setOpenAddModal(true);
                                        }}
                                    />
                                }
                            }
                        })

                    }
                ) || [])
            }
            if (isError && isFetching === false) {
                toast({
                    description: String(error),
                })

            }
        }, [isFetching]
    )





    return (
        <div className=' grow  flex flex-col overflow-auto'>
            <StandardAlertDialog
                title="Are you absolutely sure?"
                description="This action cannot be undone. Are you sure you want to delete this mapping?"
                positiveLabel="Delete"
                negativeLabel="Cancel"
                positiveAction={deleteEmployeeDistributorMapping}
                negativeAction={() => setOpenDeleteModal(false)}
                positiveIcon={isDeleting ? <IconLoader2 size={16} className=" animate-spin" /> : <IconTrashFilled size={16} />}
                negativeIcon={<IconX size={16} />}
                disableNegativeButton={isDeleting}
                disablePositiveButton={isDeleting}
                show={openDeleteModal}
            />
            <ModalAddEmployeeDistributorMapping
                show={openAddModal}
                setShow={setOpenAddModal}
                refetchEmployeeDistributorMapping={refetchEmployeeDistributorMapping}
                mode={mode}
                employeeDistributorMappingId={selectedEmployeeDistributorMappingId}
            />
            <ImportExcelModal
                show={openImportModal}
                setShow={setOpenImportModal}
            />
            
            <TablePlater className=' flex flex-col gap-2'>
                {/* Buttons section */}
                <div className=' flex justify-end w-full gap-4'>
                    <ButtonIconSquare variant="white" icon={<IconCloudUp />} text="Import" onClick={() => { setOpenImportModal(true) }} />

                    <ButtonIconSquare variant="secondary" icon={<IconPlus />} text="Add mapping" onClick={() => { setMode('add'); setOpenAddModal(true); }} />
                </div>
                <StandardTable
                    headers={headers}
                    rows={employeeDistributorMappingRow}
                    isLoading={isLoading}
                    rowsPerPrevousePage={previousDataLength}
                    isUpdating={isFetching}
                    isError={isError && isFetching === false}
                    onRetry={() => refetchEmployeeDistributorMapping()}
                />
                <StandardTablePagination currentPage={pagination.currentPage} totalPages={pagination.totalPages} onChangePage={(page) => {
                    setPagination({ ...pagination, currentPage: page })
                }} />

            </TablePlater>
        </div>
    )

}


