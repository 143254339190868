import { IconCalendarFilled, IconEdit, IconPlus } from '@tabler/icons-react'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { ButtonIconSquare } from 'src/components/buttons'
import { Calendar } from 'src/shadcn/ui/calender'
import { Input } from 'src/shadcn/ui/input'
import { ModalAddForecastModal } from './component.modalForecast'
import { StandardTable, StandardTableHeader, StandardTableRow } from 'src/components/standard.table'
import { useForecastCalenderAPI, useGetLatestCalendarDate } from 'src/API/rest.forecast_calender'
import { useRegionAPI } from 'src/API/rest.region'
import { MonthCode, useForecastRegionwiseAPI } from 'src/API/rest.forecast_regionwise'
import { SelectStandard } from 'src/components/standard.select'
import { InputStandard } from 'src/components/standard.input'
import { useForecastAPI } from 'src/API/rest.forecast'

export default function ForcastRegionWisePage() {
    const [isOpen, setIsOpen] = useState(false)
    const currentDate = new Date();
    const [selectedDate, setSelectedDate] = useState(
        ''
    );

    const [dates, setDates] = useState<{
        monthCode: MonthCode;
        month: string;
    }[]>([])




    const [monthCode, setMonthCode] = useState<{
        monthCode: MonthCode;
        month: string;
    }>({
        monthCode: dates?.[0]?.monthCode || '',
        month: dates?.[0]?.month || ''
    });


    const [RegionForcastRows, setRegionForcastRows] = useState<StandardTableRow[]>([])
    const headers: StandardTableHeader[] = [
        { label: 'SINo', id: 'SINo' },
        { label: 'Region', id: 'Region' },
        { label: 'Distributer', id: 'Distributer' },
        { label: 'EBO', id: 'EBO' },
        { label: 'Institution', id: 'Institution' },
        { label: 'Other', id: 'Other' },
        { label: 'Total', id: 'Total' },
    ]

    const [mode, setMode] = useState<'scheduled' | 'unScheduled'>('unScheduled')

    // const {
    //     isLoading: isLoadingForecastRegionWise,
    //     isError: isErrorForecastRegionWise,
    //     isFetching: isFetchingForecastRegionWise,
    //     isFetched: isFetchedForcastRegionWise,
    //     forcastRegionwise,
    //     refetchforcastRegionwise
    // } = useForecastRegionwiseAPI({
    //     date: `${selectedDate}-01`,
    //     MonthCode: monthCode?.monthCode
    // })

    const {
        forecastData: forecastLines,
        isErrorforcast: isErrorLoadingForecastLine,
        isFetchingForcastData: isFetchingForecastLine,
        isFetchedForcastData: isFethedForecastLine,
        isLoadingforcast: isLoadingForecastLine,
        refetchForcastData: refetchForecastLine
    } = useForecastAPI({
        trigger: selectedDate ? true : false,
        FT02_Date: `${selectedDate}-01`,
        FT02_MonthCode: monthCode?.monthCode,
        FT02_ForecastLevel: 'Region',
    })

    const {
        isLoading: isLoadingRegion,
        regions,
        isFetching: isFetchingRegion,
        refetchRegions,
        isError: isErrorRegion,
        isFetched: isFetchedRegion
    } = useRegionAPI({
        trigger: true
    })

    const {
        isErrorlatestCalendarDate,
        isFetchedlatestCalendarDate,
        isFetchinglatestCalendarDate,
        isLoadinglatestCalendarDate,
        latestCalendarDate,
        refetchlatestCalendarDate
    } = useGetLatestCalendarDate({
        trigger: true
    })


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value)
        setSelectedDate(event.target.value);
        // refetchforcastRegionwise()
    };

    const isAnythingLoading = isLoadingRegion || isLoadingForecastLine || isLoadinglatestCalendarDate
    const isAnythingFetching = isFetchingRegion || isFetchingForecastLine || isFetchinglatestCalendarDate
    const isAnyError = isErrorRegion || isErrorLoadingForecastLine || isErrorLoadingForecastLine


    // after fetching data
    useEffect(
        () => {
            if (!isErrorlatestCalendarDate && latestCalendarDate) {
                // setting month codes
                const dates: {
                    monthCode: MonthCode;
                    month: string;
                }[] = [];

                let selectedDate

                if (!latestCalendarDate.date) {
                    const currentDate = new Date();
                    const formattedDate = currentDate.toISOString().substring(0, 10);
                    setSelectedDate(formattedDate?.substring(0, 7));
                    selectedDate = formattedDate?.substring(0, 7)
                } else {
                    setSelectedDate(latestCalendarDate?.date?.substring(0, 7));
                    selectedDate = latestCalendarDate?.date?.substring(0, 7)
                }
                const d = new Date(selectedDate);
                for (let i = 0; i < 3; i++) {
                    d.setMonth(d.getMonth() + 1);
                    dates.push({
                        monthCode: 'M' + (i + 1) as MonthCode,
                        month: d.toISOString().substring(0, 7)
                    });
                }
                setDates(dates)
                setMonthCode({
                    monthCode: dates?.[0]?.monthCode || '',
                    month: dates?.[0]?.month || ''
                })
            }
            if (!isAnythingLoading && !isAnyError) {

                if (regions?.length && forecastLines?.ForecastLines?.length) {
                    setMode('scheduled')
                } else {
                    setMode('unScheduled')
                }

                setRegionForcastRows(regions?.map(
                    (region, index) => {

                        const DistributerData = forecastLines?.ForecastLines?.find((data) => data.FT02_FM07_RegionID === region.FM07_RegionID && data.FT02_ForecastChannel === 'Distributer')
                        const EBO = forecastLines?.ForecastLines?.find((data) => data.FT02_FM07_RegionID === region.FM07_RegionID && data.FT02_ForecastChannel === 'EBO')
                        const Institution = forecastLines?.ForecastLines?.find((data) => data.FT02_FM07_RegionID === region.FM07_RegionID && data.FT02_ForecastChannel === 'Institution')
                        const Other = forecastLines?.ForecastLines?.find((data) => data.FT02_FM07_RegionID === region.FM07_RegionID && data.FT02_ForecastChannel === 'Other')


                        return {
                            id: region.FM07_RegionID,
                            datas: {
                                SINo: { id: 'SINo', data: index + 1 },
                                Region: { id: 'Region', data: region.FM07_RegionName },
                                Distributer: { id: 'Distributer', data: DistributerData?.FT02_ForecastValue },
                                EBO: { id: 'EBO', data: EBO?.FT02_ForecastValue },
                                Institution: { id: 'Institution', data: Institution?.FT02_ForecastValue },
                                Other: { id: 'Other', data: Other?.FT02_ForecastValue },
                            }
                        }

                    }
                ) || [])



            }


        }, [isFetchingForecastLine, selectedDate, isFetchingRegion, isFetchinglatestCalendarDate]
    )

    return (
        <div className=' grow  flex flex-col overflow-auto items-center'>
            <ModalAddForecastModal
                show={isOpen}
                refetchforcastRegionwise={refetchForecastLine}
                setShow={(show) => setIsOpen(show)}
                selectedDate={selectedDate}
                MonthCode={monthCode}

            />

            {mode === 'scheduled' ?
                <div className=' w-full'>
                    <div className=' gap-1 pb-2 dark:text-vstargray-400 flex  items-center justify-end '>

                        <InputStandard type='month' className='w-min' onChange={handleChange} value={selectedDate} />
                        <SelectStandard
                            options={dates.map(data => ({ label: `${data.monthCode} - ${data.month}`, value: data.monthCode }))}
                            placeHolder='Month Code'
                            onChange={(data) => {

                                const selectedMonth = dates.find(date => date.monthCode === data.target.value)
                                setMonthCode({
                                    monthCode: selectedMonth?.monthCode!,
                                    month: selectedMonth?.month as string
                                })
                            }}
                            value={monthCode?.monthCode}
                        />
                        <ButtonIconSquare disabled={isAnythingLoading} onClick={() => setIsOpen(true)} icon={<IconEdit />} text='Edit' variant='secondary' />

                    </div>
                    <StandardTable
                        headers={headers}
                        isLoading={isLoadingForecastLine}
                        isUpdating={isAnythingLoading}
                        rows={RegionForcastRows}
                    />
                </div>
                :
                <div className=' flex flex-col items-center justify-center h-min my-auto'>
                    <IconCalendarFilled className=' dark:text-vstargray-300' size={80} />
                    <p className=' font-bold text-lg dark:text-vstargray-300'>Select Date</p>
                    <div className=' gap-1 p-5 dark:text-vstargray-400 flex flex-col items-center justify-center '>
                        <Input type='month' onChange={handleChange} value={selectedDate} />
                        <SelectStandard
                            options={dates.map(data => ({ label: `${data.monthCode} - ${data.month}`, value: data.monthCode }))}
                            placeHolder='Month Code'
                            onChange={(data) => {

                                const selectedMonth = dates.find(date => date.monthCode === data.target.value)
                                setMonthCode({
                                    monthCode: selectedMonth?.monthCode!,
                                    month: selectedMonth?.month as string
                                })
                            }}
                            value={monthCode?.monthCode}
                        />
                    </div>
                    <div className='  flex flex-col items-center gap-2'>
                        <p className=' font-bold text-2xl !!! dark:text-vstargray-400'>No RegionWise Forecasts Found</p>
                        <ButtonIconSquare disabled={isAnythingLoading} onClick={() => setIsOpen(true)} variant='secondary' text=' Create Forcast' icon={<IconPlus />} />
                    </div>
                </div>
            }
        </div>
    )
}

