import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { AxiosResponseCustom, useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"
import { AxiosProgressEvent } from "axios"



export function useStylewiseForcast({
    retryCount,
}: {
    retryCount?: number,
}) {

    const api = useRestAPI()
    const [totalSubAttributeCount, setTotalSubAttributeCount] = useState(0)
    const [validateProgress,setValidateProgress] = useState(0)
    const [uploadProgress,setuploadProgress] = useState(0)
    const queryclient = useQueryClient()
    // const [deleteRetryCount,setDeleteRetryCount] = useState(0)


    const { mutateAsync: downloadTemplateStylewise, isPending: isDownloadingTEmplateStyleswise, failureCount: downloadTemplateFailureCount, isError: isDownloadTemplateError } = useMutation({
        mutationFn: async () => {

            const response = await api.get({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/np-stylewise-template-generation/`,
                mode: 'protected',
                responseType: 'blob'
            })


            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && downloadTemplateFailureCount < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }
            if (response.success !== false) {
                const blob = new Blob([response as unknown as BlobPart], { type: 'application/zip' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'stylewise_template.zip'; // Set the filename

                // Append to the document and trigger the download
                document.body.appendChild(link);
                link.click();

                // Clean up
                document.body.removeChild(link);
                window.URL.revokeObjectURL(link.href);
            }

            return response
        },
        retry: 4,

    })
    const { mutateAsync: uploadstylewiseExel, isPending: isuploadingstylewiseExel, failureCount: uploadstylewiseExelFailureCount, isError: isuploadstylewiseExellateError } = useMutation({
        mutationFn: async ({ file,date,state }: { file: File,date:string,state:number}) => {

            const formData = new FormData();
            formData.append('file', file);
            formData.append('date',date);
            formData.append('state',state.toString());
            const response = await api.post({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/np-stylewise-forecast/v2/template/upload/`,
                mode: 'protected',
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total!
                    );
                    setuploadProgress(percentCompleted);
                },
                data: formData
            })


            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && uploadstylewiseExelFailureCount < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }


            return response
        },
        retry: 4,

    })
    const { mutateAsync: validatestylewiseExel, isPending: isvalidateingstylewiseExel, failureCount: validatestylewiseExelFailureCount, isError: isvalidatestylewiseExelError } = useMutation({
        mutationFn: async ({ file }: { file: File }) => {

            const formData = new FormData();
            formData.append('file', file);

            const response = await api.post({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/np-stylewise-forecast/v2/template/validate/`,
                mode: 'protected',
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total!
                    );
                    setValidateProgress(percentCompleted);
                },
                data: formData
            });


            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && validatestylewiseExelFailureCount < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }


            return response
        },
        retry: 4,

    })






    return {

        downloadTemplateStylewise,
        isDownloadingTEmplateStyleswise,
        isDownloadTemplateError,
        validatestylewiseExel,
        isvalidateingstylewiseExel,
        isvalidatestylewiseExelError,
        validateProgress,
        uploadstylewiseExel,
        isuploadingstylewiseExel,
        uploadProgress
    }


}




