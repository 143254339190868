import { useMutation, useQuery } from "@tanstack/react-query"
import { useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"

export type DepartmentData = {
    M17_Dept_id: number,
    M17_Dept_name: string
}


const QUERY_KEY = 'department'
export function useDepartmentAPI({
    page_size,
    page,
    retryCount,
    trigger=true
}:{
    page_size?: number,
    page?: number,
    retryCount?:number,
    trigger?:boolean
}) {

    const api = useRestAPI()
    const [totalDepartmentCount, setTotalDepartmentCount] = useState(0)

    const {
        isLoading,
        isError,
        data: departments,
        refetch: refetchDepartments,
        isFetching,
        isFetched,
        error
    } = useQuery({
        queryKey: [QUERY_KEY, page_size, page],
        queryFn: async () => {
            const response = await api.get<{ data: DepartmentData[] }&backendPagination>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/departments/`,
                mode: 'protected',
                params: {
                    page_size,
                    page
                }
            })
            if (response.success === false) {
                throw response.message
            }

            setTotalDepartmentCount(response.count)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger
        
    })


 const { mutateAsync: createDepartment } = useMutation({
        mutationFn: async (Department:DepartmentData) => {
            const response = await api.post<{ access: string,refresh:string }, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/departments/`,
                data: Department,
                mode:'protected'
            })


            if (response.success === false) {
                return response
            }
            localStorage.setItem('accessTocken', response.access);
            localStorage.setItem('refreshTocken', response.refresh);
            return response
        }
    })


    return {
        isLoading,
        isError,
        departments,
        refetchDepartments,
        totalDepartmentCount,
        isFetching,
        isFetched,
        error,
        createDepartment
    }


}


