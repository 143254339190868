import { IconCloudUp, IconLoader, IconX, IconUpload, IconLoader2 } from '@tabler/icons-react'
import React, { useEffect, useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSkuAPI } from 'src/API/rest.sku'
import { PostSubAttributes } from 'src/API/rest.subattribute'
import { ButtonIconSquare } from 'src/components/buttons'
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from 'src/components/standard.modal'
import { Progress } from 'src/shadcn/ui/progress'
import { toast } from 'src/shadcn/ui/use-toast'

type Props = {
    show?: boolean,
    setShow?: (show: boolean) => void,
    onSuccess: () => void
}

export function RefStyleUploadExcel(props: Props) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDownloadingTemplate, setisDownloadingTemplate] = useState(false);
    const [uplaodProgress, setuplaodProgress] = useState(0);
    const [file, setFile] = useState<File | null>(null);

    useEffect(() => {
        if (props.show) {
            setFile(null);
        }
    }, [props.show])

    const {
        uploadNPStyleRef,
        isUpdatingSubAttribute
    } = PostSubAttributes()

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            setFile(acceptedFiles[0]);
        } else {
            toast({
                description: 'Please select a Valid File',
            })
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'text/csv': ['.csv']
        },
        disabled: isSubmitting
    });

    const handleUpload = async () => {
        if (file) {
            setIsSubmitting(true);
            // Implement your file upload logic here
            const response = await uploadNPStyleRef({
                excelFile: file,
                onUploadProgress:(progressEvent) => {
                    // Calculate the progress percentage
                    const percentCompleted = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total!
                    );
                    setuplaodProgress(percentCompleted);
                  }
            })

            if (response.success === true) {
                toast({
                    description: response.message
                })
                props.setShow?.(false);
                props.onSuccess();
            } else {
                toast({
                    description: response.message
                })
            }
            // After upload is complete:
            setIsSubmitting(false);
        }
    };

    const handleDownloadTemplate = async () => {
        setisDownloadingTemplate(true);
        // Implement your file download logic here
        // After download is complete:
        setisDownloadingTemplate(false);
    };



    const isAnythingLoading = false;

    return (
        <ModalStandard
            open={props.show}
        // onOpenChange={props.setShow}
        >
            <ModalStandardContent hideCloseButton={true} onOpenAutoFocus={(e) => e?.preventDefault()} className='bg-vstargray-100'>
                <ModalStandardHeader>
                    <ModalStandardTitle>Referense style update</ModalStandardTitle>
                    <ModalStandardDescription>
                        {isAnythingLoading && (
                            <div className='px-2 p-1 rounded-md bg-vstargray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>
                        )}
                        
                        <div className=' h-[400px] w-full flex items-center justify-center'>
                            <div {...getRootProps()} className=" h-[250px] w-[250px] flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer hover:border-gray-400 transition-colors">
                                {isSubmitting ?
                                    <div className='w-full flex flex-col items-center justify-center gap-2'> 
                                        <p>Uploading...</p>
                                        <Progress value={uplaodProgress} />
                                    </div>
                                    :
                                    <>
                                        <input disabled={isSubmitting} {...getInputProps()} />
                                        {isDragActive ? (
                                            <p>Drop the Excel or CSV file here...</p>
                                        ) : (
                                            <div>
                                                <IconUpload className="mx-auto h-12 w-12 text-gray-400" />
                                                <p>Drag and drop an Excel or CSV file here, or click to select a file</p>
                                            </div>
                                        )}
                                        {file && (
                                            <p className="mt-2 text-sm text-gray-600">Selected file: {file.name}</p>
                                        )}
                                    </>
                                }
                            </div>
                        </div>
                        <div className=' w-full text-end fle'>Download Template {isDownloadingTemplate ? <IconLoader2 size={10} className=' inline animate-spin' /> : <span onClick={handleDownloadTemplate} className=' underline text-blue-500 cursor-pointer hover:text-blue-700'>here</span>}</div>
                    </ModalStandardDescription>
                </ModalStandardHeader>
                <ModalStandardFooter>
                    <ButtonIconSquare
                        variant='white'
                        icon={<IconX />}
                        text='Close'
                        onClick={(e) => { e?.preventDefault(); props.setShow?.(false) }}
                        disabled={isSubmitting}
                    />
                    <ButtonIconSquare
                        variant='secondary'
                        icon={isSubmitting ? <IconLoader className='animate-spin' /> : <IconCloudUp />}
                        text='Upload'
                        onClick={handleUpload}
                        disabled={isSubmitting || !file}
                    />
                </ModalStandardFooter>
            </ModalStandardContent>
        </ModalStandard>
    )
}