import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { AxiosResponseCustom, useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"
import { ForecastChannelType, MonthCode } from "./rest.forecast_regionwise"

export type ForecastLevelType = "Region" |"SE-Allotment"| null

export type ForecastHeaderType = {
  FT01_ForecastHeaderID: number | null;
  FT01_ForecastLevel: ForecastLevelType;
  FT01_Date: string;
  FT01_ForecastMonth: string|null;
  FT01_MonthCode: MonthCode | null;
  FT01_ForecastChannel: string | null;
  FT01_M04_StateID: number | null;
  FT01_Remarks: string | null;
  FT01_M03_CreatedBy?: number | null;
  FT01_CreatedDate?: string | null;
  FT01_SubmitFlag: 1 | 0;
  FT01_IsApprove: boolean | null;
  FT01_ApproveDate: string | null;
  FT01_ApprovedBy: number | null;
  FT01_Status: boolean;
  FT01_StatusDate: string | null;
  FT01_ForecastFileName: string | null;
}

export type ForecastLineType = {
  FT02_ForecastLineID: number | null;
  FT02_FT01_ForecastHeaderID: number | null;
  FT02_ForecastLevel:ForecastLevelType;
  FT02_Date: string | null;
  FT02_ForecastMonth: string | null;
  FT02_MonthCode: MonthCode | null;
  FT02_ForecastChannel: ForecastChannelType | null;
  FT02_SuggestedQty: number | null;
  FT02_SuggestedValue: number | null;
  FT02_ForecastQty: number | null;
  FT02_ASP: number | null;
  FT02_ForecastValue: number | null;
  FT02_StyleFlag: string | null;
  FT02_M12_ProductSubCategory: number | null;
  FT02_M12_ProductCategory: number | null;
  FT02_ABCCategory: string | null;
  FT02_M13_ProductID: number | null;
  FT02_M14_SubAttributeID: number | null;
  FT02_M04_StateID: number | null;
  FT02_FM07_RegionID: number | null;
  FT02_M03_EmployeeIDRSM: number | null;
  FT02_M03_EmployeeIDASM: number | null;
  FT02_M03_EmployeeIDSE: number | null;
  FT02_M11_DistributorID: number | null;
  FT02_Remarks: string | null;
  FT02_LYM1Qty: number | null;
  FT02_LYM2Qty: number | null;
  FT02_LYM3Qty: number | null;
  FT02_M1Qty: number | null;
  FT02_M2Qty: number | null;
  FT02_M3Qty: number | null;
  FT02_RunningMonthForecast: number | null;
  FT02_RunningMonthActuals: number | null;
  FT02_M1Suggested: number | null;
  FT02_Forecast1: number | null;
  FT02_Forecast2: number | null;
  FT02_Forecast3: number | null;
  FT02_SubmitFlag: 1 | 0 | null;
  FT02_Status: number | null;
  FT02_StatusDate: string | null;
  FT02_CreatedDate: string | null;
}


export type ForecastReadType = {
  ForecastHeader: ForecastHeaderType[];
  ForecastLines: ForecastLineType[];
}

export type ForecastRegionWisePostType = {

  ForcastLines: ForecastLineType[]
} & ForecastHeaderType




const QUERY_KEY = 'forcastData'
export function useForecastAPI({
  FT02_Date,
  FT02_MonthCode,
  FT02_ForecastLevel,
  FT02_M03_EmployeeIDRSM,
  FT02_M03_EmployeeIDASM,
  FT02_M03_EmployeeIDSE,
  FT02_M04_StateID,
  trigger = true,
  retryCount,
}: {
  FT02_Date?: string,
  FT02_MonthCode?: MonthCode,
  FT02_ForecastLevel?:ForecastLevelType,
  FT02_M03_EmployeeIDRSM?: number|null,
  FT02_M03_EmployeeIDASM?: number|null,
  FT02_M03_EmployeeIDSE?: number|null,
  FT02_M04_StateID?: number|null,
  retryCount?: number,
  trigger?: boolean
}) {

  // const [deleteRetryCount,setDeleteRetryCount] = useState(0)

  const {
    isLoading: isLoadingforcast,
    isError: isErrorforcast,
    data: forecastData,
    refetch: refetchForcastData,
    isFetching:isFetchingForcastData,
    isFetched:isFetchedForcastData,
    error,
    isRefetchError,
    isFetchedAfterMount,
  } = useQuery({
    queryKey: [QUERY_KEY,FT02_Date, FT02_MonthCode, FT02_ForecastLevel, FT02_M03_EmployeeIDRSM, FT02_M03_EmployeeIDASM, FT02_M03_EmployeeIDSE,FT02_M04_StateID],
    queryFn: async (): Promise<ForecastReadType> => {

      const response = await api.get<{ data: ForecastReadType }>({
        url: `${process.env.REACT_APP_BACKEND_URL}/transactions/forecast-read/`,
        mode: 'protected',
        params: {
          FT02_Date: FT02_Date,
          FT02_MonthCode: FT02_MonthCode,
          FT02_ForecastLevel: FT02_ForecastLevel,
          EmployeeIDRSM: FT02_M03_EmployeeIDRSM,
          EmployeeIDASM: FT02_M03_EmployeeIDASM,
          EmployeeIDSE: FT02_M03_EmployeeIDSE,
          FT02_M04_StateID: FT02_M04_StateID
        
        }
      })
      if (response.success === false) {
        throw response.data
      }

      return response.data
      

      // const response:ForecastReadType = await new Promise(
      //   (resolve,reject)=>{
      //     setTimeout(
      //       ()=>{
              
      //         // if(EmployeeIDSE===1){
      //           resolve(
      //             {
      //               ForecastHeader:[],
      //               ForecastLines:[]
      //             }
      //           )
      //           // resolve(
      //           //   {
      //           //     ForecastHeader: [
      //           //       {
      //           //         ForecastHeaderID: 1,
      //           //         ForecastLevel: "SE-Allotment",
      //           //         Date: "2024-01-01",
      //           //         ForecastMonth: "Jan",
      //           //         MonthCode: "M1",
      //           //         ForecastChannel: "M1-Total",
      //           //         StateID: 1,
      //           //         Remarks: null,
      //           //         SubmitFlag: 0,
      //           //         IsApprove: null,
      //           //         ApproveDate: null,
      //           //         ApprovedBy: null,
      //           //         Status: true,
      //           //         StatusDate: null,
      //           //         ForecastFileName: null
      //           //       }
      //           //     ],
      //           //     ForecastLines: [
      //           //       {
      //           //         FT02_ForecastLineID: null,
      //           //         FT02_FT01_ForecastHeaderID: null,
      //           //         FT02_ForecastLevel: "SE-Allotment",
      //           //         FT02_Date: "2024-01-01",
      //           //         FT02_ForecastMonth: "Jan",
      //           //         FT02_MonthCode: "M1",
      //           //         FT02_ForecastChannel: "M1-Total",
      //           //         FT02_SuggestedQty: 100,
      //           //         FT02_SuggestedValue: 1000,
      //           //         FT02_ForecastQty: null,
      //           //         FT02_ASP: null,
      //           //         FT02_ForecastValue: 20,
      //           //         FT02_StyleFlag: null,
      //           //         FT02_M12_ProductSubCategory: null,
      //           //         FT02_M12_ProductCategory: null,
      //           //         FT02_ABCCategory: null,
      //           //         FT02_M13_ProductID: null,
      //           //         FT02_M14_SubAttributeID: null,
      //           //         FT02_M04_StateID: 1,
      //           //         FT02_FM07_RegionID: 1,
      //           //         FT02_M03_EmployeeidRSM: null,
      //           //         FT02_M03_EmployeeidASM: null,
      //           //         FT02_M03_EmployeeidSE: 2,
      //           //         FT02_M11_DistributorID: null,
      //           //         FT02_Remarks: null,
      //           //         FT02_LYM1Qty: null,
      //           //         FT02_LYM2Qty: null,
      //           //         FT02_LYM3Qty: null,
      //           //         FT02_M1Qty: null,
      //           //         FT02_M2Qty: null,
      //           //         FT02_M3Qty: null,
      //           //         FT02_RunningMonthForecast: null,
      //           //         FT02_RunningMonthActuals: null,
      //           //         FT02_M1Suggested: null,
      //           //         FT02_Forecast1: null,
      //           //         FT02_Forecast2: null,
      //           //         FT02_Forecast3: null,
      //           //         FT02_SubmitFlag: 0,
      //           //         FT02_Status: 1,
      //           //         FT02_StatusDate: "2024-01-01",
      //           //         FT02_CreatedDate: "2024-01-01",
      //           //       },
      //           //       {
      //           //         FT02_ForecastLineID: null,
      //           //         FT02_FT01_ForecastHeaderID: null,
      //           //         FT02_ForecastLevel: "SE-Allotment",
      //           //         FT02_Date: "2024-01-01",
      //           //         FT02_ForecastMonth: "Jan",
      //           //         FT02_MonthCode: "M1",
      //           //         FT02_ForecastChannel: "M2-Total",
      //           //         FT02_SuggestedQty: 100,
      //           //         FT02_SuggestedValue: 1000,
      //           //         FT02_ForecastQty: null,
      //           //         FT02_ASP: null,
      //           //         FT02_ForecastValue: 210,
      //           //         FT02_StyleFlag: null,
      //           //         FT02_M12_ProductSubCategory: null,
      //           //         FT02_M12_ProductCategory: null,
      //           //         FT02_ABCCategory: null,
      //           //         FT02_M13_ProductID: null,
      //           //         FT02_M14_SubAttributeID: null,
      //           //         FT02_M04_StateID: 1,
      //           //         FT02_FM07_RegionID: 1,
      //           //         FT02_M03_EmployeeidRSM: null,
      //           //         FT02_M03_EmployeeidASM: null,
      //           //         FT02_M03_EmployeeidSE: 2,
      //           //         FT02_M11_DistributorID: null,
      //           //         FT02_Remarks: null,
      //           //         FT02_LYM1Qty: null,
      //           //         FT02_LYM2Qty: null,
      //           //         FT02_LYM3Qty: null,
      //           //         FT02_M1Qty: null,
      //           //         FT02_M2Qty: null,
      //           //         FT02_M3Qty: null,
      //           //         FT02_RunningMonthForecast: null,
      //           //         FT02_RunningMonthActuals: null,
      //           //         FT02_M1Suggested: null,
      //           //         FT02_Forecast1: null,
      //           //         FT02_Forecast2: null,
      //           //         FT02_Forecast3: null,
      //           //         FT02_SubmitFlag: 0,
      //           //         FT02_Status: 1,
      //           //         FT02_StatusDate: "2024-01-01",
      //           //         FT02_CreatedDate: "2024-01-01",
      //           //       },
      //           //       {
      //           //         FT02_ForecastLineID: null,
      //           //         FT02_FT01_ForecastHeaderID: null,
      //           //         FT02_ForecastLevel: "SE-Allotment",
      //           //         FT02_Date: "2024-02-01",
      //           //         FT02_ForecastMonth: "Feb",
      //           //         FT02_MonthCode: "M2",
      //           //         FT02_ForecastChannel: "M2-Total",
      //           //         FT02_SuggestedQty: 200,
      //           //         FT02_SuggestedValue: 2000,
      //           //         FT02_ForecastQty: null,
      //           //         FT02_ASP: null,
      //           //         FT02_ForecastValue: 30,
      //           //         FT02_StyleFlag: null,
      //           //         FT02_M12_ProductSubCategory: null,
      //           //         FT02_M12_ProductCategory: null,
      //           //         FT02_ABCCategory: null,
      //           //         FT02_M13_ProductID: null,
      //           //         FT02_M14_SubAttributeID: null,
      //           //         FT02_M04_StateID: 1,
      //           //         FT02_FM07_RegionID: 1,
      //           //         FT02_M03_EmployeeidRSM: null,
      //           //         FT02_M03_EmployeeidASM: null,
      //           //         FT02_M03_EmployeeidSE: 1,
      //           //         FT02_M11_DistributorID: null,
      //           //         FT02_Remarks: null,
      //           //         FT02_LYM1Qty: null,
      //           //         FT02_LYM2Qty: null,
      //           //         FT02_LYM3Qty: null,
      //           //         FT02_M1Qty: null,
      //           //         FT02_M2Qty: null,
      //           //         FT02_M3Qty: null,
      //           //         FT02_RunningMonthForecast: null,
      //           //         FT02_RunningMonthActuals: null,
      //           //         FT02_M1Suggested: null,
      //           //         FT02_Forecast1: null,
      //           //         FT02_Forecast2: null,
      //           //         FT02_Forecast3: null,
      //           //         FT02_SubmitFlag: 0,
      //           //         FT02_Status: 1,
      //           //         FT02_StatusDate: "2024-02-01",
      //           //         FT02_CreatedDate: "2024-02-01",
      //           //       },
      //           //       {
      //           //         FT02_ForecastLineID: null,
      //           //         FT02_FT01_ForecastHeaderID: null,
      //           //         FT02_ForecastLevel: "SE-Allotment",
      //           //         FT02_Date: "2024-03-01",
      //           //         FT02_ForecastMonth: "Mar",
      //           //         FT02_MonthCode: "M3",
      //           //         FT02_ForecastChannel: "M3-Total",
      //           //         FT02_SuggestedQty: 300,
      //           //         FT02_SuggestedValue: 3000,
      //           //         FT02_ForecastQty: null,
      //           //         FT02_ASP: null,
      //           //         FT02_ForecastValue: 50,
      //           //         FT02_StyleFlag: null,
      //           //         FT02_M12_ProductSubCategory: null,
      //           //         FT02_M12_ProductCategory: null,
      //           //         FT02_ABCCategory: null,
      //           //         FT02_M13_ProductID: null,
      //           //         FT02_M14_SubAttributeID: null,
      //           //         FT02_M04_StateID: 1,
      //           //         FT02_FM07_RegionID: 1,
      //           //         FT02_M03_EmployeeidRSM: null,
      //           //         FT02_M03_EmployeeidASM: null,
      //           //         FT02_M03_EmployeeidSE: 3,
      //           //         FT02_M11_DistributorID: null,
      //           //         FT02_Remarks: null,
      //           //         FT02_LYM1Qty: null,
      //           //         FT02_LYM2Qty: null,
      //           //         FT02_LYM3Qty: null,
      //           //         FT02_M1Qty: null,
      //           //         FT02_M2Qty: null,
      //           //         FT02_M3Qty: null,
      //           //         FT02_RunningMonthForecast: null,
      //           //         FT02_RunningMonthActuals: null,
      //           //         FT02_M1Suggested: null,
      //           //         FT02_Forecast1: null,
      //           //         FT02_Forecast2: null,
      //           //         FT02_Forecast3: null,
      //           //         FT02_SubmitFlag: 0,
      //           //         FT02_Status: 1,
      //           //         FT02_StatusDate: "2024-03-01",
      //           //         FT02_CreatedDate: "2024-03-01",
      //           //       },
      //           //     ]
      //           //   }
      //           // )
      //         // }
      //         // resolve({
      //         //   ForecastHeader:[],
      //         //   ForecastLines:[]
      //         // })
      //       },1000
      //     )
      //   }
      // )
      
    },
    refetchOnWindowFocus: false,
    retry: retryCount,
    enabled: trigger,


  })

  const api = useRestAPI()





  const { mutateAsync: createCalendarLine, isPending: isCreatingCalendarLine, failureCount: errorCreatingRegionCount, isError: isErrorCreateForecast } = useMutation({
    mutationFn: async (actionsLines: ForecastRegionWisePostType) => {


      const response = await api.post<{}, {}>({
        url: `${process.env.REACT_APP_BACKEND_URL}/transactions/forecast/`,
        data: actionsLines,
        mode: 'protected'
      })

      if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && errorCreatingRegionCount < 4) {

        throw response
      }

      return response
    },
    retry: 4,

  })
  const { mutateAsync: updateCalendarLine, isPending: isUpdatingCalendarLine, failureCount: errorUpdatingRegionCount, isError: isErrorUpdateForecast } = useMutation({
    mutationFn: async ({
      id,
      actionsLines
    }:{actionsLines: ForecastRegionWisePostType, id: number}) => {


      const response = await api.put<{}, {}>({
        url: `${process.env.REACT_APP_BACKEND_URL}/transactions/forecast/update/${id}/`,
        data: actionsLines,
        mode: 'protected'
      })

      if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && errorUpdatingRegionCount < 4) {

        throw response
      }

      return response
    },
    retry: 4,

  })




  return {
    forecastData,
    refetchForcastData,
    isFetchingForcastData,
    isLoadingforcast,
    isErrorforcast,
    isFetchedForcastData,
    createCalendarLine,
    isCreatingCalendarLine,
    isErrorCreateForecast,updateCalendarLine
  }


}


