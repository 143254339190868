import { IconEdit, IconLoader, IconPlus, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ForecastRegionWisePostType, MonthCode } from 'src/API/rest.forecast_regionwise';
import { ReferenceMonthData, useReferenceMonthAPI } from 'src/API/rest.referenceMonth';
import { ButtonIconSquare } from 'src/components/buttons';
import { InputStandard } from 'src/components/standard.input';
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from 'src/components/standard.modal';
import { toast } from 'src/shadcn/ui/use-toast';
import { isatty } from 'tty';

type Props = {
    show?: boolean,
    setShow?: (show: boolean) => void,
    refetchlatestCalenderDate: () => void,
    employeeDistributorMappingId?: number | string | null,
    selectedDate: string,
    MonthCode?: {
        monthCode: MonthCode;
        month: string;
    }
};



type MonthType = {
    month: string;
    value: string;
    status: boolean;
    id?: number
}



type formType = {
    data: MonthType[]
}



export function ModalAddRefMonthModal(props: Props) {

    const { register, handleSubmit, setValue, reset, formState: { errors }, watch } = useForm<formType>({

    });

    const [standardMonths, setStandardMonths] = useState<MonthType[]>([
        { month: 'LYM1', value: '', status: true },
        { month: 'LYM2', value: '', status: true },
        { month: 'LYM3', value: '', status: true },
        { month: 'M-3', value: '', status: true },
        { month: 'M-2', value: '', status: true },
        { month: 'M-1', value: '', status: true },
    ]);




    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [mode, setMode] = useState<'add' | 'edit'>('add');

    const {
        isLoading: isLoadingReferenceMonth,
        isError: isErrorReferenceMonth,
        isFetching: isFetchingReferenceMonth,
        isFetched: isFetchedReferenceMonth,
        referenceMonths,
        createReferenceMonth,
        isCreateReferenceMonthPending,
        isCreateReferenceMonthError,
        updateReferenceMonth,
        isUpdateReferenceMonthError,
        isUpdateReferenceMonthPending
    } = useReferenceMonthAPI({
        trigger: props.show && props.selectedDate?true:false,
        forcastMonth: props.selectedDate || ''
    })







    useEffect(() => {
        if (!isLoadingReferenceMonth && isFetchingReferenceMonth && !isErrorReferenceMonth) {




           


            if (referenceMonths?.length) {

                standardMonths.forEach(item => {

                    const value = referenceMonths?.find(month => month.FT17_month_name === item.month)
                    if (value) {
                        item.value = value.FT17_date
                        item.id = value.FT17_Id
                    }

                })

                setStandardMonths([...standardMonths])
                reset({
                    data: standardMonths
                })

                setMode('edit')
            } else {
                setMode('add')
                reset({
                    data: standardMonths
                })
            }
            // reset(options)
        }

        if (isErrorReferenceMonth) {
            toast({
                description: 'Error in fetching data',
                title: 'Error',
            })
        }

    }, [props.show, isFetchingReferenceMonth]);

    const onSubmit = async (data: formType) => {
        setIsSubmitting(true);
        let postData: ReferenceMonthData[]

        if (mode === 'add') {
            
            postData = data.data.map(item => {
                return {
                    FT17_forecast_month: props.selectedDate,
                    FT17_date: item.value,
                    FT17_month_name: item.month,
                    FT17_status: item.status?1:0,
                    FT17_type: '',
                }
            })
            console.log(postData)

            const response = await createReferenceMonth({
                referenceMonth: postData
            });
            if (response.success) {
                toast({ description: response.message });
                props.setShow?.(false);
                props.refetchlatestCalenderDate();
            } else {
                toast({ description: response.message, title: 'Error' });
            }
        } else if (mode === 'edit') {

            postData = data.data.map(item => {
                return {
                    FT17_forecast_month: props.selectedDate,
                    FT17_date: item.value,
                    FT17_month_name: item.month,
                    FT17_status: item.status?1:0,
                    FT17_type: '',
                    FT17_Id: item.id
                }
            })

            const response = await updateReferenceMonth({
                referenceMonth: postData
            });
            if (response.success) {
                toast({ description: response.message });
                props.setShow?.(false);
                props.refetchlatestCalenderDate();
            } else {
                toast({ description: response.message, title: 'Error' });
            }

        }
        setIsSubmitting(false);
    };



    const isAnythingLoading = isLoadingReferenceMonth
    const isAnyFetching = isFetchingReferenceMonth

    const isAnyLoadError = isErrorReferenceMonth



    return (
        <ModalStandard open={props.show} onOpenChange={(show)=>{
            if(isCreateReferenceMonthPending||isUpdateReferenceMonthPending){
                
            }else{
                props.setShow?.(show)
            }
        }} >
            <ModalStandardContent className="bg-gray-100  flex flex-col" hideCloseButton={isCreateReferenceMonthPending||isUpdateReferenceMonthPending} >
                <form className=' ' onSubmit={handleSubmit(onSubmit)}>
                    <ModalStandardHeader className=' grow' >
                        <ModalStandardTitle>{'NP Forcast Month'}</ModalStandardTitle>
                        <ModalStandardDescription >
                            {(isAnythingLoading || isAnyFetching) && <div className=' px-2 p-1 rounded-md bg-vstargray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>}
                            <div className=' flex flex-col w-full'>
                                <div className=" grid grid-cols-3  gap-1 mb-4">
                                    {standardMonths.map((month, index) => (
                                        <div key={index}>
                                            <div className="bg-vstardark-400  rounded-lg text-vstargray-800 font-medium">{month.month}</div>
                                            <div className="w-full ">
                                                <InputStandard
                                                    disabled={isAnythingLoading || isAnyFetching}
                                                    {...register(`data.${index}.value`, { required: 'required' })}
                                                    type='date'
                                                    className='w-full'
                                                    error={errors?.data?.[index]?.value?.message}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='min-h-2'></div>
                        </ModalStandardDescription>
                    </ModalStandardHeader>
                    <ModalStandardFooter>
                        <ButtonIconSquare
                            variant="white"
                            icon={<IconX />}
                            text="Close"
                            onClick={(e) => {
                                props.setShow?.(false);
                                e?.preventDefault();
                            }}
                            disabled={isCreateReferenceMonthPending || isUpdateReferenceMonthPending} />
                        <ButtonIconSquare
                            disabled={isCreateReferenceMonthPending || isUpdateReferenceMonthPending || isAnythingLoading || isAnyFetching}
                            variant="secondary"
                            icon={isSubmitting ? <IconLoader className="animate-spin" /> : mode === 'add' ? <IconPlus /> : <IconEdit />}
                            text={mode === 'add' ? 'Add' : 'Edit'}
                            type="submit"
                        />
                    </ModalStandardFooter>
                </form>
            </ModalStandardContent>
        </ModalStandard>
    );
}
