import { IconEdit, IconLoader, IconPlus, IconX } from '@tabler/icons-react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useBranchAPI } from 'src/API/rest.branch'
import { useDepartmentAPI } from 'src/API/rest.department'
import { useDesignationAPI } from 'src/API/rest.designations'
import { salesRoleType, useEmployeeAPI } from 'src/API/rest.employee'
import { useEmployeeTypeAPI } from 'src/API/rest.employee_category'
import { useStatesAPI } from 'src/API/rest.states'
import { ButtonIconSquare } from 'src/components/buttons'
import { InputStandard } from 'src/components/standard.input'
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from 'src/components/standard.modal'
import { MultiselectStandard } from 'src/components/standard.multiselect'
import { SelectStandard } from 'src/components/standard.select'
import { SwitchDemo } from 'src/components/standard.switch'
import { toast } from 'src/shadcn/ui/use-toast'





type Props = {

    show?: boolean,
    setShow?: (show: boolean) => void,
    refetchEmployees: () => void,
    mode: 'add' | 'edit',
    employeeId?: number | string | null
}
type FormData = {
    FM03_EmployeeID?: number,
    FM03_EmployeeCode: string,
    FM03_EmployeeName: string,
    FM03_EmployeeType: string,
    FM03_M02_BranchID: number | null,
    FM03_M05_Department: number | null,
    FM03_M05_DesignationID: number | null,
    FM03_Mobile: string,
    FM03_MailID: string,
    FM03_Username: string,
    FM03_M03ReportingtoEmployeeID: number | null,
    FM03_AllowLogin: number,
    FM03_ReportSortOrder: number,
    FM03_Status: number,
    FM03_StatusDate: string|null,
    is_active: boolean,
    is_staff: boolean,
    is_superuser: boolean,
    mapping_state_id: number[],
    sales_role: salesRoleType
}

export function ModalAddEmployee(props: Props) {

    const { register, handleSubmit, setValue, reset, formState: { errors }, control, watch } = useForm<FormData>({
        defaultValues: {
            "FM03_EmployeeCode": "",
            "FM03_EmployeeName": "",
            "FM03_EmployeeType": "",
            "FM03_M02_BranchID": null,
            "FM03_M05_Department": null,
            "FM03_M05_DesignationID": null,
            "FM03_Mobile": "",
            "FM03_MailID": "",
            "FM03_Username": "",
            "FM03_M03ReportingtoEmployeeID": null,
            "FM03_AllowLogin": 1,
            "FM03_ReportSortOrder": 1,
            "FM03_Status": 1,
            "FM03_StatusDate": null,
            "is_active": true,
            "is_staff": true,
            "is_superuser": true
        }
    });

    const [isSubmitting, setIsSubmitting] = React.useState(false);


    const allow_login = watch('FM03_AllowLogin')
    const status = watch('FM03_Status')
    const employee_type = watch('FM03_EmployeeType')
    const branch = watch('FM03_M02_BranchID')
    const department = watch('FM03_M05_Department')
    const designation = watch('FM03_M05_DesignationID')
    const reporting_to = watch('FM03_M03ReportingtoEmployeeID')
    const sales_role = watch('sales_role')
    const mapping_state_id = watch('mapping_state_id')




    const {
        branches,
        isLoading: isLoadingBranch,
        isFetching: isFetchingBranch,
        isError:isErrorBranch
    } = useBranchAPI({
        trigger: props.show
    })
    const {
        emloyees,
        isLoading: isLoadingEmployees,
        isFetching: isFetchingEmployees,
        readEmployeebyId,
        isReadingEmployee,
        createEmployee,
        updateEmployee,
        isUpdatingEmployee
    } = useEmployeeAPI({
        trigger: props.show
    })
    const {
        designations,
        isLoading: isLoadingdesignation,
        isFetching: isFetchingDesignation,
        isError:isErrorDesignation

    } = useDesignationAPI({
        trigger: props.show
    })

    const {
        employeeTypes,
        isLoading: isEmlployeeTypeLoading,
        isError: isEmlployeeTypeError,
        isFetching: isEmlployeeTypeFetching,
    } = useEmployeeTypeAPI({
        trigger:props.show
    })

    const {
        departments,
        isLoading: isLoadingDepartments,
        isFetching: isFetchingDepartments,
        isError:isErrorDepartments
    } = useDepartmentAPI({
        trigger: props.show
    })

    const {
        states,
        isError: isErrorStates,
        isLoading: isLoadingStates,
        isFetching: isFetchingStates,

    } = useStatesAPI({
        trigger: props.show,
        page_size: 100
    })

    useEffect(
        () => {
            console.log(props.show)
            if (props.show && props.mode === 'add') {
                reset({
                    FM03_EmployeeCode: '',
                    FM03_EmployeeName: '',
                    FM03_EmployeeType: '',
                    FM03_M02_BranchID: null,
                    FM03_M05_Department: null,
                    FM03_M05_DesignationID: null,
                    FM03_Mobile: '',
                    FM03_MailID: '',
                    FM03_Username: '',
                    FM03_M03ReportingtoEmployeeID: null,
                    FM03_AllowLogin: 1,
                    FM03_ReportSortOrder: 1,
                    FM03_Status: 1,
                    FM03_StatusDate: null,
                    is_active: true,
                    is_staff: true,
                    is_superuser: true
                })
            }
            if (props.mode === 'edit' && props.employeeId && props.show) {
                readEmployeebyId({ employeeId: props.employeeId }).then((response) => {
                    if (response.success === true) {
                        reset({
                            FM03_EmployeeCode: response.data.FM03_EmployeeCode,
                            FM03_EmployeeName: response.data.FM03_EmployeeName,
                            FM03_EmployeeType: response.data.FM03_EmployeeType,
                            FM03_M02_BranchID: response.data.FM03_M02_BranchID,
                            FM03_M05_Department: response.data.FM03_M05_Department,
                            FM03_M05_DesignationID: response.data.FM03_M05_DesignationID,
                            FM03_Mobile: response.data.FM03_Mobile,
                            FM03_MailID: response.data.FM03_MailID,
                            FM03_Username: response.data.FM03_Username,
                            FM03_M03ReportingtoEmployeeID: response.data.FM03_M03ReportingtoEmployeeID,
                            FM03_AllowLogin: response.data.FM03_AllowLogin,
                            FM03_ReportSortOrder: response.data.FM03_ReportSortOrder,
                            FM03_StatusDate: response.data.FM03_StatusDate,
                            FM03_Status: response.data.FM03_Status,
                            is_active: response.data.is_active,
                            is_staff: response.data.is_staff,
                            is_superuser: response.data.is_superuser
                        })
                    } else {
                        toast({
                            description: response.message,
                            title: "error",
                            // variant:''
                        })
                    }
                })
            }
        }, [props.show]
    )

    const onSubmit = async (data: FormData) => {

        setIsSubmitting(true)
        // data.FM03_StatusDate = new Date().toISOString()
        const submitData = { ...data, mapping_state_id: data.mapping_state_id.map(id => Number(id)) }
        console.log(submitData)
        if (props.mode === 'add') {
            const response = await createEmployee(submitData)
            if (response.success === true) {
                toast({
                    description: response.message,
                    // variant:''
                })
                props.setShow?.(false);
                props.refetchEmployees()
            } else {
                toast({
                    description: response.message,
                    title: "error",
                    // variant:''
                })
            }
        } else if (props.mode === 'edit' && props.employeeId) {
            const response = await updateEmployee({
                id: props.employeeId,
                Employee: submitData
            })
            if (response.success === true) {
                toast({
                    description: response.message,
                    // variant:''
                })
                props.setShow?.(false);
                props.refetchEmployees()
            } else {
                toast({
                    description: response.message,
                    title: "error",
                    // variant:''
                })
            }
        }
        setIsSubmitting(false)
    };

    const isAnythingLoadingorFetching = isUpdatingEmployee || isLoadingStates || isLoadingBranch || isLoadingEmployees || isEmlployeeTypeLoading || isEmlployeeTypeFetching || isEmlployeeTypeError || isLoadingDepartments || isFetchingStates || isFetchingBranch || isFetchingEmployees || isFetchingDepartments || isFetchingDesignation
    const isAnyError = isErrorDesignation || isEmlployeeTypeError || isErrorBranch|| isErrorStates || isErrorDepartments

    return (
        <ModalStandard
            open={props.show}
            onOpenChange={props.setShow}
        >
            <ModalStandardContent onOpenAutoFocus={(e) => e?.preventDefault()} className='bg-vstargray-100'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalStandardHeader >
                        <ModalStandardTitle >{props.mode === 'edit' ? 'Edit Employee' : 'Add Employee'}</ModalStandardTitle>
                        <ModalStandardDescription>
                            {/* loading indication */}
                            {(isAnythingLoadingorFetching) && <div className=' px-2 p-1 rounded-md bg-vstargray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>}
                            <form onSubmit={handleSubmit(onSubmit)} className=" pb-3">
                                <div className='grid gap-4 py-4 xs2:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 '>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='code'
                                            title='Employee Code'
                                            {...register("FM03_EmployeeCode", { required: "Employee code is required" })}
                                            error={errors.FM03_EmployeeCode?.message}
                                            disabled={isAnythingLoadingorFetching}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='name'
                                            title='Employee Name'
                                            {...register("FM03_EmployeeName", { required: "Name is required" })}
                                            error={errors.FM03_EmployeeName?.message}
                                            disabled={isAnythingLoadingorFetching}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            type="email"
                                            placeholder='email'
                                            title='Email'
                                            {...register("FM03_MailID", {
                                                required: "Email is required",
                                                pattern: {
                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, // email validation
                                                    message: "Invalid email address"
                                                }
                                            })}
                                            onChange={(e) => {
                                                setValue('FM03_MailID', e.target.value);
                                                setValue('FM03_Username', e.target.value)
                                            }}
                                            error={errors.FM03_MailID?.message}
                                            disabled={isAnythingLoadingorFetching}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='username'
                                            title='Username'
                                            {...register("FM03_Username", { required: "username is required" })}
                                            error={errors.FM03_Username?.message}

                                            disabled={true || isAnythingLoadingorFetching}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <SelectStandard
                                            title='Employee Type'
                                            {...register("FM03_EmployeeType", { required: "Employee type is required" })}
                                            error={errors.FM03_EmployeeType?.message}
                                            value={employee_type}
                                            options={employeeTypes?.map((employeeType) => ({ value: String(employeeType.id), label: employeeType.M18_Employee_type })) || []}
                                            placeHolder='employee type'
                                            disabled={isAnythingLoadingorFetching || isEmlployeeTypeLoading || isEmlployeeTypeFetching}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <SelectStandard
                                            title='Branch'
                                            disabled={isLoadingBranch || isAnythingLoadingorFetching}
                                            value={branch ? String(branch) : undefined}
                                            {...register("FM03_M02_BranchID", { required: "Branch is required" })}
                                            error={errors.FM03_M02_BranchID?.message}
                                            options={branches?.map((branch) => ({ value: String(branch.M02_BranchID), label: branch.M02_BranchName })) || []}
                                            placeHolder='branch'
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <SelectStandard
                                            title='Department'
                                            value={department ? String(department) : undefined}

                                            {...register("FM03_M05_Department", { required: "Department type is required" })}
                                            error={errors.FM03_M05_Department?.message}
                                            options={departments?.map((department) => ({ value: String(department.M17_Dept_id), label: department.M17_Dept_name })) || []}
                                            placeHolder='department'
                                            disabled={isAnythingLoadingorFetching}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <SelectStandard
                                            title='Designation'
                                            value={designation ? String(designation) : undefined}

                                            {...register("FM03_M05_DesignationID", { required: "Designation is required" })}
                                            error={errors.FM03_M05_Department?.message}
                                            options={designations?.map((designation) => ({ value: String(designation.FM05_DesignationID), label: designation.FM05_DesignationName })) || []}
                                            placeHolder='Designation'
                                            disabled={isAnythingLoadingorFetching}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <SelectStandard
                                            title='Reporting To'
                                            {...register("FM03_M03ReportingtoEmployeeID", { required: "Reporting to is required" })}
                                            error={errors.FM03_M03ReportingtoEmployeeID?.message}
                                            value={reporting_to ? String(reporting_to) : undefined}
                                            options={emloyees?.map((employee) => ({ value: String(employee.FM03_EmployeeID), label: employee.FM03_Username })) || []}
                                            placeHolder='reporting_to'
                                            disabled={isAnythingLoadingorFetching}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='mobile'
                                            title='Mobile'
                                            {...register("FM03_Mobile", {
                                                required: "Mobile is required",
                                                pattern: {
                                                    value: /^\d{10}$/, // 10 digit validation
                                                    message: "Invalid mobile number"
                                                }
                                            })}
                                            error={errors.FM03_Mobile?.message}
                                            disabled={isAnythingLoadingorFetching}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <MultiselectStandard
                                            title='State Mapping'
                                            {...register("mapping_state_id", { })}
                                            error={errors.mapping_state_id?.message}
                                            value={mapping_state_id ? mapping_state_id.map(item => String(item)) : undefined}
                                            options={states?.map((state) => ({ value: String(state.FM04_StateID), label: state.FM04_StateName })) || []}
                                            placeholder='State mapping'
                                            onValueChange={(e) => {
                                                setValue('mapping_state_id', e.target.value?.map(item=>Number(item)));
                                            }}
                                            disabled={isAnythingLoadingorFetching}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <SelectStandard
                                            title='Sales Roles'
                                            {...register("sales_role", {validate:(value)=>{
                                                if(!value && watch('mapping_state_id')?.length){
                                                    return 'Sales role is required'
                                                }
                                                else{
                                                    return true
                                                }
                                            }})}
                                            error={errors.sales_role?.message}
                                            value={sales_role ? String(sales_role) : undefined}
                                            options={[
                                                { label: 'RSM', value: 'RSM' },
                                                { label: 'ASM', value: 'ASM' },
                                                { label: 'SE', value: 'SE' },
                                            ]}
                                            placeHolder='sales role'
                                            disabled={isAnythingLoadingorFetching}
                                        />
                                    </div>

                                </div>
                                <div className=' flex flex-col items-center '>
                                    <div className=' flex flex-col gap-2 items-start'>
                                        <SwitchDemo
                                            value={allow_login===1}
                                            {...register("FM03_AllowLogin")}
                                            title={'Allow Login'}
                                            disabled={isAnythingLoadingorFetching}
                                        />


                                        <SwitchDemo
                                            value={status==1?true:false}
                                            {...register("FM03_Status")}
                                            title={'Status'}
                                            disabled={isAnythingLoadingorFetching}
                                        />

                                        {/* 
                                        <SwitchDemo
                                            control={control as unknown as Control<FieldValues, any>}
                                            {...register("is_active")}
                                            title={'Is active'}
                                            value={is_active}
                                            disabled={isReadingEmployee}
                                        /> */}


                                        {/* <SwitchDemo
                                            {...register("is_staff")}
                                            title={'Is Staff'}
                                            value={is_staff}
                                            disabled={isReadingEmployee}
                                        /> */}


                                        {/* <SwitchDemo
                                            {...register("is_superuser")}
                                            title={'Is Superuser'}
                                            value={is_superuser}
                                            disabled={isReadingEmployee}
                                        /> */}
                                    </div>

                                </div>
                            </form>
                        </ModalStandardDescription>
                    </ModalStandardHeader>
                    <ModalStandardFooter>
                        <ButtonIconSquare
                            variant='white'
                            icon={<IconX />}
                            text='close'
                            onClick={(e) => { e?.preventDefault(); props.setShow?.(false) }}
                            disabled={isSubmitting}
                        />
                        <ButtonIconSquare
                            variant='secondary'
                            icon={isSubmitting ? <IconLoader className=' animate-spin ' /> : props.mode === 'add' ? <IconPlus /> : <IconEdit />}
                            text={props.mode === 'add' ? 'Add' : 'Edit'}
                            type="submit"
                            disabled={isSubmitting || isLoadingBranch || isLoadingEmployees || isLoadingDepartments || isFetchingBranch || isFetchingEmployees || isFetchingDepartments}
                        />
                    </ModalStandardFooter>
                </form>
            </ModalStandardContent>
        </ModalStandard>
    )
}
