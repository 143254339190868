import { useMutation, useQuery } from "@tanstack/react-query"
import { useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"



export function useDistrictAPI({
    page_size,
    page,
    retryCount,
    trigger = true,
    FM10_M04_StateID
}: {
    page_size?: number,
    page?: number,
    retryCount?: number,
    trigger?: boolean,
    FM10_M04_StateID?: number
}) {

    const api = useRestAPI()
    const [totalDistrictCount, setTotalDistrictCount] = useState(0)

    const {
        isLoading,
        isError,
        data: districts,
        refetch: refetchDistricts,
        isFetching,
        isFetched,
        error
    } = useQuery({
        queryKey: [QUERY_KEY, page_size, page, FM10_M04_StateID],
        queryFn: async () => {
            const response = await api.get<{ data: DistrictData[] }&backendPagination>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/districts/`,
                mode: 'protected',
                params: {
                    page_size,
                    page,
                    FM10_M04_StateID
                }
            })
            if (response.success === false) {
                throw response.message
            }

            setTotalDistrictCount(response.data?.length||0)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger
        
    })

    return {
        isLoading,
        isError,
        districts,
        refetchDistricts,
        totalDistrictCount,
        isFetching,
        isFetched,
        error
    }

}

type DistrictData = {
    FM10_DistrictID: number,
    FM10_M04_StateID: {
        id: number,
        name: string
    },
    FM10_DistrictName: string,
    FM10_IsActive: boolean
}

const QUERY_KEY = 'districts'


