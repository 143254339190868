import { IconEdit, IconLoader, IconPlus, IconX } from '@tabler/icons-react'
import React, { useEffect } from 'react'
import { Control, FieldValues, set, useForm } from 'react-hook-form'
import { useBranchAPI } from 'src/API/rest.branch'
import { useProductCategoryAPI } from 'src/API/rest.product_category'
import { useDepartmentAPI } from 'src/API/rest.department'
import { useProductAPI } from 'src/API/rest.products'
import { ButtonIconSquare } from 'src/components/buttons'
import { InputStandard } from 'src/components/standard.input'
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle, ModalStandardTrigger } from 'src/components/standard.modal'
import { SelectStandard } from 'src/components/standard.select'
import { SwitchDemo } from 'src/components/standard.switch'
import { Input } from 'src/shadcn/ui/input'
import { Label } from 'src/shadcn/ui/label'
import { DialogFooter } from 'src/shadcn/ui/react-dialog'
import { toast } from 'src/shadcn/ui/use-toast'
import { useProductSubCategoryAPI } from 'src/API/rest.product_sub_category'

type Props = {

    show?: boolean,
    setShow?: (show: boolean) => void,
    refetchProducts: () => void,
    mode: 'add' | 'edit',
    productId?: number | string | null
}
type ProductData = {
    id?: number,
    product_code: string | null,
    product_description: string | null,
    stock_category: string | null,
    new_product_flag: number,
    status: boolean,
    status_date: string,
    product_sub_category: string | null,
    Product_category: string | null
}

export function ModalAddProduct(props: Props) {

    const { register, handleSubmit, setValue, reset, formState: { errors }, control, watch } = useForm<ProductData>({
        defaultValues: {
            product_code: null,
            product_description: '',
            stock_category: '',
            new_product_flag: 0,
            status: true,
            status_date: '',
            product_sub_category: '',
            Product_category: ''
        }
    });

    

    const selectedCategoryId = watch('Product_category')
    const selectedSubCategoryId = watch('product_sub_category')

    const {
        productCategories,
        isLoading: isProductCategoryLoading,
        isFetching: isProductCategoryFetching,
    } = useProductCategoryAPI({
        trigger: props.show
    })

    const selectedCategory = productCategories?.find((category) => category.FM12_ProductCategoryID == selectedCategoryId as any)


    const [isSubmitting, setIsSubmitting] = React.useState(false);


    
    const {
        productCategories: productSubCategories,
        isLoading: isLoadingProductSubCategories,
        isFetching: isFetchingProductSubCategories,
        refetchProductCategories: refetchProductSubCategories
    } = useProductCategoryAPI({
        page_size: 100,
        trigger: props.show && selectedCategoryId ? true : false,
        FM12_ProductCategory: selectedCategory?.FM12_ProductCategory
    })

    const selectedSubCategories = productSubCategories?.find((category) => category.FM12_ProductCategoryID == selectedSubCategoryId as any)


    const {
        readProductbyId,
        createProduct,
        updateProduct,
        isReadingProduct,
        isFetching: isProductFetching
    } = useProductAPI({})


    useEffect(
        () => {
            console.log(props.show)
            if (props.show && props.mode === 'add') {
                reset({
                    product_code: null,
                    product_description: '',
                    stock_category: '',
                    new_product_flag: 0,
                    status: true,
                    status_date: '',
                    product_sub_category: '',
                    Product_category: ''
                })
            }
            if (props.mode === 'edit' && props.productId && props.show) {
                const getProductById = async () => {
                    if (!props.productId) {
                        return
                    }
                    const response = await readProductbyId({ productId: props.productId })
                    if (response.success === true) {
                        reset({
                            id: response.data.FM13_ProductID,
                            product_code: response.data.FM13_ProductCode,
                            product_description: response.data.FM13_ProductDescription,
                            stock_category: response.data.FM13_StockCategory,
                            new_product_flag: response.data.FM13_NewProductFlag||1,
                            status: response.data.FM13_Status?true:false,
                            status_date: response.data.FM13_StatusDate!,
                            product_sub_category: response.data?.FM13_M12_ProductSubCategory?.id?String(response.data?.FM13_M12_ProductSubCategory?.id):'',
                            Product_category: response.data?.FM13_M12_ProductCategory?.id?String(response.data?.FM13_M12_ProductCategory?.id):'',

                        })
                        await refetchProductSubCategories()
                        setValue('product_sub_category', String(response.data.FM13_M12_ProductSubCategory.id))
                    } else {
                        toast({
                            description: response.message,
                            title: "error",
                            // variant:''
                        })
                    }
                }
                getProductById()
            }
        }, [props.show]
    )

    const onSubmit = async (data: ProductData) => {

        setIsSubmitting(true)
        if (props.mode === 'add') {
            const response = await createProduct({
                FM13_NewProductFlag:data.new_product_flag,
                FM13_Status:data.status?1:0,
                FM13_StatusDate:null,
                FM13_M12_ProductCategory:data.Product_category!,
                FM13_M12_ProductSubCategory:data.product_sub_category!,
                FM13_ProductCode:data.product_code,
                FM13_ProductDescription:data.product_description,
                FM13_StockCategory:data.stock_category
            })
            if (response.success === true) {
                toast({
                    description: response.message,
                    // variant:''
                })
                props.setShow?.(false);
                props.refetchProducts()
            } else {
                toast({
                    description: response.message,
                    title: "error",
                    // variant:''
                })
            }
        } else if (props.mode === 'edit' && props.productId) {
            const response = await updateProduct({
                id: props.productId,
                Product: {FM13_NewProductFlag:data.new_product_flag,
                    FM13_Status:data.status?1:0,
                    FM13_StatusDate:null,
                    FM13_M12_ProductCategory:data.Product_category!,
                    FM13_M12_ProductSubCategory:data.product_sub_category!,
                    FM13_ProductCode:data.product_code,
                    FM13_ProductDescription:data.product_description,
                    FM13_StockCategory:data.stock_category
                }
            })
            if (response.success === true) {
                toast({
                    description: response.message,
                    // variant:''
                })
                props.setShow?.(false);
                props.refetchProducts()
            } else {
                toast({
                    description: response.message,
                    title: "error",
                    // variant:''
                })
            }
        }
        setIsSubmitting(false)
    };

    const isAnyThingLoading = isReadingProduct || isProductCategoryLoading ||isLoadingProductSubCategories || isFetchingProductSubCategories || isProductCategoryFetching  || isProductFetching
    const productCategory = watch('Product_category')
    const productStockCategory = watch('stock_category')
    const productSubCategory = watch('product_sub_category')

    return (
        <ModalStandard
            open={props.show}
            onOpenChange={props.setShow}
        >
            <ModalStandardContent onOpenAutoFocus={(e) => e?.preventDefault()} className='bg-vstargray-100'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalStandardHeader >
                        <ModalStandardTitle >{props.mode === 'edit' ? 'Edit Product' : 'Add Product'}</ModalStandardTitle>
                        <ModalStandardDescription>
                            {/* loading indication */}
                            {isAnyThingLoading && <div className=' px-2 p-1 rounded-md bg-vstargray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>}
                            <form onSubmit={handleSubmit(onSubmit)} className=" pb-3">
                                <div className='grid gap-4 py-4 xs2:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 '>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='code'
                                            title='Product Code'
                                            {...register("product_code", { required: "Product code is required" })}
                                            error={errors.product_code?.message}
                                            disabled={isAnyThingLoading}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='description'
                                            title='Product Description'
                                            {...register("product_description", { required: "Product description is required" })}
                                            error={errors.product_description?.message}
                                            disabled={isAnyThingLoading}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <SelectStandard
                                            title='Stock Category'
                                            {...register("stock_category", { required: "stock category is required" })}
                                            error={errors.stock_category?.message}
                                            disabled={isAnyThingLoading}
                                            value={productStockCategory?productStockCategory:undefined}
                                            options={[
                                                { value: 'Raw Material', label: 'Raw Material' },
                                                { value: 'F ', label: 'Finished Goods' },
                                                { value: 'Semi Finished Goods', label: 'Semi Finished Goods' },
                                                { value: 'Scrap', label: 'Scrap' },
                                                { value: 'Scrap', label: 'Scrap' },
                                            ]}
                                        />
                                    </div>


                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <SelectStandard
                                            placeHolder='product category'
                                            options={productCategories?.filter(category=>!category.FM12_ProductSubCategory).map((category) => ({ value: String(category.FM12_ProductCategoryID), label: category.FM12_ProductCategory })) || []}
                                            title='product category'
                                            {...register("Product_category", { required: "product category is required" })}
                                            onChange={(e)=>{
                                                console.log('first')
                                                setValue('Product_category',e.target.value)
                                                setValue('product_sub_category',null)
                                            }}
                                            error={errors.Product_category?.message}
                                            disabled={isAnyThingLoading}
                                            value={productCategory?productCategory:undefined}
                                        />

                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <SelectStandard
                                            placeHolder='product sub category'
                                            options={productSubCategories?.map((productCategory) => ({ label: productCategory.FM12_ProductSubCategory!, value: String(productCategory.FM12_ProductCategoryID) })).filter(categoey => categoey.label) || []}
                                            title='Product Sub Category'
                                            {...register("product_sub_category", { required: "product sub category is required" })}
                                            error={errors.product_sub_category?.message}
                                            disabled={isAnyThingLoading||!selectedCategoryId}
                                            value={productSubCategory?productSubCategory:undefined}
                                            // defaultValue={productSubCategory?productSubCategory:undefined}
                                        />
                                    </div>

                                </div>
                                <div className=' flex flex-col items-center '>
                                    <div className=' flex flex-col gap-2 items-start'>
                                        <SwitchDemo
                                            {...register("status")}
                                            title={'Status'}
                                            value={watch('status')}
                                            disabled={isAnyThingLoading}
                                        />

                                        <div className=" flex flex-col gap-2 items-start">
                                            <SwitchDemo
                                                {...register("new_product_flag")}
                                                title={'New Product Flag'}
                                                value={watch('new_product_flag')==1 ? true : false}
                                                onChange={(e) => { setValue('new_product_flag', e.target.value ? 1 : 0) }}
                                                disabled={isAnyThingLoading}
                                            />


                                        </div>
                                    </div>

                                </div>
                            </form>
                        </ModalStandardDescription>
                    </ModalStandardHeader>
                    <ModalStandardFooter>
                        <ButtonIconSquare
                            variant='white'
                            icon={<IconX />}
                            text='close'
                            onClick={(e) => { e?.preventDefault(); props.setShow?.(false) }}
                            disabled={isSubmitting}
                        />
                        <ButtonIconSquare
                            variant='secondary'
                            icon={isSubmitting ? <IconLoader className=' animate-spin ' /> : props.mode === 'add' ? <IconPlus /> : <IconEdit />}
                            text={props.mode === 'add' ? 'Add' : 'Edit'}
                            type="submit"
                            disabled={isSubmitting}
                        />
                    </ModalStandardFooter>
                </form>
            </ModalStandardContent>
        </ModalStandard>
    )
}


