import { IconCloudUpload, IconLoader2 } from '@tabler/icons-react'
import { useState } from 'react'
import { useEmployeeAPI } from 'src/API/rest.employee'
import { useExportForcast } from 'src/API/rest.ExportForecast '
import { useGetLatestCalendarDate } from 'src/API/rest.forecast_calender'
import { MonthCode } from 'src/API/rest.forecast_regionwise'
import { useStatesAPI } from 'src/API/rest.states'
import { ButtonIconSquare } from 'src/components/buttons'
import { ErrorBoxStandard } from 'src/components/standard.errorbox'
import { FileInputDragDropStandard } from 'src/components/standard.fileDroper'
import { InputStandard } from 'src/components/standard.input'
import { SelectStandard } from 'src/components/standard.select'
import { TypographyH2 } from 'src/components/typography'
import { toast } from 'src/shadcn/ui/use-toast'


type MonthType = {
    month: string;
    value: string;
}

export function ExportForecastPage() {
    const [isOpen, setIsOpen] = useState(false)
    const currentDate = new Date();
    const [selectedDate, setSelectedDate] = useState(
        currentDate.toISOString().substring(0, 7) // Format: YYYY-MM
    );
    const [seletedFile, setSelectedFile] = useState<File | null>(null)


    const {
        isErrorlatestCalendarDate,
        isFetchedlatestCalendarDate,
        isFetchinglatestCalendarDate,
        isLoadinglatestCalendarDate,
        latestCalendarDate,
        refetchlatestCalendarDate
    } = useGetLatestCalendarDate({
        trigger: true
    })

    const {
        downloadTemplateExportforecast,
        isDownloadExportforcastTemplateError,
        isDownloadingTEmplateExportforecast,
        validateExportforecastExel,
        isvalidateExportforecastExelError,
        isvalidateingExportforecastExel,
        uploadExportforecastExel,
        uploadProgress,
        isuploadingExportforecastExel
    } = useExportForcast({})

    const {
        states,
        isError: isStateError,
        isFetching: isFetchingState,
        isLoading: isLoadingState
    } = useStatesAPI({
        trigger: true
    })



    const isAnyError = isStateError || isErrorlatestCalendarDate
    const isAnyFething = isFetchinglatestCalendarDate || isFetchingState
    const isLoading = isLoadingState || isLoadinglatestCalendarDate




    if (isErrorlatestCalendarDate && !isFetchinglatestCalendarDate) {
        return (
            <div className=' grow flex flex-col items-center '>
                <ErrorBoxStandard
                    onRetry={refetchlatestCalendarDate}
                />
            </div>
        )
    }

    return (
        <div className=' grow  flex flex-col overflow-auto items-center '>


            {/* NP style wise */}
            <div className=' grow flex flex-col overflow-hidden bg-white dark:bg-vstargray-700 border rounded-md w-full text-center'>
                <div className=' grow flex flex-col overflow-auto p-2 '>
                    <TypographyH2>Export Forecast</TypographyH2>
                    <div className=' flex gap-2 '>
                        <InputStandard
                            title='Date'
                            placeholder='Date'
                            type='date'
                            disabled
                            value={latestCalendarDate?.date || ''}
                        />

                    </div>
                    <div className=' grow flex flex-col justify-center items-center  w-min mx-auto mt-8'>
                        <div className='  text-center flex text-vstargray-800 dark:text-vstargray-100 py-2'>Download Template {isDownloadingTEmplateExportforecast ? <IconLoader2 size={10} className=' inline animate-spin' /> : <span onClick={() => downloadTemplateExportforecast()} className=' underline text-blue-500 cursor-pointer hover:text-blue-700'>here</span>}</div>
                        <FileInputDragDropStandard
                            disabled={!latestCalendarDate?.date ? true : false}
                            onDrop={async (file) => {
                                const response = await validateExportforecastExel({
                                    file: file!
                                })
                                if (response.success === false) {
                                    toast({
                                        description: response.message
                                    })
                                } else {
                                    setSelectedFile(file);
                                }
                            }}
                            file={seletedFile}
                            onClear={() => setSelectedFile(null)}
                            accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] }}
                        />
                    </div>
                    <ButtonIconSquare
                        disabled={seletedFile === null}
                        className=' mx-auto mb-5'
                        icon={isuploadingExportforecastExel ? <IconLoader2 className='animate-spin' /> : <IconCloudUpload />}
                        onClick={async () => {
                            const response = await uploadExportforecastExel({
                                date: latestCalendarDate?.date!,
                                file: seletedFile!,
                            })
                            if (response.success === false) {
                                toast({
                                    title: 'Error',
                                    description: response.message
                                })
                            } else {
                                toast({
                                    title: 'Success',
                                    description: response.message
                                })
                                setSelectedFile(null)
                            }
                        }}
                        variant='secondary'
                        text='Submit'
                    />
                </div>
            </div>

        </div>
    )
}

