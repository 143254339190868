import { useMutation, useQuery } from "@tanstack/react-query"
import { useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"



export function useLocationAPI({
    page_size,
    page,
    retryCount,
    trigger = true
}: {
    page_size?: number,
    page?: number,
    retryCount?: number,
    trigger?: boolean
}) {

    const api = useRestAPI()
    const [totalLocationCount, setTotalLocationCount] = useState(0)

    const {
        isLoading,
        isError,
        data: locations,
        refetch: refetchLocations,
        isFetching,
        isFetched,
        error
    } = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: async () => {
            const response = await api.get<{ data: LocationData[] }&backendPagination>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/locations/`,
                mode: 'protected',
                params: {
                    page_size,
                    page
                }
            })
            if (response.success === false) {
                throw response.message
            }

            setTotalLocationCount(response.data?.length||0)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger
        
    })

    return {
        isLoading,
        isError,
        locations,
        refetchLocations,
        totalLocationCount,
        isFetching,
        isFetched,
        error
    }

}

type LocationData = {
    FM09_LocationID: number,
    FM09_LocationName: string,
    FM09_IsActive: number
}

const QUERY_KEY = 'locations'

