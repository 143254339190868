import { useMutation, useQuery } from "@tanstack/react-query"
import { useRestAPI } from "./axiosAPI"


export type RoleMenuType = {
    id: number,
    menu_display_name: string,
    menu_program_id: string,
    parent_id: number | null,
    permissions: string[],
    status: boolean,
    status_date: string | null,
    created_date: string,
    updated_date: string
}

export type RoleMasterListData = {
    id?: number,
    role_name: string,
    menus:RoleMenuType[],
    users: {
        FM03_EmployeeID: number,
        FM03_EmployeeCode: string,
        FM03_EmployeeName: string,
        FM03_EmployeeType: number,
        FM03_M02_BranchID: number,
        FM03_M05_Department: number,
        FM03_M05_DesignationID: number,
        FM03_Mobile: string,
        FM03_MailID: string,
        FM03_Username: string,
        FM03_M03ReportingtoEmployeeID: number,
        FM03_AllowLogin: number,
        FM03_ReportSortOrder: number,
        FM03_Status: number,
        FM03_StatusDate: string | null,
        is_active: boolean,
        is_staff: boolean,
        is_superuser: boolean,
    }[]
}


export function useMenuGetAll({
    retryCount,
    trigger = true,
}: {
    retryCount?: number,
    trigger?: boolean,
}) {

    const api = useRestAPI()
    // const [deleteRetryCount,setDeleteRetryCount] = useState(0)

    const {
        isLoading,
        isError: isErrorRoleList,
        data: rolesList,
        refetch: refetchRolesList,
        isFetching: isFetchingRoleList,
        isFetched: isFetchedRoleList,
        error: errorRoleList,
        isRefetchError:isRefetchRoleListError,
        isFetchedAfterMount
    } = useQuery({
        queryKey: ['role-menu'],
        queryFn: async () => {
            const response = await api.get< {data:RoleMenuType[]}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/roles/menus/`,
                mode: 'protected',
            })
            if (response.success === false) {
                throw response.message
            }

            return response
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger,


    })







    return {
        isLoading,
        isErrorRoleList,
        rolesList,
        refetchRolesList,
        isFetchingRoleList,
        isFetchedRoleList,
        errorRoleList,
        isRefetchRoleListError,
        isFetchedAfterMount,
    }


}


export function useRoleMasterGetAll({
    retryCount,
    trigger = true,
}: {
    retryCount?: number,
    trigger?: boolean,
}) {

    const api = useRestAPI()
    // const [deleteRetryCount,setDeleteRetryCount] = useState(0)

    const {
        isLoading:isLoadingRoleMasterList,
        isError: isErrorRoleMasterList,
        data: rolesMasterList,
        refetch: refetchRolesMasterList,
        isFetching: isFetchingRoleMasterList,
        isFetched: isFetchedRoleMasterList,
        error: errorRoleMasterList,
        isRefetchError:isRefetchRoleMasterListError,
        isFetchedAfterMount
    } = useQuery({
        queryKey: ['role-master'],
        queryFn: async () => {
            const response = await api.get< {data:RoleMasterListData[]}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/roles/roles/`,
                mode: 'protected',
            })
            if (response.success === false) {
                throw response.message
            }

            return response
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger,


    })







    return {
        isLoadingRoleMasterList,
        isErrorRoleMasterList,
        rolesMasterList,
        refetchRolesMasterList,
        isFetchingRoleMasterList,
        isFetchedRoleMasterList,
        errorRoleMasterList,
        isRefetchRoleMasterListError,
        isFetchedAfterMount,
    }


}


export type CreateRoleData = {
    role_name: string,
    menus: number[],
    users?: number[]
}

export const useRoleApi = ()=>{

    const api = useRestAPI()


    const { mutateAsync: createRole, isPending: isCreatingRole, failureCount: createRoleFailureCount, isError: isErrorCreatingRole } = useMutation({
        mutationFn: async (roleData:CreateRoleData) => {
            const response = await api.post<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/roles/roles/`,
                data: roleData,
                mode: 'protected'
            })

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && createRoleFailureCount < 4) {

                throw response
            }

            return response
        },
        retry:4
    })
    const { mutateAsync: deleteRoleMaster, isPending: isdeleteRoleMaster, failureCount: deleteRoleMasterFailureCount, isError: isErrordeleteRoleMaster } = useMutation({
        mutationFn: async ({
            id
        }:{
            id:string
        }) => {
            const response = await api.delete<{}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/roles/roles/${id}/`,
                mode: 'protected'
            })

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && deleteRoleMasterFailureCount < 4) {

                throw response
            }

            return response
        },
        retry:4
    })

    const { mutateAsync: readRoleMasterbyId, isPending: isReadingreadRoleMasterbyId, failureCount: readRoleMasterFailureCount, isError: isErrorreadRoleMasterbyId } = useMutation({
        mutationFn: async ({ roleMasterId }: { roleMasterId: string | number }) => {

            const response = await api.get<{ data: RoleMasterListData }>({
                url: `${process.env.REACT_APP_BACKEND_URL}/roles/roles/${roleMasterId}/`,
                mode: 'protected'
            })

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && readRoleMasterFailureCount < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }

            return response
        },
        retry: 4,

    })

    const { mutateAsync: updateRoleMaster, isPending: isUpdatingRoleMaster, failureCount: updateRoleMasterFailureCount } = useMutation({
        mutationFn: async ({
            id,
            RoleMaster
        }: {
            id: string | number,
            RoleMaster: CreateRoleData
        }) => {
            const response = await api.put<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/roles/roles/${id}/`,
                data: RoleMaster,
                mode: 'protected'
            })

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && updateRoleMasterFailureCount < 4) {

                throw response
            }

            return response
        }, retry: 4
    })


    return {
        createRole,
        isCreatingRole,
        isErrorCreatingRole,
        deleteRoleMaster,
        isdeleteRoleMaster,
        isErrordeleteRoleMaster,
        readRoleMasterbyId,
        isReadingreadRoleMasterbyId,
        isErrorreadRoleMasterbyId,
        updateRoleMaster,
        isUpdatingRoleMaster
    }


}



export function useGetAllowedMenus({
    retryCount,
    trigger = true,
    menuIds=[]
}: {
    retryCount?: number,
    trigger?: boolean,
    menuIds:string[]
}) {

    const api = useRestAPI()
    // const [deleteRetryCount,setDeleteRetryCount] = useState(0)

    const {
        isLoading:isLoadingGetAllowdMenus,
        isError: isErrorGetAllowdMenus,
        data: rolesAllowedMenus,
        refetch: refetchRolesAllowedMenus,
        isFetching: isFetchingGetAllowdMenus,
        isFetched: isFetchedGetAllowdMenus,
        error: errorGetAllowdMenus,
        isRefetchError:isRefetchGetAllowdMenusError,
        isFetchedAfterMount
    } = useQuery({
        queryKey: ['role-allowed-menus',...menuIds],
        queryFn: async () => {
            const response = await api.post< {data:string[]},{}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/roles/allowed-menu-program-ids/`,
                mode: 'protected',
                data:{
                    menuIds:menuIds
                }
            })
            if (response.success === false) {
                throw response.message
            }

            return response
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger,
    })







    return {
        isLoadingGetAllowdMenus,
        isErrorGetAllowdMenus,
        rolesAllowedMenus,
        refetchRolesAllowedMenus,
        isFetchingGetAllowdMenus,
        isFetchedGetAllowdMenus,
        errorGetAllowdMenus,
        isRefetchGetAllowdMenusError,
        isFetchedAfterMount
    }


}