import { IconAlertTriangleFilled } from "@tabler/icons-react";
import { ButtonIconSquare } from "./buttons";

export interface ErrorBoxProps {
  onRetry: () => void;
}

export function ErrorBoxStandard({ onRetry }: ErrorBoxProps) {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <IconAlertTriangleFilled size={100} className="text-red-500 text-5xl mb-4" />
      <p className="text-vstargray-800 text-lg mb-4 dark:text-vstargray-200 ">Error Displaying Datas</p>
      <ButtonIconSquare
        text='Retry'
        onClick={onRetry}
      />
    </div>
  );
}
