import { useState } from "react"
import { useRestAPI } from "./axiosAPI"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { backendPagination } from "src/types/pagination"

export type ReferenceMonthData = {
    FT17_Id?: number,
    FT17_month_name: string,
    FT17_date: string,
    FT17_type: string,
    FT17_status: number,
    FT17_forecast_month: string
}

const QUERY_KEY = 'referenceMonth'
export function useReferenceMonthAPI({
    retryCount,
    trigger = true,
    forcastMonth
}: {
    retryCount?: number,
    trigger?: boolean,
    forcastMonth?: string
}) {

    const api = useRestAPI()
    const [totalReferenceMonthCount, setTotalReferenceMonthCount] = useState(0)
    const queryclient = useQueryClient()

    const {
        isLoading,
        isError,
        data: referenceMonths,
        refetch: refetchReferenceMonths,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount
    } = useQuery({
        queryKey: [QUERY_KEY,forcastMonth],
        queryFn: async ():Promise<ReferenceMonthData[]> => {
            const response = await api.get<{ data: ReferenceMonthData[] }>({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/reference-month/`,
                mode: 'protected',
                params:{
                    FT17_forecast_month:forcastMonth
                }
            })
            if (response.success === false) {
                throw response.message
            }

            return response.data




        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger,
    })

    const { mutateAsync: updateReferenceMonth, isPending: isUpdateReferenceMonthPending, failureCount: updateReferenceMonthFailure, isError:isUpdateReferenceMonthError } = useMutation({
        mutationFn: async ({
            referenceMonth
        }: {
            referenceMonth: ReferenceMonthData[]
        }) => {


            // const response:{success: boolean, message: string} = await new Promise((resolve, reject) => {
            //     setTimeout(()=>{
            //         resolve({
            //             success: true,
            //             message:'successfully updated reference month',
            //         })
            //     },1000)
            // });
            // return response

            const response = await api.put<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/reference-month/`,
                data: referenceMonth,
                mode: 'protected'
            })

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && updateReferenceMonthFailure < 4) {

                throw response
            }

            return response
        }, retry: 4
    })

    const { mutateAsync: createReferenceMonth, isPending: isCreateReferenceMonthPending, failureCount: createReferenceMonthFailure,isError:isCreateReferenceMonthError } = useMutation({
        mutationFn: async ({
            referenceMonth
        }: {
            referenceMonth: ReferenceMonthData[]
        }):Promise<{success: boolean, message: string}> => {


            const response = await api.post<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/reference-month/`,
                data: referenceMonth,
                mode: 'protected'
            })

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && createReferenceMonthFailure < 4) {

                throw response
            }

            return response

        }, retry: 4
    })


    return {
        isLoading,
        isError,
        referenceMonths,
        refetchReferenceMonths,
        totalReferenceMonthCount,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount,
        updateReferenceMonth,
        isUpdateReferenceMonthPending,
        createReferenceMonth,
        isCreateReferenceMonthPending,
        isCreateReferenceMonthError,
        isUpdateReferenceMonthError
    }


}


