import { useGetEmployeeDetailsByToken } from "src/API/rest.employee";
import {ReactComponent as VstarLogo} from './vstarLogo.svg';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { setEmployeeDetails } from "src/redux/user.slice/user.slice";

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {

    const employeeReducer = useSelector((state: RootState) => state.user.employeeDetails);
    const dispatch = useDispatch()
    const {
        employeeDetails,
        isErrorEmployeeDetails,
        isFetchingEmployeeDetails,
        isLoaingEmployeeDetails
    } = useGetEmployeeDetailsByToken({trigger:true});

    if(isErrorEmployeeDetails){
        return <div className=" fixed inset-0 flex items-center justify-center"><VstarLogo className=" mx-auto animate-pulse" /></div>
    }

    if(!isErrorEmployeeDetails && !isLoaingEmployeeDetails && !isFetchingEmployeeDetails){
        dispatch(setEmployeeDetails(employeeDetails?.data!))
    }

    return <>{children}</>;
}