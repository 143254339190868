import { IconChartBar } from '@tabler/icons-react'
import React from 'react'


function ReportComponent() {
    return (
        <div className=' bg-vstargray-100 dark:bg-vstargray-100/40 w-full  rounded-2xl items-center flex p-4 flex-wrap-reverse justify-center gap-1 '>
            <div className=' grow text-center'>
                <div className=' font-bold text-xs dark:text-vstargray-100'>Today's Money</div>
                <div><span className=' text-vstargray-700 font-bold dark:text-vstargray-100'>$2,300</span><span className=' text-green-500 text-xs font-bold'>+55%</span></div>
            </div>
            <div className={`bg-vstarPrimary-500 w-[45px] h-[45px]  min-w-[45px] min-h-[45px] text-white rounded-[13px] flex items-center justify-center overflow-hidden`}><IconChartBar /></div>

        </div>
    )
}


export default function SmallReport() {
    return (
        <div className='grid xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4'>
            <ReportComponent />
            <ReportComponent />
            <ReportComponent />
            <ReportComponent />
        </div>
    )
}
