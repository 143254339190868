import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { AxiosResponseCustom, useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"
import { AxiosProgressEvent } from "axios"

export type SkuDataResponse = {
    FM15_SKUID?: number,
    FM15_SKUCode: string,
    FM15_EANCode: string,
    FM15_SKUName: string,
    FM15_M14_SubAttributeIDStyle: number,
    FM15_StockCategory: string,
    FM15_UOM: string,
    FM15_M13_ProductID: number,
    FM14_M12_ProductSubCategory: number,
    FM14_M12_ProductCategory: number,
    FM15_NewSKUFlag: number,
    FM15_ASP:  number|null,
    FM15_MRP:  number|null,
    FM15_Status: number,
    FM15_StatusDate: string|null
}
export type SkuDataResponseExtended = {
    FM15_SKUID?: number,
    FM15_M14_SubAttributeIDStyle?: {
        id: number,
        name: string
    },
    FM15_M13_ProductID?: {
        id: number,
        name: string
    },
    FM14_M12_ProductSubCategory?: {
        id: number,
        name: string
    },
    FM14_M12_ProductCategory?: {
        id: number,
        name: string
    },
    FM15_SKUCode: string,
    FM15_EANCode: string,
    FM15_SKUName: string,
    StockCategory: string,
    FM15_UOM: string,
    FM15_NewSKUFlag: number,
    FM15_ASP: number,
    FM15_MRP: number,
    FM15_Status: number,
    FM15_StatusDate: string|null
}

const QUERY_KEY = 'skus'
export function useSkuAPI({
    page_size,
    page,
    retryCount,
    trigger = true,
    search
}: {
    page_size?: number,
    page?: number,
    retryCount?: number,
    trigger?: boolean,
    search?: string
}) {

    const api = useRestAPI()
    const [totalSkuCount, setTotalSkuCount] = useState(0)
    const queryclient = useQueryClient()
    // const [deleteRetryCount,setDeleteRetryCount] = useState(0)

    const {
        isLoading,
        isError,
        data: skus,
        refetch: refetchSkus,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount
    } = useQuery({
        queryKey: [QUERY_KEY, page_size, page, search],
        queryFn: async () => {
            const response = await api.get<{ data: SkuDataResponseExtended[] } & backendPagination>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/skus/`,
                mode: 'protected',
                params: {
                    page_size,
                    page,
                    search
                }
            })
            if (response.success === false) {
                throw response.message
            }

            setTotalSkuCount(response.count)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger,


    });


    const { mutateAsync: createSku,isPending:isCreatingSku,failureCount:createfailureCount } = useMutation({
        mutationFn: async (Sku: SkuDataResponse) => {
            const response = await api.post<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/skus/`,
                data: Sku,
                mode: 'protected'
            });

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&createfailureCount<4){
                
                throw response
            }

            return response
        },
        retry:4
    })
    const { mutateAsync: uploadSkuExcel,isPending:isUploadingSku,failureCount:uploadSkuFailure,isError:isUploadingSkuError } = useMutation({
        mutationFn: async ({
            excelFile,
            onUploadProgress
        }:{
            excelFile:File,
            onUploadProgress?:((progressEvent: AxiosProgressEvent) => void)
        }) => {
            const formData = new FormData();
            formData.append('file', excelFile);

            const response = await api.post<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/sample/`,
                data: formData,
                mode: 'protected',
                onUploadProgress: onUploadProgress
            });

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&uploadSkuFailure<4){
                
                throw response
            }

            return response
        },
        retry:4
    })

    const { mutateAsync: updateSku,isPending:isUpdatingSku  ,failureCount:updateFailuirCount } = useMutation({
        mutationFn: async ({
            id, 
            Sku
        }:{
            id: string | number,
            Sku: SkuDataResponse
        }) => {
            const response = await api.patch<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/skus/${id}/`,
                data: Sku,
                mode: 'protected'
            })

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&updateFailuirCount<4){
                
                throw response
            }

            return response
        },retry:4
    })

    const { mutateAsync: deleteSkuById ,isPending:isDeleting,failureCount} = useMutation({
        mutationFn: async ({skuId}: {skuId:string|number}) => {
            
            const response = await api.delete<{}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/skus/${skuId}/`,
                mode: 'protected'
            })

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&failureCount<4){
                
                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }

            return response 
        },
        retry:4,
        
    })
    const { mutateAsync: readSkuById ,isPending:isReadingSku,failureCount:empReadFailureCount} = useMutation({
        mutationFn: async ({skuId}: {skuId:string|number}) => {
            
            const response = await api.get<{data:SkuDataResponseExtended}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/skus/${skuId}/`,
                mode: 'protected'
            })

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&empReadFailureCount<4){
                
                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }

            return response 
        },
        retry:4,
        
    })

   


    return {
        isLoading,
        isError,
        skus,
        refetchSkus,
        totalSkuCount,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount,
        isDeleting,
        createSku,
        deleteSkuById,
        readSkuById,
        isReadingSku,
        updateSku,
        isUpdatingSku,
        uploadSkuExcel,
        isUploadingSku,
        isUploadingSkuError
    }


}


