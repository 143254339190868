import { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot } from "../shadcn/ui/input-otp";
import React, { forwardRef } from 'react';

type Props = {
  onChange: (e: string) => any,
  value?: string
}

export const InputOTPDemo = forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <InputOTP ref={ref} onChange={props.onChange} value={props.value} maxLength={6}>
      <InputOTPGroup>
        <InputOTPSlot ref={ref} className="bg-[#ddafaf]" index={0} />
        <InputOTPSlot className="bg-[#ddafaf]" index={1} />
        <InputOTPSlot className="bg-[#ddafaf]" index={2} />
      </InputOTPGroup>
      <InputOTPSeparator className="text-red-30" />
      <InputOTPGroup>
        <InputOTPSlot className="bg-[#ddafaf]" index={3} />
        <InputOTPSlot className="bg-[#ddafaf]" index={4} />
        <InputOTPSlot className="bg-[#ddafaf]" index={5} />
      </InputOTPGroup>
    </InputOTP>
  )
});