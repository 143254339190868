import { IconEdit, IconLoader2, IconPlus } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { EmployeeDataResponse, useEmployeeAPI, useEmployeeASMsUnderRSMsAPI, useEmployeeSEsUnderASMsAPI } from 'src/API/rest.employee'
import { ForecastLineType, MonthCode } from 'src/API/rest.forecast_regionwise'
import { useLoadStatesFromStateMappingAPI, useStatesAPI } from 'src/API/rest.states'
import { ButtonIconSquare } from 'src/components/buttons'
import { InputStandard } from 'src/components/standard.input'
import { SelectStandard } from 'src/components/standard.select'
import { StandardTable, StandardTableHeader, StandardTableRow, TablePlater } from 'src/components/standard.table'
import { TypographyH2, TypographyH3 } from 'src/components/typography'
import { ModalAddSEAllotment } from './component.modalForecast'
import { useForecastAPI } from 'src/API/rest.forecast'
import { useGetLatestCalendarDate } from 'src/API/rest.forecast_calender'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'





const headers: StandardTableHeader[] = [
    { label: '', id: 'SINo' },
    { label: 'SE', id: 'SE' },
    { label: 'M1-Total', id: 'M1_Total' },
    { label: 'M1-N', id: 'M1_N' },
    { label: 'M2-Total', id: 'M2_Total' },
    { label: 'M2-N', id: 'M2_N' },
    { label: 'M3-Total', id: 'M3_Total' },
    { label: 'M3-N', id: 'M3_N' },
]




type data = {
    stateId: number | null,
    rsmId: number | null,
    asmId:number|null
}

export function SeAllotmentPage() {
    const [isOpen, setIsOpen] = useState(false)
    const userData = useSelector((state: RootState) => state.user.employeeDetails)
    const [data, setData] = useState<data>({
        stateId: null,
        rsmId: userData.FM03_EmployeeID!,
        asmId:null
    })

    const [showaddSE, setShowaddSE] = useState(false)

    const [mode, setMode] = useState<'add'|'edit'>('add')

    
    const {
        isErrorlatestCalendarDate,
        isFetchedlatestCalendarDate,
        isFetchinglatestCalendarDate,
        isLoadinglatestCalendarDate,
        latestCalendarDate,
        refetchlatestCalendarDate
    } = useGetLatestCalendarDate({
        trigger: true
    })


    const {
        states,
        isLoading: isLoadingState,
        isError: isErrorState,
        isFetching: isFetchingState
    } = useLoadStatesFromStateMappingAPI({
        trigger: true,
        FM06_M03_EmployeeID: data.rsmId!
    });

    const {
        asms,
        isLoading: isLoadingAsms,
        isFetching: isFetchingAsms,
        isError: isAsmsError,
    } = useEmployeeASMsUnderRSMsAPI({
        trigger: data.stateId !== null,
        rsmId: data.rsmId!,
        stateId: data.stateId!
    })
    const {
        ses,
        isLoading: isLoadingSes,
        isFetching: isFetchingSes,
        isError: isSesError,
    } = useEmployeeSEsUnderASMsAPI({
        trigger: data.asmId !== null,
        asmId: data.asmId!,
        stateId: data.stateId!
    })

    const {
        forecastData: forecastLines,
        isErrorforcast,
        refetchForcastData,
        isFetchingForcastData,
        isLoadingforcast
    } = useForecastAPI({
        FT02_Date:latestCalendarDate?.date,
        FT02_M03_EmployeeIDASM:data.asmId,
        FT02_M03_EmployeeIDRSM:data.rsmId,
        FT02_ForecastLevel:'SE-Allotment',
        trigger:data.stateId&&data.asmId?true:false,
        FT02_M04_StateID:data.stateId,
        
    })



    const [tableRows, setTableRows] = useState<StandardTableRow[]>([])

    const isAnyError = isErrorState || isAsmsError;
    const isAnyLoading = isLoadingState || isLoadingAsms || isFetchingSes
    const isAnyFething = isFetchingState || isFetchingAsms ||isFetchingForcastData

    useEffect(() => {

        let mode : 'add'|'edit' = 'add'

        if(forecastLines?.ForecastLines?.length){
            mode = 'edit'
        }else{
            mode = 'add'
        }
        setMode(mode)
        setData({
            stateId: data.stateId,
            rsmId: userData.FM03_EmployeeID!,
            asmId:data.asmId
        })

        if(!isAnyError && !isAnyLoading && mode==='edit'){
            const tablerows: StandardTableRow[] = ses?.map((se) => {

                const M1_total = forecastLines?.ForecastLines?.find(item => {
                    return item.FT02_M03_EmployeeIDSE == se.FM03_EmployeeID && item.FT02_ForecastChannel === 'M1-Total' && item.FT02_ForecastLevel === 'SE-Allotment'
                })
                const M2_total = forecastLines?.ForecastLines?.find(item => {
                    return item.FT02_M03_EmployeeIDSE == se.FM03_EmployeeID && item.FT02_ForecastChannel === 'M2-Total' && item.FT02_ForecastLevel === 'SE-Allotment'
                })
                const M3_total = forecastLines?.ForecastLines?.find(item => {
                    return item.FT02_M03_EmployeeIDSE == se.FM03_EmployeeID && item.FT02_ForecastChannel === 'M3-Total' && item.FT02_ForecastLevel === 'SE-Allotment'
                })
        
                return {
                    id: String(se.FM03_EmployeeID),
                    datas: {
                        SE: {
                            data: se.FM03_Username,
                            id: '7'
                        },
                        M1_Total: {
                            data: M1_total?.FT02_ForecastValue || '',
                            id: '6'
                        },
                        M1_N: {
                            data: '',
                            id: '1'
                        },
                        M2_Total: {
                            data: M2_total?.FT02_ForecastValue || '',
                            id: '2'
                        },
                        M2_N: {
                            data: '',
                            id: '3'
                        },
                        M3_Total: {
                            data: M3_total?.FT02_ForecastValue || '',
                            id: '4'
                        },
                        M3_N: {
                            data: '',
                            id: '5'
                        },
                    }
                }
        
            })||[]
            setTableRows(tablerows)
        }

        if(mode==='add' && !isAnyError && !isAnyLoading && !isAnyFething){
            setTableRows([])
        }

    }, [isFetchingSes,isFetchingForcastData,userData.FM03_EmployeeID])




    return (
        <div className=' grow  flex flex-col overflow-auto items-center '>


            <ModalAddSEAllotment
                show={showaddSE}
                setShow={setShowaddSE}
                refetchforcast={refetchForcastData}
                selectedDate={latestCalendarDate?.date || ''}
                data={data}
            />
            <TypographyH2>SE Allotment</TypographyH2>
            
            <TablePlater className=' flex flex-col gap-2'>
                <div className='   gap-2  grid grid-cols-2 md:grid-cols-4 '>
                    <SelectStandard
                        options={states?.map(s => ({ label: s.FM04_StateName, value: String(s.FM04_StateID) })) || []}
                        placeHolder='Select State'
                        onChange={(e) => {
                            setData(prev => ({ ...prev, stateId: Number(e.target.value) }))
                        }}
                    />
                    <SelectStandard
                        options={asms?.map(s => ({ label: s.FM03_Username, value: String(s.FM03_EmployeeID) })) || []}
                        placeHolder='Select ASM'
                        onChange={(e) => {
                            setData(prev => ({ ...prev, asmId: Number(e.target.value) }))
                        }}
                    />
                    <InputStandard
                        type='date'
                        value={latestCalendarDate?.date || ''}
                        disabled
                    />
                    <ButtonIconSquare
                        text={mode==='add'?'Add':'Edit'}
                        icon={isAnyFething||isAnyLoading?<IconLoader2 className=' animate-spin' />:mode==='add'?<IconPlus />:<IconEdit />}
                        variant='secondary'
                        onClick={() => setShowaddSE(true)}
                        disabled={data.stateId === null || data.rsmId === null|| data.asmId===null||isAnyFething||isAnyLoading}
                    />
                </div>
                {
                    data.asmId && data.stateId ?
                        <StandardTable
                            headers={headers}
                            // isLoading={isLoadingForecastCalender}
                            // isUpdating={isAnythingLoading}
                            isLoading={isLoadingforcast}
                            rows={tableRows}
                            onRetry={refetchForcastData}
                            isUpdating={isFetchingForcastData}
                        />
                        : 
                        <div className=' text-center grow flex justify-center items-center'>
                            <TypographyH3>State and RSM not selected</TypographyH3>
                        </div>
                }
            </TablePlater>



        </div>
    )
}

