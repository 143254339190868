import { useState } from "react"


type darkModeType = 'dark' | 'light'

export function useDarkmodeAPI() {

    const initialMode = localStorage.getItem('darkMode')==='dark' ? 'dark' : 'light'
    const [mode, setMode] = useState<darkModeType>(initialMode)

    if(initialMode==='dark'){
        document.documentElement.classList.add('dark')
    }

    const setDarkMode = () => {
        document.documentElement.classList.add('dark')
        localStorage.setItem('darkMode', 'dark')
        setMode('dark')
    }

    const setLightMode = () => {
        document.documentElement.classList.remove('dark')
        localStorage.setItem('darkMode', 'light')
        setMode('light')
    }

    const toggleDarkMode = () => {
        document.documentElement.classList.toggle('dark')
        setMode(document.documentElement.classList.contains('dark') ? 'dark' : 'light')
        localStorage.setItem('darkMode', document.documentElement.classList.contains('dark') ? 'dark' : 'light')
    }

    return {
        mode,
        setDarkMode,
        setLightMode,
        toggleDarkMode
    }

}
