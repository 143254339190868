import { useMutation, useQuery } from "@tanstack/react-query"
import { useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"

export type ProductCategoryData = {
    FM12_ProductCategoryID: number,
    FM12_ProductSubCategory: string,
    FM12_ProductCategory: string,
    FM12_IsActive: boolean
}

const QUERY_KEY = 'product-category'
export function useProductCategoryAPI({
    page_size,
    page,
    retryCount,
    trigger=true,
    FM12_ProductCategory,
    FM12_ProductSubCategory

}:{
    page_size?: number,
    page?: number,
    retryCount?:number,
    trigger?:boolean,
    FM12_ProductSubCategory?:string,
    FM12_ProductCategory?:string,
}) {

    const api = useRestAPI()
    const [totalProductCategoryCount, setTotalProductCategoryCount] = useState(0)

    const {
        isLoading,
        isError,
        data: productCategories,
        refetch: refetchProductCategories,
        isFetching,
        isFetched,
        error
    } = useQuery({
        queryKey: [QUERY_KEY, page_size, page,FM12_ProductCategory,FM12_ProductSubCategory],
        queryFn: async () => {
            const response = await api.get<{ data: ProductCategoryData[] }&backendPagination>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/product-categories/`,
                mode: 'protected',
                params: {
                    page_size,
                    page,
                    FM12_ProductCategory,
                    FM12_ProductSubCategory
                }
            })
            if (response.success === false) {
                throw response.message
            }

            setTotalProductCategoryCount(response.count)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger
        
    })


 const { mutateAsync: createProductCategory } = useMutation({
        mutationFn: async (ProductCategory:ProductCategoryData) => {
            const response = await api.post<{ access: string,refresh:string }, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/product-categories/`,
                data: ProductCategory,
                mode:'protected'
            })


            if (response.success === false) {
                return response
            }
            localStorage.setItem('accessTocken', response.access);
            localStorage.setItem('refreshTocken', response.refresh);
            return response
        }
    })


    return {
        isLoading,
        isError,
        productCategories,
        refetchProductCategories,
        totalProductCategoryCount,
        isFetching,
        isFetched,
        error,
        createProductCategory
    }


}

