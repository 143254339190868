import { IconLoader2, IconPlus, IconTrashFilled, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useRestAPI } from 'src/API/axiosAPI'
import { ButtonIconSquare } from 'src/components/buttons'
import { StandardTable, StandardTableHeader, StandardTableRow, TablePlater } from 'src/components/standard.table'
import { TypographyH2 } from 'src/components/typography'
import { ModalAddRole } from './component.addModal'
import './role.page.css'
import { useRoleApi, useRoleMasterGetAll } from 'src/API/rest.role'
import { toast } from 'src/shadcn/ui/use-toast'
import { DropdownMenuDemo } from './component.dropdownMenu'
import { StandardAlertDialog } from 'src/components/standard.alert-dialog'


export default function RolePage() {

    const api = useRestAPI()
    const [mode, setMode] = useState<'add' | 'edit'>('add')
    const [openAddModal, setOpenAddModal] = useState(false)
    const [productsRow, setProductsRow] = useState<StandardTableRow[]>([])
    const [selectedProductId, setSelectedProductId] = useState<number | null | string>()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)


    const headers: StandardTableHeader[] = [
        { label: 'SI no', id: 'Si_no', whiteSpace: 'nowrap' },
        { label: 'Role', id: 'role', whiteSpace: 'nowrap' },
        { label: '', id: 'actions', width: '10px' },
    ]


    const {
        isLoadingRoleMasterList,
        isErrorRoleMasterList,
        rolesMasterList,
        refetchRolesMasterList,
        isFetchingRoleMasterList,
        isFetchedRoleMasterList,
        errorRoleMasterList,
        isRefetchRoleMasterListError,
        isFetchedAfterMount,

    } = useRoleMasterGetAll({
    })

    const {
        deleteRoleMaster,
        isdeleteRoleMaster,
        isErrordeleteRoleMaster
    } = useRoleApi()



    const deleteProduct = async () => {

        if (selectedProductId) {
            const response = await deleteRoleMaster({
                id: selectedProductId as string
            })
            if (response.success === true) {
                toast({
                    title: 'Success',
                    description: response.message,
                })
                refetchRolesMasterList()
                setOpenDeleteModal(false)
            } else {
                toast({
                    title: 'Error',
                    description: response.message,
                })
            }
            setSelectedProductId(null)
        }

    }


    useEffect(
        () => {

            //if successfully loaded
            if (isFetchedRoleMasterList && !isErrorRoleMasterList) {

                setProductsRow(rolesMasterList?.data?.map(
                    (master, index) => {
                        return ({
                            id: String(master.id),
                            datas: {
                                Si_no: { id: 'Si_no', data: index + 1 },
                                role: { id: 'role', data: master.role_name },
                                actions: {
                                    id: 'actions', data: <DropdownMenuDemo
                                        rowId={String(master.id)}
                                        onDeleteAction={(rowid) => {
                                            setSelectedProductId(rowid);
                                            setOpenDeleteModal(true);
                                        }}
                                        onEditAction={(rowid) => {
                                            setSelectedProductId(rowid);
                                            setMode('edit');
                                            setOpenAddModal(true);
                                        }}
                                    />
                                }
                            }
                        })

                    }
                ) || [])
            }
            if (isErrorRoleMasterList && isFetchingRoleMasterList === false) {
                toast({
                    description: String(errorRoleMasterList?.message),
                })

            }
        }, [isFetchingRoleMasterList]
    )


    return (
        <div className=' grow  flex flex-col overflow-auto items-center '>
            <StandardAlertDialog
                title="Are you absolutely sure?"
                description="This action cannot be undone. Are you sure you want to delete this product?"
                positiveLabel="Delete"
                negativeLabel="Cancel"
                positiveAction={deleteProduct}
                negativeAction={() => setOpenDeleteModal(false)}
                positiveIcon={isdeleteRoleMaster ? <IconLoader2 size={16} className=" animate-spin" /> : <IconTrashFilled size={16} />}
                negativeIcon={<IconX size={16} />}
                disableNegativeButton={isdeleteRoleMaster}
                disablePositiveButton={isdeleteRoleMaster}
                show={openDeleteModal}
            />
            <ModalAddRole
                show={openAddModal}
                setShow={setOpenAddModal}
                refetchEmployees={refetchRolesMasterList}
                mode={mode}
                employeeId={selectedProductId}
            />

            <TypographyH2>Role</TypographyH2>
            <div className=' flex justify-end w-full gap-4 mb-2'>
                <ButtonIconSquare variant="secondary" icon={<IconPlus />} text="Add Role" onClick={() => { setMode('add'); setOpenAddModal(true); }} />
            </div>
            <TablePlater className=' flex flex-col gap-2'>
                <StandardTable
                    headers={headers}
                    rows={productsRow}
                    isLoading={isLoadingRoleMasterList}
                    // rowsPerPrevousePage={previousDataLength}
                    isUpdating={isFetchingRoleMasterList}
                    isError={isErrorRoleMasterList && isFetchingRoleMasterList === false}
                    onRetry={() => refetchRolesMasterList()}
                />
            </TablePlater>

        </div>
    )
}

