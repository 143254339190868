import { IconCloudDown, IconCloudUp, IconLoader, IconLoader2, IconPlus, IconSortDescending, IconTrashFilled, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { ButtonIconSquare } from 'src/components/buttons'
import { StandardTable, StandardTableHeader, StandardTableRow, TablePlater } from 'src/components/standard.table'
import { Pagination, PaginationKeys } from 'src/types/pagination'
import { ModalAddDistributor } from './component.addModal'
import { DropdownMenuDemo } from './component.dropdownMenu'
import { StandardTablePagination } from '../../../../../components/standard.pagination'
import { useDistributorAPI } from 'src/API/rest.distributer'
import { toast } from 'src/shadcn/ui/use-toast'
import { StandardAlertDialog } from 'src/components/standard.alert-dialog'
import { ImportExcelModal } from './component.importExcelModal'
import { InputStandard } from 'src/components/standard.input'
import {useDebounce} from 'use-debounce'


export function DistributorPage() {

    const searchParams = new URLSearchParams(window.location.search)
    const currentPageFromUrl = searchParams.get(PaginationKeys.CURRENT_PAGE)
    const dataPerPage = 15
    const [pagination, setPagination] = useState<Pagination>({
        currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
        totalPages: Math.ceil(1 / dataPerPage) || 1,
        dataPerPage: dataPerPage
    })
    const [openImportModal, setOpenImportModal] = useState(false)
    const [distributorsRow, setDistributorsRow] = useState<StandardTableRow[]>([])
    const [previousDataLength, setPreviousDataLength] = useState(0)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [selectedDistributorId, setSelectedDistributorId] = useState<number | null | string>()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [mode, setMode] = useState<'add' | 'edit'>('add')
    const [search, setSearch] = useState('')
    const [debouncedInput] = useDebounce(search, 500);

    const {
        distributors,
        error,
        isError,
        isFetched,
        isFetching,
        isLoading,
        totalDistributorCount,
        isDeleting,
        refetchDistributors,
        deleteDistributorById,

    } = useDistributorAPI({
        page_size: pagination.dataPerPage,
        page: pagination.currentPage,
        search:debouncedInput
    })



    const headers: StandardTableHeader[] = [
        { label: 'Distributor Code', id: 'distributor_code' },
        { label: 'Distributor Name', id: 'distributor_name' },
        { label: 'State', id: 'state_name' },
        { label: 'Location', id: 'location_name' },
        { label: 'District', id: 'district' },
        { label: 'Zone', id: 'zone' },
        { label: '', id: 'actions', width: '10px' },
    ]



    const deleteDistributor = async () => {

        if (selectedDistributorId) {
            const response = await deleteDistributorById({
                distributorId: selectedDistributorId
            })
            if (response.success === true) {
                toast({
                    title: 'Success',
                    description: response.message,
                })
                refetchDistributors()
                setOpenDeleteModal(false)
            } else {
                toast({
                    title: 'Error',
                    description: response.message,
                })
            }
            setSelectedDistributorId(null)
        }

    }


    useEffect(
        () => {
            // setting prevouse data length for loading optimization
            if (isFetched && !isFetching) {
                console.log(distributors?.length)
                setPreviousDataLength(distributors?.length || 0)
            }

            //if successfully loaded
            if (isFetched && !isError) {
                setPagination({
                    currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
                    totalPages: Math.ceil(totalDistributorCount / dataPerPage) || 1,
                    dataPerPage: dataPerPage
                })
                setDistributorsRow(distributors?.map(
                    (distributor) => {
                        return ({
                            id: String(distributor.FM11_DistributorID),
                            datas: {
                                distributor_code: { id: 'distributor_code', data: distributor.FM11_DistributorCode },
                                distributor_name: { id: 'distributor_name', data: distributor.FM11_DistributorName },
                                status: { id: 'status', data: distributor.FM11_Status ? 'Active' : 'Inactive' },
                                state_name: { id: 'state_name', data: distributor?.FM11_M04_StateName?.name },
                                location_name: { id: 'location_name', data: distributor?.FM11_M09_LocationName?.name },
                                district: { id: 'district', data: distributor?.FM11_M10_District?.name },
                                zone: { id: 'zone', data: distributor?.FM11_M08_Zone?.name },
                                actions: {
                                    id: 'actions', data: <DropdownMenuDemo
                                        rowId={String(distributor.FM11_DistributorID)}
                                        onDeleteAction={(rowid) => {
                                            setSelectedDistributorId(rowid);
                                            setOpenDeleteModal(true);
                                        }}
                                        onEditAction={(rowid) => {
                                            setSelectedDistributorId(rowid);
                                            setMode('edit');
                                            setOpenAddModal(true);
                                        }}
                                    />
                                }
                            }
                        })

                    }
                ) || [])
            }
            if (isError && isFetching === false) {
                toast({
                    description: String(error),
                })

            }
        }, [isFetching]
    )







    return (
        <div className=' grow  flex flex-col overflow-auto'>
            <StandardAlertDialog
                title="Are you absolutely sure?"
                description="This action cannot be undone. Are you sure you want to delete this distributor?"
                positiveLabel="Delete"
                negativeLabel="Cancel"
                positiveAction={deleteDistributor}
                negativeAction={() => setOpenDeleteModal(false)}
                positiveIcon={isDeleting ? <IconLoader2 size={16} className=" animate-spin" /> : <IconTrashFilled size={16} />}
                negativeIcon={<IconX size={16} />}
                disableNegativeButton={isDeleting}
                disablePositiveButton={isDeleting}
                show={openDeleteModal}
            />
            <ModalAddDistributor
                show={openAddModal}
                setShow={setOpenAddModal}
                refetchDistributors={refetchDistributors}
                mode={mode}
                distributorId={selectedDistributorId}
            />
            <ImportExcelModal
                show={openImportModal}
                setShow={setOpenImportModal}
            />
            <TablePlater className=' flex flex-col gap-2'>
                {/* Buttons section */}
                <div className=' flex justify-end w-full gap-4'>
                    <InputStandard onChange={(e) => setSearch(e.target.value)} className=' lg:w-[400px] ' placeholder='Search' />
                    <ButtonIconSquare variant="white" icon={<IconCloudUp />} text="Import" onClick={() => { setOpenImportModal(true) }} />
                    <ButtonIconSquare variant="secondary" icon={<IconPlus />} text="Add distributor" onClick={() => { setMode('add'); setOpenAddModal(true); }} />
                </div>
                <StandardTable
                    headers={headers}
                    rows={distributorsRow}
                    isLoading={isLoading}
                    rowsPerPrevousePage={previousDataLength}
                    isUpdating={isFetching}
                    isError={isError && isFetching === false}
                    onRetry={() => refetchDistributors()}
                />
                <StandardTablePagination currentPage={pagination.currentPage} totalPages={pagination.totalPages} onChangePage={(page) => {
                    setPagination({ ...pagination, currentPage: page })
                }} />

            </TablePlater>
        </div>
    )

}


