import * as React from "react"
import { cn } from "src/lib/utils"
import { Label } from "src/shadcn/ui/label"


export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  title?: string,
  error?: string
}

const InputStandard = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <div className=" flex flex-col gap-1  ">
        <div className=" flex flex-col gap-2 items-start ">
          {props.title ?
            <Label htmlFor={props.name} className="text-xs font-medium dark:text-vstargray-300" >
              {props.title}
            </Label>
          :null}
          <input
            id={props.name}
            type={type}
            className={cn(
              "flex h-10   w-full rounded-md border border-gray-400  bg-background px-3 py-2 text-sm ring-offset-background  file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground  dark:disabled:text-white disabled:dark:placeholder:text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
              props.error ? "border-red-500 placeholder:text-red-400 ring-vstarPrimary-200" : " ring-vstargray-200",
              className
            )}
            ref={ref}
            {...props}
          />
        </div>
        {props.error ? <p className="text-red-500 text-[10px]  leading-4 text-start">{props.error}</p> : null}
      </div>
    )
  }
)
InputStandard.displayName = "InputStandard"

export { InputStandard }
