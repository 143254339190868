import { useMutation, useQuery } from "@tanstack/react-query"
import { useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"



export function useRegionAPI({
    page_size,
    page,
    retryCount,
    trigger = true
}: {
    page_size?: number,
    page?: number,
    retryCount?: number,
    trigger?: boolean
}) {

    const api = useRestAPI()
    const [totalRegionCount, setTotalRegionCount] = useState(0)

    const {
        isLoading,
        isError,
        data: regions,
        refetch: refetchRegions,
        isFetching,
        isFetched,
        error
    } = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: async () => {
            const response = await api.get<{ data: RegionData[] }>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/regions/`,
                mode: 'protected',
                params: {
                    page_size,
                    page
                }
            })
            if (response.success === false) {
                throw response.message
            }

            setTotalRegionCount(response.data?.length||0)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger
        
    })

    return {
        isLoading,
        isError,
        regions,
        refetchRegions,
        totalRegionCount,
        isFetching,
        isFetched,
        error
    }

}

type RegionData = {
    FM07_RegionID: number,
    FM07_RegionName: string,
    FM07_IsActive: boolean
}

const QUERY_KEY = 'regions'

