import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { AxiosResponseCustom, useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"

export type EmployeeDistributorMapping = {
    FM16_EmployeeDistributorMappingID?: number,
    FM16_M11_DistributorID: number,
    FM16_M04_StateName: number,
    FM16_M07_RegionName: number,
    FM14_M12_ProductSubCategory: number,
    FM14_M12_ProductCategory: number,
    FM16_M13_ProductID: number,
    FM16_M03_ExecutiveID: number,
    FM16_M03_ExecutiveName: string,
    FM16_M03_ASMID: number,
    FM16_M03_ASMName: string,
    FM16_M03_RSMID: number,
    FM16_M03_RSMName: string,
    FM16_Status: 1 | 0,
    FM16_StatusDate: string
}

type EmployeeDistributorMappingResponse = {
    FM16_EmployeeDistributorMappingID: number,
    FM16_M11_DistributorID: { id: number, name: string },
    FM16_M04_StateName: { id: number, name: string },
    FM16_M07_RegionName: { id: number, name: string },
    FM16_Status: 1 | 0,
    FM16_StatusDate: string,
    FM14_M12_ProductSubCategory: number,
    FM14_M12_ProductCategory: number,
    FM16_M13_ProductID: number,
    FM16_M03_ExecutiveID: number,
    FM16_M03_ExecutiveName: string | null,
    FM16_M03_ASMID: string | null,
    FM16_M03_ASMName: string | null,
    FM16_M03_RSMID: string | null,
    FM16_M03_RSMName: string | null,
}


const QUERY_KEY = 'employee-distributor-mapping'
export function useEmployeeDistributorMappingAPI({
    page_size,
    page,
    retryCount,
    trigger = true
}: {
    page_size?: number,
    page?: number,
    retryCount?: number,
    trigger?: boolean
}) {

    const api = useRestAPI()
    const [totalEmployeeDistributorMappingCount, setTotalEmployeeDistributorMappingCount] = useState(0)
    const queryclient = useQueryClient()

    const {
        isLoading,
        isError,
        data: employeeDistributorMapping,
        refetch: refetchEmployeeDistributorMapping,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount
    } = useQuery({
        queryKey: [QUERY_KEY, page_size, page],
        queryFn: async () => {
            const response = await api.get<{ data: EmployeeDistributorMappingResponse[] } & backendPagination>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/employee-distributor-mapping/`,
                mode: 'protected',
                params: {
                    page_size,
                    page
                }
            })
            if (response.success === false) {
                throw response.message
            }

            setTotalEmployeeDistributorMappingCount(response.count)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger,


    })

    const { mutateAsync: createEmployeeDistributorMapping,isPending:isCreatingEmployeeDistributorMapping,failureCount:createfailureCount } = useMutation({
        mutationFn: async (data: EmployeeDistributorMapping) => {
            const response = await api.post<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/employee-distributor-mapping/`,
                data,
                mode: 'protected'
            })

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&createfailureCount<4){
                
                throw response
            }

            return response
        }
    })

    const { mutateAsync: updateEmployeeDistributorMapping,isPending:isUpdatingEmployeeDistributorMapping  ,failureCount:updateFailuirCount } = useMutation({
        mutationFn: async ({
            id, 
            data
        }:{
            id: string | number,
            data: EmployeeDistributorMapping
        }) => {
            const response = await api.patch<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/employee-distributor-mapping/${id}/`,
                data,
                mode: 'protected'
            })

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&updateFailuirCount<4){
                
                throw response
            }

            return response
        },retry:4
    })

    const { mutateAsync: deleteEmployeeDistributorMappingById ,isPending:isDeleting,failureCount} = useMutation({
        mutationFn: async ({id}: {id:string|number}) => {
            
            const response = await api.delete<{}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/employee-distributor-mapping/${id}/`,
                mode: 'protected'
            })

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&failureCount<4){
                
                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }

            return response 
        },
        retry:4,
        
    })
    const { mutateAsync: readEmployeeDistributorMappingById ,isPending:isReadingEmployeeDistributorMapping,failureCount:employeeDistributorMappingReadFailureCount} = useMutation({
        mutationFn: async ({id}: {id:string|number}) => {
            
            const response = await api.get<{data:EmployeeDistributorMappingResponse}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/employee-distributor-mapping/${id}/`,
                mode: 'protected'
            })

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&employeeDistributorMappingReadFailureCount<4){
                
                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }

            return response 
        },
        retry:4,
        
    })

   


    return {
        isLoading,
        isError,
        employeeDistributorMapping,
        refetchEmployeeDistributorMapping,
        totalEmployeeDistributorMappingCount,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount,
        isDeleting,
        createEmployeeDistributorMapping,
        deleteEmployeeDistributorMappingById,
        readEmployeeDistributorMappingById,
        isReadingEmployeeDistributorMapping,
        updateEmployeeDistributorMapping,
        isUpdatingEmployeeDistributorMapping
    }


}



