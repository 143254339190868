import { IconCloudDown, IconLoader, IconLoader2, IconPlus, IconSortDescending, IconTrashFilled, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { ButtonIconSquare } from 'src/components/buttons'
import { StandardTable, StandardTableHeader, StandardTableRow, TablePlater } from 'src/components/standard.table'
import { Pagination, PaginationKeys } from 'src/types/pagination'
import { ModalAddEmployee } from './component.addModal'
import { DropdownMenuDemo } from './component.dropdownMenu'
import { StandardTablePagination } from '../../../../../components/standard.pagination'
import { useEmployeeAPI } from 'src/API/rest.employee'
import { toast } from 'src/shadcn/ui/use-toast'
import { StandardAlertDialog } from 'src/components/standard.alert-dialog'
import { useEmployeeTypeAPI } from 'src/API/rest.employee_category'
import { InputStandard } from 'src/components/standard.input'
import { useDebounce } from 'use-debounce'


export function EmloyeesPage() {

    const searchParams = new URLSearchParams(window.location.search)
    const currentPageFromUrl = searchParams.get(PaginationKeys.CURRENT_PAGE)
    const [search, setSearch] = useState('')
    const [debouncedInput] = useDebounce(search, 500);
    const dataPerPage = 15
    const [pagination, setPagination] = useState<Pagination>({
        currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
        totalPages: Math.ceil(1 / dataPerPage) || 1,
        dataPerPage: dataPerPage,
    })
    const [employeesRow, setEmployeesRow] = useState<StandardTableRow[]>([])
    const [previousDataLength, setPreviousDataLength] = useState(0)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [selectedEmployeeId, setSelectedEmployeeId] = useState<number | null | string>()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [mode, setMode] = useState<'add' | 'edit'>('add')

    const {
        emloyees,
        error,
        isError,
        isFetched,
        isFetching,
        isLoading,
        totalEmployeeCount,
        isDeleting,
        refetchEmployees,
        deleteEmployeeById,

    } = useEmployeeAPI({
        page_size: pagination.dataPerPage,
        page: pagination.currentPage,
        search:debouncedInput
    })

    



    const headers: StandardTableHeader[] = [
        { label: 'SI No', id: 'SINo' },
        { label: 'Emp Code', id: 'employee_code' ,whiteSpace:'nowrap'},
        { label: 'Username', id: 'username' },
        { label: 'Department', id: 'Department' },
        { label: 'Designation', id: 'Designation' },
        { label: '', id: 'actions', width: '10px' },
    ]



    const deleteEmployee = async () => {

        if (selectedEmployeeId) {
            const response = await deleteEmployeeById({
                employeeId: selectedEmployeeId
            })
            if (response.success === true) {
                toast({
                    title: 'Success',
                    description: response.message,
                })
                refetchEmployees()
                setOpenDeleteModal(false)
            } else {
                toast({
                    title: 'Error',
                    description: response.message,
                })
            }
            setSelectedEmployeeId(null)
        }

    }


    useEffect(
        () => {
            // setting prevouse data length for loading optimization
            if (isFetched && !isFetching) {
                console.log(emloyees?.length)
                setPreviousDataLength(emloyees?.length || 0)
            }

            //if successfully loaded
            if (isFetched && !isError) {
                setPagination({
                    currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
                    totalPages: Math.ceil(totalEmployeeCount / dataPerPage) || 1,
                    dataPerPage: dataPerPage
                })
                setEmployeesRow(emloyees?.map(
                    (employee,index) => {
                        return ({
                            id: String(employee.FM03_MailID),
                            datas: {
                                SINo: { id: 'SINo', data:  ((pagination.dataPerPage*pagination.currentPage)-pagination.dataPerPage)+(index+1)},
                                employee_code: { id: 'employee_code', data: employee.FM03_EmployeeCode },
                                username: { id: 'username', data: employee.FM03_Username },
                                Department: { id: 'Department', data: employee.FM03_M05_Department?.name },
                                Designation: { id: 'Designation', data: employee.FM03_M05_DesignationID?.name},
                                actions: {
                                    id: 'actions', data: <DropdownMenuDemo
                                        rowId={String(employee.FM03_EmployeeID)}
                                        onDeleteAction={(rowid) => {
                                            setSelectedEmployeeId(rowid);
                                            setOpenDeleteModal(true);
                                        }}
                                        onEditAction={(rowid) => {
                                            setSelectedEmployeeId(rowid);
                                            setMode('edit');
                                            setOpenAddModal(true);
                                        }}
                                    />
                                }
                            }
                        })

                    }
                ) || [])
            }
            if (isError && isFetching === false) {
                toast({
                    description: String(error),
                })

            }
        }, [isFetching]
    )







    return (
        <div className=' grow  flex flex-col overflow-auto'>
            <StandardAlertDialog
                title="Are you absolutely sure?"
                description="This action cannot be undone. Are you sure you want to delete this employee?"
                positiveLabel="Delete"
                negativeLabel="Cancel"
                positiveAction={deleteEmployee}
                negativeAction={() => setOpenDeleteModal(false)}
                positiveIcon={isDeleting ? <IconLoader2 size={16} className=" animate-spin" /> : <IconTrashFilled size={16} />}
                negativeIcon={<IconX size={16} />}
                disableNegativeButton={isDeleting}
                disablePositiveButton={isDeleting}
                show={openDeleteModal}
            />
            <ModalAddEmployee
                show={openAddModal}
                setShow={setOpenAddModal}
                refetchEmployees={refetchEmployees}
                mode={mode}
                employeeId={selectedEmployeeId}
            />
            <TablePlater className=' flex flex-col gap-2'>
                {/* Buttons section */}
                <div className=' flex justify-between w-full gap-4'>
                    <InputStandard onChange={(e) => setSearch(e.target.value)} className=' lg:w-[400px] ' placeholder='Search'/>
                     <ButtonIconSquare  variant="secondary" icon={<IconPlus />} text="Add employee" onClick={() => { setMode('add'); setOpenAddModal(true); }} />
                </div>
                <StandardTable
                    headers={headers}
                    rows={employeesRow}
                    isLoading={isLoading}
                    rowsPerPrevousePage={previousDataLength}
                    isUpdating={isFetching}
                    isError={isError && isFetching === false}
                    onRetry={() => refetchEmployees()}
                />
                <StandardTablePagination currentPage={pagination.currentPage} totalPages={pagination.totalPages} onChangePage={(page) => {
                    setPagination({ ...pagination, currentPage: page })
                }} />

            </TablePlater>
        </div>
    )

}

