import { IconChevronRight, IconList, IconMoon, IconSettings, IconSun } from '@tabler/icons-react'
import React from 'react'
import { useConfigurationRQ } from '../queryState/configuration.state'
import { Link } from 'react-router-dom'
import { useSideNavAPI } from '../API/sideNavAPI'
import { ButtonIconSquare, ButtonRounded } from './buttons'
import { useDarkmodeAPI } from '../API/darkmodeAPI'

export default function NavBar() {

    const {
        showSideNav,
    } = useSideNavAPI()

    const { mode, toggleDarkMode } = useDarkmodeAPI()

    return (
        <nav className='   grow  h-[100px] max-h-[70px] min-h-[70px] flex items-center px-2 justify-between'>
            {/* side bar toggle button */}
            <div className=' p-2 sm:hidden '>
                <ButtonIconSquare icon={<IconChevronRight />} onClick={showSideNav} />
            </div>
            <div className=' flex gap-3'>
                <Link className=' text-vstargray-700 dark:text-vstargray-500 text-xs font-bold' to={''}>File</Link>
                <Link className=' text-vstargray-700 dark:text-vstargray-500 text-xs font-bold' to={''}>Department</Link>
                <Link className=' text-vstargray-700 dark:text-vstargray-500 text-xs font-bold' to={''}>Sales</Link>
            </div>
            {/* icons */}
            <div className=' flex gap-2'>
                <ButtonRounded icon={<IconSettings size={15} />} />
                {<ButtonRounded onClick={toggleDarkMode} icon={mode === 'dark' ? <IconMoon size={15} /> : <IconSun size={15} />} />}

            </div>
        </nav>
    )
}
