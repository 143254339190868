import { IconEdit, IconLoader, IconLockFilled, IconPlus, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ForecastHeaderType, ForecastLineType, useForecastAPI } from 'src/API/rest.forecast';
import { ForecastChannelType, MonthCode } from 'src/API/rest.forecast_regionwise';
import { ButtonIconSquare } from 'src/components/buttons';
import { InputStandard } from 'src/components/standard.input';
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from 'src/components/standard.modal';
import { toast } from 'src/shadcn/ui/use-toast';
import { cn } from 'src/lib/utils';
import { useEmployeeSEsUnderASMsAPI } from 'src/API/rest.employee';

type Props = {
    show?: boolean,
    setShow?: (show: boolean) => void,
    refetchforcast: () => void,
    employeeDistributorMappingId?: number | string | null,
    selectedDate: string,
    MonthCode?: {
        monthCode: MonthCode;
        month: string;
    },
    data: data
};

type data = {
    stateId: number | null,
    rsmId: number | null,
    asmId: number | null
}


export type ForecasteSEwise = {
    employee_id: number;
    employee_name: string;
    employee_code: string;
    ForecastLines: (ForecastLineType & { type: 'input' | 'disabled' })[];
}

export type ForecastSEWiseType = {
    ForecastHeader?: ForecastHeaderType;
    SEs: ForecasteSEwise[];
}

export type ForecastRegionWisePostType = {

    ForcastLines: ForecastLineType[]
} & ForecastHeaderType


function returnForcastLine(props: {
    Date: string,
    ForecastMonth?: string | null,
    MonthCode?: MonthCode | null,
    forecastChannel: ForecastChannelType,
    asmId: number | null,
    rsmId: number | null,
    seId:number|null,
    stateId: number | null
}): (ForecastLineType) {
    return {
        FT02_ForecastLineID: null,
        FT02_FT01_ForecastHeaderID: null,
        FT02_ForecastLevel: "SE-Allotment",
        FT02_Date: props.Date,
        FT02_ForecastMonth: props.ForecastMonth || null,
        FT02_MonthCode: props.MonthCode || null,
        FT02_ForecastChannel: props.forecastChannel,
        FT02_SuggestedQty: null,
        FT02_SuggestedValue: null,
        FT02_ForecastQty: null,
        FT02_ASP: null,
        FT02_ForecastValue: null,
        FT02_StyleFlag: null,
        FT02_M12_ProductSubCategory: null,
        FT02_M12_ProductCategory: null,
        FT02_ABCCategory: null,
        FT02_M13_ProductID: null,
        FT02_M14_SubAttributeID: null,
        FT02_M04_StateID: props.stateId,
        FT02_FM07_RegionID: null,
        FT02_M03_EmployeeIDRSM: props.rsmId || null,
        FT02_M03_EmployeeIDASM: props.asmId || null,
        FT02_M03_EmployeeIDSE: props.seId || null,
        FT02_M11_DistributorID: null,
        FT02_Remarks: null,
        FT02_LYM1Qty: null,
        FT02_LYM2Qty: null,
        FT02_LYM3Qty: null,
        FT02_M1Qty: null,
        FT02_M2Qty: null,
        FT02_M3Qty: null,
        FT02_RunningMonthForecast: null,
        FT02_RunningMonthActuals: null,
        FT02_M1Suggested: null,
        FT02_Forecast1: null,
        FT02_Forecast2: null,
        FT02_Forecast3: null,
        FT02_SubmitFlag: 0,
        FT02_Status: 1,
        FT02_StatusDate: "2024-09-28",
        FT02_CreatedDate: null,
    } as ForecastLineType
}

export function ModalAddSEAllotment(props: Props) {

    const { register, handleSubmit, setValue, reset, formState: { errors }, watch } = useForm<ForecastSEWiseType>({

    });

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [options, setOptions] = useState<ForecastSEWiseType>({
        ForecastHeader: undefined,
        SEs: [],
    });
    const [mode, setMode] = useState<'add' | 'edit'>('add');




    const {
        forecastData: forecastLines,
        isErrorforcast,
        isFetchingForcastData,
        isLoadingforcast,
        createCalendarLine,
        updateCalendarLine
    } = useForecastAPI({
        trigger: props.show && props.data.asmId !== null && props.data.stateId !== null,
        FT02_M03_EmployeeIDASM: props.data.asmId,
        FT02_Date: props.selectedDate,
        FT02_M04_StateID: props.data.stateId,
        FT02_ForecastLevel: 'SE-Allotment',
        FT02_M03_EmployeeIDRSM: props.data.rsmId

    })

    const {
        ses: SEs,
        isLoading: isLoadingSes,
        isFetching: isFetchingSes,
        isError: isSesError,
    } = useEmployeeSEsUnderASMsAPI({
        trigger: props.data.asmId !== null && props.data.stateId !== null && props.show,
        asmId: props.data.asmId!,
        stateId: props.data.stateId!
    })




    useEffect(() => {
        // if (!isErrorForecastRegionWise && isFetchedForcastRegionWise) {


        const ForcastHeader: ForecastHeaderType = {
            FT01_ForecastHeaderID: forecastLines?.ForecastHeader?.[0]?.FT01_ForecastHeaderID || null,
            FT01_ForecastLevel: forecastLines?.ForecastHeader?.[0]?.FT01_ForecastLevel || "SE-Allotment",
            FT01_Date: forecastLines?.ForecastHeader?.[0]?.FT01_Date || `${props.selectedDate}`,
            FT01_ForecastMonth: forecastLines?.ForecastHeader?.[0]?.FT01_ForecastMonth || null,
            FT01_MonthCode: forecastLines?.ForecastHeader?.[0]?.FT01_MonthCode || props.MonthCode?.monthCode!,
            FT01_ForecastChannel: forecastLines?.ForecastHeader?.[0]?.FT01_ForecastChannel || "",
            FT01_M04_StateID: forecastLines?.ForecastHeader?.[0]?.FT01_M04_StateID || props.data.stateId,
            FT01_Remarks: forecastLines?.ForecastHeader?.[0]?.FT01_Remarks || null,
            FT01_SubmitFlag: forecastLines?.ForecastHeader?.[0]?.FT01_SubmitFlag || 0,
            FT01_IsApprove: forecastLines?.ForecastHeader?.[0]?.FT01_IsApprove || null,
            FT01_ApproveDate: forecastLines?.ForecastHeader?.[0]?.FT01_ApproveDate || null,
            FT01_ApprovedBy: forecastLines?.ForecastHeader?.[0]?.FT01_ApprovedBy || null,
            FT01_Status: forecastLines?.ForecastHeader?.[0]?.FT01_Status || true,
            FT01_StatusDate: forecastLines?.ForecastHeader?.[0]?.FT01_StatusDate || "",
            FT01_ForecastFileName: forecastLines?.ForecastHeader?.[0]?.FT01_ForecastFileName || null,
        }
        console.log(props.data)

        const options: ForecastSEWiseType = {
            ForecastHeader: ForcastHeader,
            SEs: SEs?.map(
                se => {

                    const M1Total = forecastLines?.ForecastLines.find(item => {
                        return item.FT02_M03_EmployeeIDSE == se.FM03_EmployeeID && item.FT02_ForecastChannel === 'M1-Total'
                    }) || returnForcastLine({
                        Date: `${props.selectedDate}`,
                        forecastChannel: 'M1-Total',
                        ForecastMonth: null,
                        MonthCode: null,
                        asmId: props.data.asmId,
                        rsmId: props.data.rsmId,
                        stateId: props.data.stateId,
                        seId:se.FM03_EmployeeID!
                    })
                    const M2Total = forecastLines?.ForecastLines.find(item => {
                        return item.FT02_M03_EmployeeIDSE == se.FM03_EmployeeID && item.FT02_ForecastChannel === 'M2-Total'
                    }) || returnForcastLine({
                        Date: `${props.selectedDate}`,
                        forecastChannel: 'M2-Total',
                        ForecastMonth: null,
                        MonthCode: null,
                        asmId: props.data.asmId,
                        rsmId: props.data.rsmId,
                        stateId: props.data.stateId,
                        seId:se.FM03_EmployeeID!
                    })
                    const M3Total = forecastLines?.ForecastLines.find(item => {
                        return item.FT02_M03_EmployeeIDSE == se.FM03_EmployeeID && item.FT02_ForecastChannel === 'M3-Total'
                    }) || returnForcastLine({
                        Date: `${props.selectedDate}`,
                        forecastChannel: 'M3-Total',
                        ForecastMonth: null,
                        MonthCode: null,
                        asmId: props.data.asmId,
                        rsmId: props.data.rsmId,
                        stateId: props.data.stateId,
                        seId:se.FM03_EmployeeID!
                    })

                    return {
                        employee_id: se.FM03_EmployeeID!,
                        employee_name: se.FM03_Username,
                        employee_code: se.FM03_EmployeeCode,
                        ForecastLines: [
                            { ...M1Total, type: 'input' },
                            {
                                ...returnForcastLine({
                                    Date: props.selectedDate,
                                    forecastChannel: "Other",
                                    ForecastMonth: props.MonthCode?.month,
                                    MonthCode: props.MonthCode?.monthCode,
                                    asmId: props.data.asmId,
                                    rsmId: props.data.rsmId,
                                    stateId: props.data.stateId,
                                    seId:se.FM03_EmployeeID!
                                }), type: 'disabled'
                            },
                            { ...M2Total, type: 'input' },
                            {
                                ...returnForcastLine({
                                    Date: props.selectedDate,
                                    forecastChannel: "Other",
                                    ForecastMonth: props.MonthCode?.month,
                                    MonthCode: props.MonthCode?.monthCode,
                                    asmId: props.data.asmId,
                                    rsmId: props.data.rsmId,
                                    stateId: props.data.stateId,
                                    seId:se.FM03_EmployeeID!
                                }), type: 'disabled'
                            },
                            { ...M3Total, type: 'input' },
                            {
                                ...returnForcastLine({
                                    Date: props.selectedDate,
                                    forecastChannel: "Other",
                                    ForecastMonth: props.MonthCode?.month,
                                    MonthCode: props.MonthCode?.monthCode,
                                    asmId: props.data.asmId,
                                    rsmId: props.data.rsmId,
                                    stateId: props.data.stateId,
                                    seId:se.FM03_EmployeeID!
                                }), type: 'disabled'
                            },
                        ]
                    }
                }
            )
                || []
        }
        setOptions(options)
        console.log(options.SEs)


        if (forecastLines?.ForecastLines?.length && forecastLines?.ForecastLines?.length > 0) {
            setMode('edit')
        } else {
            setMode('add')
        }
        reset(options)
        // }

        if (isAnyError) {
            toast({
                description: 'Error in fetching data',
                title: 'Error',
            })
        }

    }, [props.show]);

    const onSubmit = async (data: ForecastSEWiseType, final?: boolean) => {
        setIsSubmitting(true);
        let postData: ForecastRegionWisePostType
        postData = {
            ...data.ForecastHeader!,
            FT01_StatusDate: null,
            FT01_Date: props.selectedDate,
            ForcastLines: data.SEs.flatMap(se => se.ForecastLines).filter(item => item.type !== 'disabled'),
        }

        if (final) {
            postData.FT01_SubmitFlag = 1
            postData.ForcastLines.forEach(item => {
                item.FT02_SubmitFlag = 1
            })
        } else {
            postData.FT01_SubmitFlag = 0
            postData.ForcastLines.forEach(item => {
                item.FT02_SubmitFlag = 0
            })
        }

        console.log(postData)

        if (mode === 'add') {

            const response = await createCalendarLine(postData);
            if (response.success) {
                toast({ description: response.message });
                props.setShow?.(false);
                props.refetchforcast();
            } else {
                toast({ description: response.message, title: 'Error' });
            }

        } else if (mode === 'edit') {
            console.log(postData)
            const response = await updateCalendarLine({
                id: postData.FT01_ForecastHeaderID!,
                actionsLines: postData
            });
            if (response.success) {
                toast({ description: response.message });
                props.setShow?.(false);
                props.refetchforcast();
            } else {
                toast({ description: response.message, title: 'Error' });
            }
        }


        setIsSubmitting(false);
    };



    const isAnythingLoading = isLoadingforcast
    const isAnyError = isErrorforcast
    const isAnyFething = isFetchingForcastData
    const isFinalSubmit = forecastLines?.ForecastHeader?.[0]?.FT01_SubmitFlag ? true : false

    const total = options.SEs.reduce((a, b) => a + b.ForecastLines.reduce((a, b) => a + (b.FT02_ForecastValue || 0), 0), 0)


    return (
        <ModalStandard open={props.show} onOpenChange={props.setShow} >
            <ModalStandardContent className="bg-gray-100 max-w-full min-w-full h-full flex flex-col">
                <form className=' grow flex flex-col' onSubmit={handleSubmit((data) => onSubmit(data))}>
                    <ModalStandardHeader className=' grow' >
                        <ModalStandardTitle>{mode === 'edit' ? 'Edit Regionwise Forecast' : 'Add Regionwise Forecast'}</ModalStandardTitle>
                        <ModalStandardDescription >
                            {isAnythingLoading && <div className=' px-2 p-1 rounded-md bg-vstargray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>}
                            <div className="overflow-x-auto">
                                <div className="inline-block min-w-full align-middle">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table className="min-w-full border border-gray-200 rounded-lg overflow-hidden">
                                            <thead className="bg-gray-100">
                                                <tr>
                                                    <th className="px-3 py-2 border-b border-gray-200"></th>
                                                    <th className="px-3 py-2 border-b border-gray-200">SE</th>
                                                    <th className="px-3 py-2 border-b border-gray-200">M1-Total</th>
                                                    <th className="px-3 py-2 border-b border-gray-200">M1-N</th>
                                                    <th className="px-3 py-2 border-b border-gray-200">M2-Total</th>
                                                    <th className="px-3 py-2 border-b border-gray-200">M2-N</th>
                                                    <th className="px-3 py-2 border-b border-gray-200">M3-Total</th>
                                                    <th className="px-3 py-2 border-b border-gray-200">M3-N</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white ">
                                                {
                                                    options?.SEs.map((region, indexRegion) => {

                                                        const total = region.ForecastLines.reduce((accumulator, currentValue) => {
                                                            return accumulator + currentValue.FT02_ForecastValue!;
                                                        }, 0);

                                                        return (
                                                            <tr key={indexRegion} >
                                                                <td className="px-3 py-2 border-b border-gray-200">{indexRegion + 1}</td>
                                                                <td className="px-3 py-2 border-b border-gray-200">{region.employee_name}</td>
                                                                {
                                                                    region.ForecastLines.map((forecastLine, indexLine) => {
                                                                        return (
                                                                            <td key={indexLine} className=' px-3 py-2 border-b border-gray-200'>
                                                                                {
                                                                                    forecastLine.type === 'input' ?
                                                                                        <InputStandard
                                                                                            className='min-w-[100px]'
                                                                                            {...register(`SEs.${indexRegion}.ForecastLines.${indexLine}.FT02_ForecastValue`, {
                                                                                                validate: (value) => {
                                                                                                    if (!value) {
                                                                                                        return true
                                                                                                    }
                                                                                                    if (isNaN(value)) {
                                                                                                        return 'Not valid'
                                                                                                    }
                                                                                                }
                                                                                            })}
                                                                                            placeholder={forecastLine.FT02_ForecastChannel!}
                                                                                            error={errors?.SEs?.[indexRegion]?.ForecastLines?.[indexLine]?.FT02_ForecastValue?.message}
                                                                                            onChange={(e) => {
                                                                                                const regex = /^[0-9]+$/;
                                                                                                if (e.target.value === '' || regex.test(e.target.value)) {
                                                                                                    setValue(`SEs.${indexRegion}.ForecastLines.${indexLine}.FT02_ForecastValue`, e.target.value as unknown as number)
                                                                                                    const newOption = { ...options }
                                                                                                    newOption.SEs[indexRegion].ForecastLines[indexLine].FT02_ForecastValue = Number(e.target.value)
                                                                                                    setOptions(newOption)
                                                                                                } else {
                                                                                                    setValue(`SEs.${indexRegion}.ForecastLines.${indexLine}.FT02_ForecastValue`, watch(`SEs.${indexRegion}.ForecastLines.${indexLine}.FT02_ForecastValue`))

                                                                                                }
                                                                                            }}
                                                                                            disabled={isFinalSubmit || isAnyFething || isAnyError}
                                                                                        />
                                                                                        :
                                                                                        <InputStandard
                                                                                            disabled={forecastLine.type === 'disabled'}
                                                                                            className='min-w-[100px]'
                                                                                            {...register(`SEs.${indexRegion}.ForecastLines.${indexLine}.FT02_ForecastValue`, {
                                                                                                validate: (value) => {
                                                                                                    if (!value) {
                                                                                                        return true
                                                                                                    }
                                                                                                    if (isNaN(value)) {
                                                                                                        return 'Not valid'
                                                                                                    }
                                                                                                }
                                                                                            })}
                                                                                            placeholder={forecastLine.FT02_ForecastChannel!}
                                                                                            error={errors?.SEs?.[indexRegion]?.ForecastLines?.[indexLine]?.FT02_ForecastValue?.message}
                                                                                        />
                                                                                }
                                                                            </td>
                                                                        )
                                                                    })
                                                                }
                                                                <td className=' px-3 py-2 border-b border-gray-200'>{total}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                <tr>
                                                    <td className='px-3 py-2 border-b border-gray-200' colSpan={7}></td>
                                                    <td className='px-3 py-2 border-b border-gray-200'>Total</td>
                                                    <td className='px-3 py-2 border-b border-gray-200'>{total}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                            <div className='min-h-2'></div>
                            {/* <SwitchDemo
                                {...register(`ForecastHeader.SubmitFlag`)}
                                title={'Final Submit'}
                                onChange={(value) => {
                                    setValue('ForecastHeader.SubmitFlag', value.target.value ? 1 : 0);
                                }}
                                value={is_submit_flag == 1 ? true : false}
                            /> */}
                        </ModalStandardDescription>
                    </ModalStandardHeader>
                    <ModalStandardFooter>
                        <ButtonIconSquare variant="white" icon={<IconX />} text="Close" onClick={(e) => {
                            props.setShow?.(false);
                            e?.preventDefault();
                        }} disabled={isSubmitting} />
                        <ButtonIconSquare
                            variant='secondary'
                            icon={isSubmitting ? <IconLoader className="animate-spin" /> : <IconLockFilled />}
                            text='Final submit'
                            disabled={isSubmitting || isAnythingLoading || isAnyError || isAnyFething}
                            onClick={(e) => {
                                e?.preventDefault()
                                handleSubmit((data) => onSubmit(data, true))()
                            }}
                            className={
                                cn(forecastLines?.ForecastHeader?.[0]?.FT01_SubmitFlag == 1 ? 'hidden' : '')
                            }
                        />
                        <ButtonIconSquare
                            variant="secondary"
                            icon={isSubmitting ? <IconLoader className="animate-spin" /> : mode === 'add' ? <IconPlus /> : <IconEdit />}
                            text={mode === 'add' ? 'Add' : 'Edit'}
                            type="submit"
                            disabled={isSubmitting || isAnythingLoading || isAnyError || isAnyFething}
                            className={
                                cn(forecastLines?.ForecastHeader?.[0]?.FT01_SubmitFlag == 1 ? 'hidden' : '')
                            }
                        />
                    </ModalStandardFooter>
                </form>
            </ModalStandardContent>
        </ModalStandard>
    );
}
