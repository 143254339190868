import { useMutation, useQuery } from "@tanstack/react-query"
import { useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"



export function useZoneAPI({
    page_size,
    page,
    retryCount,
    trigger = true
}: {
    page_size?: number,
    page?: number,
    retryCount?: number,
    trigger?: boolean
}) {

    const api = useRestAPI()
    const [totalZoneCount, setTotalZoneCount] = useState(0)

    const {
        isLoading,
        isError,
        data: zones,
        refetch: refetchZones,
        isFetching,
        isFetched,
        error
    } = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: async () => {
            const response = await api.get<{ data: ZoneData[] }&backendPagination>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/zones/`,
                mode: 'protected',
                params: {
                    page_size,
                    page
                }
            })
            if (response.success === false) {
                throw response.message
            }

            setTotalZoneCount(response.data?.length||0)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger
        
    })

    return {
        isLoading,
        isError,
        zones,
        refetchZones,
        totalZoneCount,
        isFetching,
        isFetched,
        error
    }

}

type ZoneData = {
    FM08_ZoneID: number,
    FM08_ZoneName: string,
    FM08_IsActive: boolean
}

const QUERY_KEY = 'zones'



