import React, { useEffect, useState } from 'react'
import { InputStandard } from 'src/components/standard.input'
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from 'src/components/standard.modal'
import { AspFormData } from './component.addModal'
import { useForm } from 'react-hook-form'
import { ButtonIconSquare } from 'src/components/buttons'
import { IconEdit, IconLoader, IconPlus, IconX } from '@tabler/icons-react'
import { useSubAttributeAPI } from 'src/API/rest.subattribute'
import { SelectStandard } from 'src/components/standard.select'
import { useStatesAPI } from 'src/API/rest.states'


type props = {
    pricelist: ({
        FM19_M04_StateID: string,
        FM19_ASP: string,
        stateName?: string,
        FM19_PriceID?: number;
        FM19_M14_SubAttributeID?: string;
        FM19_status: number;
        FM19_MRP: number | null;
    })[],
    show: boolean,
    setShow: (show: boolean) => void,
    setPricelist: (aspData: AspFormData[]) => any
}

export default function AspModal(props: props) {


    const { register, handleSubmit, setValue, reset, formState: { errors }, control, watch } = useForm<{
        options: AspFormData[]
    }>({
        defaultValues: {
            options: props.pricelist
        }
    });
    

    const {
        states,
        isFetching
    } = useStatesAPI({
        isState:1
    })

    
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [mode, setMode] = useState<'add' | 'edit'>('add');


    const onSubmit = async (data: { options: {FM19_ASP: string;}[] }) => {
        setIsSubmitting(true);

        


        if (mode === 'add') {

        
        const submitData:(AspFormData&{stateName?: string,})[] =[]
            data.options.forEach((item, index) => {
                submitData.push({
                    FM19_ASP: item.FM19_ASP,
                    FM19_M04_StateID: props.pricelist[index].FM19_M04_StateID,
                    FM19_PriceID: props.pricelist[index].FM19_PriceID,
                    FM19_M14_SubAttributeID: props.pricelist[index].FM19_M14_SubAttributeID,
                    FM19_status: props.pricelist[index].FM19_status,
                    FM19_MRP: props.pricelist[index].FM19_MRP,
                    stateName: props.pricelist[index].stateName
                })
            })
            props.setPricelist(submitData)
            props.setShow?.(false)

        } else if (mode === 'edit') {


        }
        setIsSubmitting(false);
    };

    useEffect(
        ()=>{
            if(props.show){
                reset({
                    options: props.pricelist
                })
            }else{
                
            }

        },[props.show]
    )

    // console.log(props.pricelist)

    return (
        <ModalStandard open={props.show} onOpenChange={props.setShow}>

            <ModalStandardContent className=' bg-white'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalStandardTitle >ASP</ModalStandardTitle>
                    <ModalStandardHeader>
                        <ModalStandardDescription>
                            <div className=' grid grid-cols-2 gap-2 py-2'>
                                {
                                    props.pricelist.map((item, index) => (
                                        <>
                                            {/* <InputStandard
                                                disabled
                                                placeholder={'state id'}
                                                value={item.stateName}
                                            /> */}
                                            <SelectStandard
                                                disabled
                                                options={states?.map(state=>({
                                                    value: String(state.FM04_StateID),
                                                    label: state.FM04_StateName}))||[]}
                                                value={String(item.FM19_M04_StateID)}
                                            />
                                            <InputStandard
                                                placeholder={'asp'}
                                                type='number'
                                                {...register(`options.${index}.FM19_ASP`, { required: "asp is required" })}
                                                error={errors.options?.[index]?.FM19_ASP?.message}
                                            />
                                        </>
                                    ))
                                }
                            </div>
                        </ModalStandardDescription>
                    </ModalStandardHeader>
                    <ModalStandardFooter>
                        <ButtonIconSquare variant="white" icon={<IconX />} text="Close" onClick={(e) => {
                            props.setShow?.(false);
                            e?.preventDefault();
                        }} disabled={isSubmitting} />
                        <ButtonIconSquare
                            variant="secondary"
                            icon={isSubmitting ? <IconLoader className="animate-spin" /> : mode === 'add' ? <IconPlus /> : <IconEdit />}
                            text={mode === 'add' ? 'submit' : 'Edit'} type="submit"
                            disabled={isSubmitting}
                        />
                    </ModalStandardFooter>
                </form>
            </ModalStandardContent>
        </ModalStandard>
    )
}
