import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
type initialData = {
    theme:'light'|'dark',
    sideNav:{
        isOpen:'open'|'close',
        isCollapsed:'open'|'close'
    }
}

const initialState:initialData = {
    theme:'light',
    sideNav:{
        isOpen:'close',
        isCollapsed:'open'
    }
}

export const configurationReducer = createSlice({
  name: 'configuration',
  initialState:initialState,
  reducers: {
    setSideNavigation: (state, action: PayloadAction<'open' | 'close'>) => {
      state.sideNav.isOpen = action.payload
      return state
    },
    setSideNavigationCollapse: (state, action: PayloadAction<'open' | 'close'>) => {
      state.sideNav.isCollapsed = action.payload
      return state
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSideNavigation,setSideNavigationCollapse } = configurationReducer.actions

export default configurationReducer.reducer