import { IconCloudUpload, IconLoader2 } from '@tabler/icons-react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useEmployeeAPI, useEmployeeSEsUnderASMsAPI } from 'src/API/rest.employee'
import { useExecutiveWiseForcast } from 'src/API/rest.ExecutiveForecast'
import { useGetLatestCalendarDate } from 'src/API/rest.forecast_calender'
import { MonthCode } from 'src/API/rest.forecast_regionwise'
import { ButtonIconSquare } from 'src/components/buttons'
import { ProgressFullScreen } from 'src/components/progressLoading'
import { ErrorBoxStandard } from 'src/components/standard.errorbox'
import { FileInputDragDropStandard } from 'src/components/standard.fileDroper'
import { InputStandard } from 'src/components/standard.input'
import { SelectStandard } from 'src/components/standard.select'
import { TypographyH2 } from 'src/components/typography'
import { RootState } from 'src/redux/store'
import { toast } from 'src/shadcn/ui/use-toast'


type MonthType = {
    month: string;
    value: string;
}

export default function ForcastExecutiveWise() {
    const [isOpen, setIsOpen] = useState(false)
    const currentDate = new Date();
    const [selectedDate, setSelectedDate] = useState(
        currentDate.toISOString().substring(0, 7) // Format: YYYY-MM
    );
    const [isDownloadingTemplate, setisDownloadingTemplate] = useState(false);
    const [employeeSelected, setemployeeSelected] = useState<number | null>(null)
    const [seletedFile, setSelectedFile] = useState<File | null>(null)
    const userData = useSelector((state: RootState) => state.user.employeeDetails)


    const {
        isErrorlatestCalendarDate,
        isFetchedlatestCalendarDate,
        isFetchinglatestCalendarDate,
        isLoadinglatestCalendarDate,
        latestCalendarDate,
        refetchlatestCalendarDate
    } = useGetLatestCalendarDate({
        trigger: true
    })


    const {
        downloadTemplateExecutivewiseforecast,
        isDownloadingTEmplateExcelforecast,
        isDownloadExcelForecastTemplateError,
        validateExecutivewiseforecastExel,
        isvalidateingExecutivewiseforecastExel,
        isvalidateExecutivewiseforecastExelError,
        validateProgress,
        uploadExecutivewiseforecastExel,
        isuploadingExecutivewiseforecastExel,
        uploadProgress
    } = useExecutiveWiseForcast({

    })

    const {
        ses: emloyees,
        isLoading: isLoadingEmployee,
        isFetching: isFetchingEmployee,
        isError: isEmployeeError,
    } = useEmployeeSEsUnderASMsAPI({
        asmId: userData.FM03_EmployeeID!,
    })



    const isAnyError = isEmployeeError || isErrorlatestCalendarDate
    const isAnyFething = isFetchinglatestCalendarDate || isFetchingEmployee
    const isLoading = isLoadingEmployee || isLoadinglatestCalendarDate




    if (isErrorlatestCalendarDate && !isFetchinglatestCalendarDate) {
        return (
            <div className=' grow flex flex-col items-center '>
                <ErrorBoxStandard
                    onRetry={refetchlatestCalendarDate}
                />
            </div>
        )
    }

    return (
        <div className=' grow  flex flex-col overflow-auto items-center '>
            <ProgressFullScreen
                progress={validateProgress}
                show={isvalidateingExecutivewiseforecastExel}
                subTitle='Validating file...'
            />
            <ProgressFullScreen
                progress={uploadProgress}
                show={isuploadingExecutivewiseforecastExel}
                subTitle='uploading file...'
            />

            {/* NP style wise */}
            <div className=' grow flex flex-col overflow-hidden bg-white dark:bg-vstargray-700 border rounded-md w-full text-center'>
                <div className=' grow flex flex-col overflow-auto p-2 '>
                    <TypographyH2>Executive Wise</TypographyH2>
                    <div className=' flex gap-2 '>
                        <InputStandard
                            title='Date'
                            placeholder='Date'
                            type='date'
                            disabled
                            value={latestCalendarDate?.date || ''}
                        />
                        <SelectStandard
                            title='Employee'
                            placeHolder='Select Employee'
                            options={emloyees?.map(emp => ({ value: String(emp.FM03_EmployeeID), label: emp.FM03_Username })) || []}
                            onChange={(value) => setemployeeSelected(Number(value.target.value))}
                            value={employeeSelected ? String(employeeSelected) : ''}
                        />
                    </div>
                    <div className=' grow flex flex-col justify-center items-center  w-min mx-auto mt-8'>
                        <div className='  text-center flex text-vstargray-800 dark:text-vstargray-100 py-2'>Download Template {isDownloadingTEmplateExcelforecast ? <IconLoader2 size={10} className=' inline animate-spin' /> : <span onClick={() => downloadTemplateExecutivewiseforecast()} className=' underline text-blue-500 cursor-pointer hover:text-blue-700'>here</span>}</div>
                        <FileInputDragDropStandard
                            disabled={employeeSelected === null || !latestCalendarDate?.date ? true : false}
                            onDrop={async (file) => {
                                const response = await validateExecutivewiseforecastExel({
                                    file: file!,
                                    SEId: employeeSelected!,
                                })
                                if (response.success === false) {
                                    toast({
                                        description: response.message
                                    })
                                } else {
                                    setSelectedFile(file);
                                }
                            }}
                            file={seletedFile}
                            onClear={() => setSelectedFile(null)}
                            accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] }}
                        />
                    </div>
                    <ButtonIconSquare
                        disabled={seletedFile === null}
                        className=' mx-auto mb-5'
                        icon={isuploadingExecutivewiseforecastExel ? <IconLoader2 className='animate-spin' /> : <IconCloudUpload />}
                        onClick={async () => {
                            const response = await uploadExecutivewiseforecastExel({
                                date: latestCalendarDate?.date!,
                                file: seletedFile!,
                                SEId: employeeSelected!
                            })
                            if (response.success === false) {
                                toast({
                                    title: 'Error',
                                    description: response.message
                                })
                            } else {
                                toast({
                                    title: 'Success',
                                    description: response.message
                                })
                                setSelectedFile(null)
                            }
                        }}
                        variant='secondary' text='Submit'
                    />
                </div>
            </div>

        </div>
    )
}

