import { useMutation, useQuery } from "@tanstack/react-query"
import { useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"

export type EmployeeTypeData = {
    id: number,
    M18_Employee_type: string,
}

const QUERY_KEY = 'employee_types'
export function useEmployeeTypeAPI({

    retryCount,
    trigger=true
}:{
    retryCount?:number,
    trigger?:boolean
}) {

    const api = useRestAPI()
    const [totalEmployeeTypeCount, setTotalEmployeeTypeCount] = useState(0)

    const {
        isLoading,
        isError,
        data: employeeTypes,
        refetch: refetchEmployeeTypes,
        isFetching,
        isFetched,
        error
    } = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: async () => {
            const response = await api.get<{ data: EmployeeTypeData[] }>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/employee_type/`,
                mode: 'protected',
            })
            if (response.success === false) {
                throw response.message
            }

            setTotalEmployeeTypeCount(response.data?.length||0)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger
        
    })



    return {
        isLoading,
        isError,
        employeeTypes,
        refetchEmployeeTypes,
        totalEmployeeTypeCount,
        isFetching,
        isFetched,
        error
    }


}



